import React, { useState, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next';

import { styled } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from './theme'
import ExcelJs from "exceljs";
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Slide from '@mui/material/Slide';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Card from '@mui/material/Card';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import CardContent from '@mui/material/CardContent';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';
import Select from '@mui/material/Select';
import { UserContext } from './context/UserContext'
import { MenuContext } from "./context/MenuContext"
import useDidMountEffect from "./hooks/useDidMountEffect";
import HeaderAndMenu from './components/HeaderAndMenu'
import ReportChart from './components/ReportChart'
import { Typography, Checkbox } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import useMediaQuery from '@mui/material/useMediaQuery';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const columnsFilterForUser = [
  { id: 'date', label: '日期', minWidth: 80, align: "center" },
  { id: 'impressions', label: '曝光', align: "right", minWidth: 100, format: (value) => parseFloat(value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})},
  { id: 'clicks', label: '點擊', align: "right", minWidth: 100, format: (value) => parseFloat(value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2}) },
  { id: 'ctr', label: 'CTR(%)', align: "right", minWidth: 100, format: (value)=>parseFloat(value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})+'%' },
  { id: 'cpm', label: 'CPM', align: "right", minWidth: 100, format: (value) => parseFloat(value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})},
  { id: 'final_revenue', align: "right", label: '預估收益', minWidth: 100, format: (value) => parseFloat(value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})},
];

const columnsForManager = [
  { id: 'date', label: '日期', minWidth: 90, align: "center" },
  { id: 'platform', label: '平台', minWidth: 80, align: "center" },
  { id: 'website', label: '網站', minWidth: 80, align: "center" },
  { id: 'adunit', label: '版位', minWidth: 80, align: "center" },
  { id: 'impressions', label: '曝光', align: "right", minWidth: 80, format: (value) => parseFloat(value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})},
  { id: 'clicks', label: '點擊', align: "right", minWidth: 60, format: (value) => parseFloat(value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2}) },
  { id: 'ctr', label: 'CTR(%)', align: "right", minWidth: 60, format: (value)=>parseFloat(value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})+'%'},
  { id: 'cpm', label: 'CPM', align: "right", minWidth: 50, format: (value) => parseFloat(value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})},
  { id: 'true_revenue', label: '實際收益', align: "right", minWidth: 60, format: (value) => parseFloat(value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})},
  { id: 'final_revenue', label: '預估收益', align: "right", minWidth: 60, format: (value) => parseFloat(value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})},
  { id: 'action', label: '批量', align: "center"}
];

const columnsFilterForManager = [
  { id: 'date', label: '日期', minWidth: 80, align: "center" },
  { id: 'impressions', label: '曝光', align: "right", minWidth: 100, format: (value) => parseFloat(value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})},
  { id: 'clicks', label: '點擊', align: "right", minWidth: 100, format: (value) => parseFloat(value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2}) },
  { id: 'ctr', label: 'CTR(%)', align: "right", minWidth: 100, format: (value)=>parseFloat(value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})+'%'},
  { id: 'cpm', label: 'CPM', align: "right", minWidth: 100, format: (value) => parseFloat(value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})},
  { id: 'true_revenue', label: '實際收益', align: "right", minWidth: 100, format: (value) => parseFloat(value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})},
  { id: 'final_revenue', label: '預估收益', align: "right", minWidth: 100, format: (value) => parseFloat(value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})},
];

const columnsFilterByUnitForManager = [
  { id: 'user', label: '網站主', minWidth: 80, align: "center" },
  { id: 'website', label: '網站', minWidth: 80, align: "center" },
  { id: 'unit', label: '廣告版位', minWidth: 80, align: "center" },
  { id: 'impressions', label: '曝光', align: "right", minWidth: 100, format: (value) => parseFloat(value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})},
  { id: 'clicks', label: '點擊', align: "right", minWidth: 100, format: (value) => parseFloat(value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2}) },
  { id: 'ctr', label: 'CTR(%)', align: "right", minWidth: 100, format: (value)=>parseFloat(value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})+'%'},
  { id: 'cpm', label: 'CPM', align: "right", minWidth: 100, format: (value) => parseFloat(value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})},
  { id: 'true_revenue', label: '實際收益', align: "right", minWidth: 100, format: (value) => parseFloat(value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})},
  { id: 'final_revenue', label: '預估收益', align: "right", minWidth: 100, format: (value) => parseFloat(value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})},
];

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
    '&.checked':{
      backgroundColor: '#55c1f133'
    }
  },
  '&.checked':{
    backgroundColor: '#55c1f133'
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function Report() {
  const { t } = useTranslation();

  const { userInfo } = useContext(UserContext);
  const [ menuOpen, setMenuOpen ] = useState(true);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const defaultEndDate = new Date()
  const defaultStartDate = new Date()
  defaultStartDate.setDate(defaultEndDate.getDate() - 7)
  const [dateStartValue, setStartDateValue] = useState(defaultStartDate);
  const [dateEndValue, setEndDateValue] = useState(defaultEndDate);
  const [siteArr, setSiteArr] = useState([]);
  const [site, setSite] = useState('all');
  const [ siteValue, setSiteValue ] = useState(t('all'))
  const [ inputSite, setInputSite ] = useState(t('all'))
  const [userArr, setUserArr] = useState([]);
  const [user, setUser] = useState(userInfo.role==='User'?userInfo.id:'all');
  const [ userValue, setUserValue ] = useState(userInfo.role==='User'?userInfo.id:t('all'))
  const [ inputUser, setInputUser ] = useState(userInfo.role==='User'?userInfo.id:t('all'))
  const [unitArr, setUnitArr] = useState([]);
  const [unit, setUnit] = useState('all');
  const [unitSelector, setUnitSelector] = useState(true);
  const [range, setRange] = useState('week');
  const [filter, setFilter] = useState('byDate');
  const [showFilter, setShowFilter] = useState('byDate');
  const [loading, setLoading] = useState(false);
  const [dayOrMonth, setDayOrMonth] = useState('daily');
  const [reportArr, setReportArr] = useState([]);
  const [chartArr, setChartArr] = useState([]);
  const [reportByFilterArr, setReportByFilterArr] = useState([]);
  const [pageInfo, setPageInfo] = useState(0)
  const [search, setSearch] = useState(true)
  const [unitInfo, setUnitInfo] = useState('')
  const workbook = new ExcelJs.Workbook();
  const [ open, setOpen ] = useState(false)
  const [ summary, setSummary ] = useState({})
  const [ deleteReport, setDeleteReport ] = useState([])
  const [ deleteMultiple, setDeleteMultiple ] = useState(false)
  const [ checkAll, setCheckAll ] = useState(false)
  const [ checkedArr, setCheckArr ] = useState([])
  const [ showTable, setShowTable ] = useState(false)
  const [ showChart, setShowChart ] = useState(false)
  const [ showUnit, setShowUnit ] = useState(true)
  const matches = useMediaQuery('(max-width:768px)');
  const [ showDownloadMonthly, setShowDownloadMonthly ] = useState(true)

  const rangeArr = [
    {id: 'week', label: t('this_week')},
    {id: 'month',label: t('this_month')},
    {id: 'last_month', label: t('last_month')}
  ];

  const columnsForUser = [
    { id: 'date', label: t('date'), minWidth: 80, align: "center" },
    { id: 'platform', label: t('platform'), minWidth: 80, align: "center" },
    { id: 'website', label: t('website'), minWidth: 80, align: "center" },
    { id: 'adunit', label: t('unit'), minWidth: 80, align: "center" },
    { id: 'impressions', label: t('impressions'), align: "right", minWidth: 100, format: (value) => parseFloat(value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})},
    { id: 'clicks', label: t('clicks'), align: "right", minWidth: 100, format: (value) => parseFloat(value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2}) },
    { id: 'ctr', label: 'CTR(%)', align: "right", minWidth: 100, format: (value)=>parseFloat(value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})+'%' },
    { id: 'cpm', label: 'CPM', align: "right", minWidth: 100, format: (value) => parseFloat(value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})},
    { id: 'final_revenue', align: "right", label: t('estimated_revenue'), minWidth: 100, format: (value) => parseFloat(value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})},
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setSearch(true)
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    setSearch(true)
  };

  const handleUnitChange = (event) => {
    setUnit(event.target.value);
    if(event.target.value!=='all'){

      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userInfo.token},
      };
      fetch('https://api.futurekey.org/api/ad_positions/' + event.target.value, requestOptions)
      .then(response => response.json())
      .then(data => {
        if(data.message==='OK'){
          setUnitInfo(parseInt(data.data.deduction))
        }else{
          console.log(data.message)
        }
      })
    }
  };

  const handleSiteChange = (event) => {
    setUnitSelector(true)
    setSite(event.target.value);
    console.log(event.target.value)
    setUnit('all')
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userInfo.token},
    };
    fetch('https://api.futurekey.org/api/ad_positions?user_id=' + (user==='all' ? '' : user) + '&website_id='+ (event.target.value==='all' ? '' : event.target.value), requestOptions)
    .then(response => response.json())
    .then(data => {
      if(data.message==='OK'){
        setUnitArr(data.data.adunits)
        if(event.target.value!=='all' && event.target.value!==0){
          setUnitSelector(false)
        }else{
          setUnitSelector(true)
        }
      }else{
        console.log(data.message)
      }
    })
    
  };

  const handleUserChange = (event) => {
    setUser(event.target.value);
    setSiteArr([])
    setSite('all')
    setSiteValue(t('all'))
    setUnitArr([])
    setUnit('all')
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userInfo.token},
    };
    fetch('https://api.futurekey.org/api/websites?page=1&per_page=500&user_id='+(event.target.value==='all'?'':event.target.value), requestOptions)
    .then(response => response.json())
    .then(data => {
      if(data.message==='OK'){
        let newSiteArr = data.data.websites.map((w) => {
          if(w.status===false){
            return {
              label: w.name+'(停用)',
              value: w.id,
            }
          }else{
            return {
              label: w.name,
              value: w.id,
            }
          }
        })
        newSiteArr.unshift({label: t('all'), value: 'all'})
        setSiteArr(newSiteArr)
      }else{
        console.log(data.message)
      }
    })
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
    //setSite('all')
    //setUnit('all')
  };

  const getDataByDate = () => {
    if(search){
      setShowDownloadMonthly(true)
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userInfo.token},
      };
      fetch('https://api.futurekey.org/api/reports?sorting=date&direction=asc&type=' + dayOrMonth +'&user_id=' + (user==='all'||user===0 ? '' : user) + '&website_id='+ (site==='all'||site===0 ? '' : site) + '&adunit_id=' + (unit==='all' ? '' : unit) + '&start_date=' + formatDate(dateStartValue) + '&end_date=' + formatDate(dateEndValue) + '&per_page=' + rowsPerPage + '&page=' + (page+1), requestOptions)
        .then(response => response.json())
        .then(data => {
          if(data.message==='OK'){
            setChartArr(data.data.reports)
          
          }else{
            console.log(data.message)
          }
        })

      fetch('https://api.futurekey.org/api/reports?sorting=date&direction='+ (userInfo.role==="User"?'desc':'asc') +'&type=' + dayOrMonth +'&user_id=' + (user==='all'||user===0 ? '' : user) + '&website_id='+ (site==='all'||site===0 ? '' : site) + '&adunit_id=' + (unit==='all' ? '' : unit) + '&start_date=' + formatDate(dateStartValue) + '&end_date=' + formatDate(dateEndValue) + '&per_page=' + rowsPerPage + '&page=' + (page+1), requestOptions)
        .then(response => response.json())
        .then(data => {
          if(data.message==='OK'){
            setShowFilter('byDate')
            setReportArr(data.data.reports)
            setPageInfo(data.data.meta.total)
            setSummary(data.data.summary)
            var check_arr=[]
            // eslint-disable-next-line array-callback-return
            data.data.reports.map((r)=>{
              check_arr.push(false)
            })
            setCheckArr(check_arr)
            
            if(user!=='all' && user!==0){
              setShowTable(true)
              if(site!=='all' && site!==0) {
                setShowChart(true)
                if(unit==='all'){
                  setShowUnit(false)
                }else{
                  setShowUnit(true)
                }
              }else{
                setShowChart(false)
                if(unit==='all'){
                  setShowUnit(true)
                }else{
                  setShowUnit(true)
                }
              }
            }else{
              if(site!=='all'&&site!==0){
                setShowTable(true)
                setShowChart(true)
                if(unit==='all'){
                  setShowUnit(false)
                }else{
                  setShowUnit(true)
                }
              }else{
                setShowTable(false)
                setShowUnit(true)
                setShowChart(false)
              }
            }
            setLoading(false)
          }else{
            console.log(data.message)
          }
        })
    }
  }

  const getDataByPlatform = () => {
    if(search){
      setShowDownloadMonthly(true)
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userInfo.token},
      };
      fetch('https://api.futurekey.org/api/reports_by_platform?direction=asc&user_id=' + (user==='all'||user===0 ? '' : user) + '&website_id='+ (site==='all'||site===0 ? '' : site) + '&adunit_id=' + (unit==='all' ? '' : unit) + '&start_date=' + formatDate(dateStartValue) + '&end_date=' + formatDate(dateEndValue) + '&per_page=' + rowsPerPage + '&page=' + (page+1), requestOptions)
        .then(response => response.json())
        .then(data => {
          if(data.message==='OK'){
            setShowFilter('byPlatform')
            setReportByFilterArr(data.data.reports)
            setPageInfo(data.data.meta.total)
            setSummary(data.data.summary)
            setShowTable(true)
            setShowChart(false)
            setShowUnit(true)
            setLoading(false)
          }else{
            console.log(data.message)
          }
        })
    }
  }

  const getDataByWebsite = () => {
    if(search){
      setShowDownloadMonthly(true)
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userInfo.token},
      };
      fetch('https://api.futurekey.org/api/reports_by_website?direction=asc&user_id=' + (user==='all' ? '' : user) + '&adunit_id=' + (unit==='all' ? '' : unit) + '&start_date=' + formatDate(dateStartValue) + '&end_date=' + formatDate(dateEndValue) + '&per_page=' + rowsPerPage + '&page=' + (page+1), requestOptions)
        .then(response => response.json())
        .then(data => {
          if(data.message==='OK'){
            setShowFilter('byWebsite')
            setReportByFilterArr(data.data.reports)
            setPageInfo(data.data.meta.total)
            setSummary(data.data.summary)
            setShowChart(false)
            setShowTable(true)
            setShowUnit(true)
            setLoading(false)
          }else{
            console.log(data.message)
          }
        })
    }
  }

  const getDataByUnit = () => {
    if(search){
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userInfo.token},
      };
      fetch('https://api.futurekey.org/api/reports_by_adunit?direction=asc&user_id=' + (user==='all' ? '0' : user) + '&website_id='+ (site==='all' ? '0' : site) + '&start_date=' + formatDate(dateStartValue) + '&end_date=' + formatDate(dateEndValue) + '&per_page=' + rowsPerPage + '&page=' + (page+1), requestOptions)
        .then(response => response.json())
        .then(data => {
          if(data.message==='OK'){
            setShowFilter('byUnit')
            setReportByFilterArr(data.data.reports)
            setPageInfo(data.data.meta.total)
            setSummary(data.data.summary)
            setShowChart(false)
            setShowTable(true)
            setShowUnit(true)
            setLoading(false)
            setShowDownloadMonthly(false)
          }else{
            console.log(data.message)
          }
        })
    }
  }

  const handleChange = (event) => {
    setDayOrMonth(event.target.value);
  };

  const handleRangeChange = (event) => {
    setRange(event.target.value);
    let range = event.target.value
    if(range==='week'){
      const endDate = new Date()
      const startDate = new Date()
      startDate.setDate(endDate.getDate() - 7)
      setStartDateValue(startDate);
      setEndDateValue(endDate);
    }else if(range==='month'){
      let tempDate = new Date()
      tempDate.setDate(0)
      const endDate = new Date()
      const startDate = new Date()
      let days = tempDate.getDate()
      startDate.setDate(1)
      endDate.setDate(days)
      setStartDateValue(startDate);
      setEndDateValue(endDate);
    }else{
      let tempDate = new Date()
      tempDate.setMonth(tempDate.getMonth())
      tempDate.setDate(0)
      console.log(tempDate.getFullYear())
      const endDate = new Date()
      const startDate = new Date()
      if(tempDate.getMonth()===11){
        startDate.setFullYear(tempDate.getFullYear())
        endDate.setFullYear(tempDate.getFullYear())
      }     
      let days = tempDate.getDate()
      startDate.setMonth(tempDate.getMonth())
      endDate.setMonth(tempDate.getMonth())
      startDate.setDate(1)
      endDate.setDate(days)
      setStartDateValue(startDate);
      setEndDateValue(endDate);
    }
  }

  const formatDate = (v) => {
    let date = new Date(v)
    let y = date.getFullYear()
    let m = date.getMonth()+1
    let d = date.getDate()
    return y+"-"+m+"-"+d
  }

  const handleSearch = () => {
    setSearch(true)
  }
  const handleDownloadReport = () => {
    var report=[]

    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userInfo.token},
    };
    fetch('https://api.futurekey.org/api/reports_by_adunit?direction=asc&user_id=' + (user==='all' ? '0' : user) + '&website_id='+ (site==='all' ? '0' : site) + '&start_date=' + formatDate(dateStartValue) + '&end_date=' + formatDate(dateEndValue) + '&per_page=500&page=1', requestOptions)
      .then(response => response.json())
      .then(data => {
        if(data.message==='OK'){
          report=data.data.reports

          const sheet = workbook.addWorksheet(formatDate(dateStartValue)+'~'+formatDate(dateEndValue)+'月報表', {properties: {defaultColWidth: 15}});
          var column=[]
          var CsvData=[]
          column = [
            {name: '網站主'},{name: '網站'},{name: '廣告版位'}, {name: '實際收益貨幣'}, {name: '實際收益'}, {name: '預估收益貨幣'},{name: '預估收益'}
          ]
          CsvData = report.map((r)=>{
            return [r.user.username, r.website.name, r.adunit.display_name, r.in_currency.code+'$',r.true_revenue, r.out_currency.code+'$',r.final_revenue]
          })
      
          sheet.addTable({
            name: 'reportCSV',
            ref: 'A1',
            columns: column,
            rows: CsvData
          });
      
          workbook.xlsx.writeBuffer().then((content) => {
            const link = document.createElement("a");
            const blobData = new Blob([content], {
              type: "application/vnd.ms-excel;charset=utf-8;"
            });
            link.download = '月報表資料.xlsx';
            link.href = URL.createObjectURL(blobData);
            link.click();
          });
        }else{
          console.log(data.message)
        }
      })
  }

  const handleDownloadCSV = () => {

    const sheet = workbook.addWorksheet(formatDate(dateStartValue)+'~'+formatDate(dateEndValue)+' By '+dayOrMonth, {properties: {defaultColWidth: 15}});
    var column=[]
    var reportData=[]
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userInfo.token},
    };
    fetch('https://api.futurekey.org/api/reports?sorting=date&direction='+ (userInfo.role==="User"?'desc':'asc') +'&type=' + dayOrMonth +'&user_id=' + (user==='all'||user===0 ? '' : user) + '&website_id='+ (site==='all'||site===0 ? '' : site) + '&adunit_id=' + (unit==='all' ? '' : unit) + '&start_date=' + formatDate(dateStartValue) + '&end_date=' + formatDate(dateEndValue) + '&per_page=2000&page=1', requestOptions)
      .then(response => response.json())
      .then(data => {
        if(data.message==='OK'){
          if(userInfo.role!=="User"){
            // eslint-disable-next-line array-callback-return
            column = columnsForManager.map((item) =>{
                return {name: item.label}
            })
            reportData = data.data.reports.map((r)=>{
              return [r.date, r.platform.name, r.website.name, !r.adunit?'All':r.adunit.display_name, r.impressions, r.clicks, r.ctr+'%', r.cpm, r.true_revenue, r.final_revenue]
            })
          }else{
            // eslint-disable-next-line array-callback-return
            column = columnsForUser.map((item) =>{
              return {name: item.label}
            })
            reportData = data.data.reports.map((r)=>{
              return [r.date, r.platform.name, r.website.name, !r.adunit?'All':r.adunit.display_name, r.impressions, r.clicks, r.ctr+'%', r.cpm, r.final_revenue]
            })
          }
      
          sheet.addTable({
            name: 'reportCSV',
            ref: 'A1',
            columns: column,
            rows: reportData
          });
      
          workbook.xlsx.writeBuffer().then((content) => {
            const link = document.createElement("a");
            const blobData = new Blob([content], {
              type: "application/vnd.ms-excel;charset=utf-8;"
            });
            link.download = `${t("report_csv")}.xlsx`;
            link.href = URL.createObjectURL(blobData);
            link.click();
          });
        }

      })

  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleDelete = () => {
    const requestOptions = {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userInfo.token},
    };
    fetch('https://api.futurekey.org/api/reports/' + deleteReport , requestOptions)
    .then(response => response.json())
    .then(data => {
      if(data.message==='OK'){
        setSearch(true)
      }else{
        console.log(data.message)
      }
    })
  }

  const handleUnCheckAll = () => {
    var temp_arr =[]
    var check_arr = checkedArr
    setCheckAll(false)
    check_arr.forEach((item, index, arr) => {
      arr[index] = false;
    })
    setCheckArr(check_arr)
    setDeleteReport(temp_arr)
    console.log(check_arr)
    console.log(temp_arr)
  }

  const handleCheckAll = () => {
    var temp_arr =[]
    var check_arr = checkedArr
    if(deleteReport.length===reportArr.length){
      temp_arr =[]
      setCheckAll(false)
      check_arr.forEach((item, index, arr) => {
        arr[index] = false;
      })
    }else{
      setCheckAll(true)
      // eslint-disable-next-line array-callback-return
      reportArr.map((r)=>{
        temp_arr.push(r.id)
      })
      check_arr.forEach((item, index, arr) => {
        arr[index] = true;
      })
    }
    setCheckArr(check_arr)
    setDeleteReport(temp_arr)
    console.log(check_arr)
    console.log(temp_arr)
  }

  const handleCheckOne = (event, id, index) => {
    var check_arr = checkedArr
    if(check_arr[index]===true){check_arr[index]=false}else{check_arr[index]=true}
    setCheckArr(check_arr)

    var temp_arr = JSON.parse(JSON.stringify(deleteReport))
    if(temp_arr.indexOf(id)>=0){
      temp_arr = temp_arr.filter(t=>t!==id)
    }else{
      temp_arr.push(id)
    }
    setDeleteReport(temp_arr)
  }

  const handleDeleteMultiple = () => {
    const requestOptions = {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userInfo.token},
    };
    // eslint-disable-next-line array-callback-return
    deleteReport.map((d)=>{
      console.log(d)
      fetch('https://api.futurekey.org/api/reports/' + d , requestOptions)
      .then(response => response.json())
      .then(data => {
        if(data.message==='OK'){
        }else{
          console.log(data.message)
        }
      })
    })
    setSearch(true)
    setDeleteMultiple(false)
    setCheckAll(false)
  }

  useEffect (() => {
    if(search){
      setLoading(true)
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userInfo.token},
      };
      if(userInfo.role!=="User"){

        fetch('https://api.futurekey.org/api/users?role=User&status=true&page=1&per_page=500', requestOptions)
        .then(response => response.json())
        .then(data => {
          if(data.message==='OK'){
            let newUserArr = data.data.users.map((u) => {
              return {
                label: u.username,
                value: u.id,
              }
            })
            newUserArr.unshift({label: t('all'), value: 'all'})
            setUserArr(newUserArr)
          }else{
            console.log(data.message)
          }
        })

        fetch('https://api.futurekey.org/api/websites?page=1&per_page=500&user_id='+(user==='all'?'':user), requestOptions)
        .then(response => response.json())
        .then(data => {
          if(data.message==='OK'){
            let newSiteArr = data.data.websites.map((w) => {
              if(w.status===false){
                return {
                  label: w.name+'(停用)',
                  value: w.id,
                }
              }else{
                return {
                  label: w.name,
                  value: w.id,
                }
              }
            })
            newSiteArr.unshift({label: t('all'), value: 'all'})
            setSiteArr(newSiteArr)
          }else{
            console.log(data.message)
          }
        })

        fetch('https://api.futurekey.org/api/ad_positions?page=1&per_page=500&user_id=' + (user==='all' ? '' : user) + '&website_id='+ (site==='all' ? '' : site), requestOptions)
        .then(response => response.json())
        .then(data => {
          if(data.message==='OK'){
            setUnitArr(data.data.adunits)
            if(site!=='all' && site!==0){
              setUnitSelector(false)
            }else{
              setUnitSelector(true)
            }
          }else{
            console.log(data.message)
          }
        })

        switch (filter) {
          case 'byDate':
            getDataByDate()
            
            break;
          case 'byPlatform':
            getDataByPlatform()
            break;
          case 'byWebsite':
            getDataByWebsite()
            break;
          case 'byUnit':
              getDataByUnit()
            break;
          default:
            break;
        }
      
      }else{
        fetch('https://api.futurekey.org/api/websites?page=1&per_page=500&user_id='+ userInfo.id, requestOptions)
        .then(response => response.json())
        .then(data => {
          if(data.message==='OK'){
            let newSiteArr = data.data.websites.map((w) => {
              if(w.status===false){
                return {
                  label: w.name+'(停用)',
                  value: w.id,
                }
              }else{
                return {
                  label: w.name,
                  value: w.id,
                }
              }
            })
            newSiteArr.unshift({label: t('all'), value: 'all'})
            setSiteArr(newSiteArr)
          }else{
            console.log(data.message)
          }
        })

        fetch('https://api.futurekey.org/api/ad_positions?page=1&per_page=500&user_id=' + userInfo.id + '&website_id='+ (site==='all' ? '' : site), requestOptions)
        .then(response => response.json())
        .then(data => {
          if(data.message==='OK'){
            setUnitArr(data.data.adunits)
            if(site!=='all' && site!==0){
              setUnitSelector(false)
            }else{
              setUnitSelector(true)
            }
          }else{
            console.log(data.message)
          }
        })

        switch (filter) {
          case 'byDate':
            getDataByDate()
            break;
          case 'byPlatform':
            getDataByPlatform()
            break;
          case 'byWebsite':
            getDataByWebsite()
            break;
          case 'byUnit':
            if(site==='all'){
              alert('請選擇網站')
              setLoading(false)
            }else{
              getDataByUnit()
            }
            break;
          default:
            break;
        }
      }

      setSearch(false)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[userInfo, user, site, unit, rowsPerPage, page, search])

  useDidMountEffect(() => {
    setUnit('all')
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userInfo.token},
    };

    fetch('https://api.futurekey.org/api/ad_positions?page=1&per_page=500&website_id='+(site==='all' ? '' : site), requestOptions)
    .then(response => response.json())
    .then(data => {
      if(data.message==='OK'){
        setUnitArr(data.data.adunits)
      }else{
        console.log(data.message)
      }
    })
  },[site])

  return (
    <ThemeProvider theme={theme}>
      <MenuContext.Provider value={{menuOpen, setMenuOpen}}>
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />
          <HeaderAndMenu mainTitle={ userInfo.role==='User'?t('revenue_report'):"會計報表" }/>
          <Box component="main" sx={{ flexGrow: 1, height: '100vh', overflow: 'auto' }}>
            <Toolbar className='fixToolbar' />
            <Container className='mainContainer' maxWidth="lg" sx={{ mt: 4, mb: 4 }} >
              <Card sx={{ minWidth: 275 }}>
                <CardContent>
                  <Grid container sx={{alignItems: 'center'}}>
                    <Grid item mr={2}>
                      <FormControl sx={{ minWidth: 100}} size="small">
                        <Select
                          size="small"
                          labelId="option-select-label"
                          id="option_select"
                          name="option_select"
                          value={range}
                          onChange={handleRangeChange}
                        >
                          {
                            rangeArr.map((r)=>(
                              <MenuItem value={r.id} key={r.id}>{r.label}</MenuItem>
                            ))
                          }
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item mr={2}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          inputFormat="yyyy-MM-dd"
                          label={t('start')}
                          value={dateStartValue}
                          onChange={(newValue) => {setStartDateValue(newValue);}}
                          renderInput={(params) => <TextField size={'small'} sx={{width:"150px",marginRight:'8px'}} {...params} />}
                        />
                        <DatePicker
                          inputFormat="yyyy-MM-dd"
                          label={t('end')}
                          value={dateEndValue}
                          onChange={(newValue) => {setEndDateValue(newValue);}}
                          renderInput={(params) => <TextField className='rwd-sm' size={'small'} sx={{width:"150px"}} {...params} />}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item mr={2}>
                      <RadioGroup name="dayOrMonth" value={dayOrMonth} onChange={handleChange} row>
                        <FormControlLabel value="daily" control={<Radio />} label="Daily" />
                        <FormControlLabel value="monthly" control={<Radio />} label="Monthly" />
                      </RadioGroup>
                    </Grid>
                    <Grid item mr={2}>
                      <Button variant="contained" onClick={handleSearch}>{t('search')}</Button>
                    </Grid>
                    <Grid item mr={2}>
                      <Button variant="contained" onClick={handleDownloadCSV}>{t('download_csv')}</Button>
                    </Grid>
                  </Grid>
                  <Grid container sx={{alignItems: 'flex-end'}}>
                    {
                      userInfo.role!=="User" &&
                      <Grid item mr={2}>
                        <Autocomplete
                        disableClearable
                        id="user"
                        value={userValue}
                        onChange={(event, newValue) => {
                          setUserValue(newValue);
                          handleUserChange(event);
                        }}
                        inputValue={inputUser}
                        onInputChange={(event, newInputValue) => {
                          setInputUser(newInputValue);
                        }}
                        options={userArr}
                        sx={{ width: 160, mt: 2 }}
                        noOptionsText="無相關網站主"
                        size="small"
                        renderOption={(props, option) => {
                          return (
                            <li {...props} value={option.value}>
                              {option.label}
                            </li>
                          );
                        }}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        renderInput={(params) => <TextField size="small" name="user" {...params} label="網站主" />}
                      />
                      </Grid>
                    }
                    <Grid item sx={{mr:2}}>
                      <Autocomplete
                          disableClearable
                          id="site"
                          value={siteValue}
                          onChange={(event, newValue) => {
                            setSiteValue(newValue);
                            handleSiteChange(event);
                          }}
                          inputValue={inputSite}
                          onInputChange={(event, newInputValue) => {
                            setInputSite(newInputValue);
                          }}
                          options={siteArr}
                          sx={{ width: 160, mt: 2 }}
                          noOptionsText={t('no_data')}
                          size="small"
                          renderOption={(props, option) => {
                            return (
                              <li {...props} value={option.value}>
                                {option.label}
                              </li>
                            );
                          }}
                          isOptionEqualToValue={(option, value) => option.value === value.value}
                          renderInput={(params) => <TextField size="small" name="site" {...params} label={t('website')} />}
                        />
                      </Grid>
                      <Grid item>
                      <FormControl className='rwd-sm rwd-smd' sx={{ minWidth: 160, mr: 2 }} size="small">
                        <InputLabel id="demo-simple-select-helper-label">{t('unit')}</InputLabel>
                        <Select
                          value={unit}
                          disabled={unitSelector || filter==='byUnit' || filter==='byPlatform'}
                          label={t('unit')}
                          onChange={handleUnitChange}
                        >
                          <MenuItem key='all' value='all'>{t('all')}</MenuItem>
                          {
                            unitArr.length > 0 && 
                            unitArr.map((u, index)=>(
                              <MenuItem key={u.id} value={u.id}>{u.display_name}{u.status===false && `(${t('deactivate')})`}</MenuItem>
                            ))
                          }
                        </Select>
                      </FormControl>
                      </Grid>
                      <Grid item>
                      <FormControl className='rwd-sm rwd-md' sx={{ minWidth: 160, mr: 2 }} size="small">
                        <InputLabel id="demo-simple-select-helper-label">{t('breakdown_by')}</InputLabel>
                        <Select
                          value={filter}
                          label={t('breakdown_by')}
                          onChange={handleFilterChange}
                        >
                          <MenuItem value='byDate'>{t('date')}</MenuItem>
                          <MenuItem value='byPlatform'>{t('platform')}</MenuItem>
                          <MenuItem value='byWebsite'>{t('website')}</MenuItem>
                          <MenuItem value='byUnit'>{t('unit')}</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item sx={{mr:2}}>
                      { userInfo.role!=='User' &&
                        <Button variant="contained" disabled={showDownloadMonthly} onClick={handleDownloadReport}>下載月報表</Button>
                      }
                    </Grid>
                    <Grid item>
                      { userInfo.role!=='User' &&
                        <Typography variant='subtitle1' sx={{width:100}} component='p'>{unit==='all'?'':('扣量：'+unitInfo+'%')}</Typography>
                      }
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              { chartArr.length>0 && !matches && showChart && 
                <Card sx={{ minWidth: 275, mt: "16px" }}>
                  <CardContent>
                    <ReportChart data={chartArr}/>
                  </CardContent>
                </Card>
              }
              {
                userInfo.role==='User' && 
                <>
                <Typography component='p' variant='caption' sx={{color: theme.palette.warning.light, mt:2}}> -{t('note_1')}</Typography>
                <Typography component='p' variant='caption' sx={{color: theme.palette.warning.light}}> -{t('note_2')}</Typography>
                </>
              }
              {
                matches && 
                <Typography component='p' variant='caption'>＊{t('note_3')}</Typography>
              }
              { showTable &&
                <Paper sx={{ width: '100%', mt: 2 }} className="tablePaper">
                  <TableContainer>
                    <Table aria-label="table" size="small" className='reportTable'>
                      <TableHead>
                        <TableRow>
                          { userInfo.role !== 'User' && showFilter==='byDate' &&
                              columnsForManager.map((column) => (
                                <TableCell
                                  key={column.id}
                                  align={column.align}
                                  style={{ minWidth: column.minWidth }}
                                >
                                  {
                                    showFilter==='byDate'&& column.label!=='批量' && (
                                      column.label==='日期' && dayOrMonth==='monthly' 
                                      ? '月份'
                                      : column.label==='預估收益' && unit!=='all' && reportArr.length>0 
                                        ? <><Typography sx={{fontSize: '14px', fontWeight: 500}}>{column.label}</Typography><Typography sx={{fontSize: '14px', fontWeight: 500}}>{reportArr[0].out_currency.code}</Typography></>
                                        : column.label
                                    )
                                  }
                                  {
                                    column.label==='批量' && !deleteMultiple && userInfo.role!=='Accounting' && showUnit &&
                                      <Button variant="outlined" onClick={()=>setDeleteMultiple(true)} size="small">批量</Button>
                                  }
                                  {
                                    column.label==='批量' && deleteMultiple && userInfo.role!=='Accounting' && showUnit &&
                                      <Checkbox checked={checkAll} onClick={handleCheckAll} />
                                  }
                                </TableCell>
                            ))
                          }{userInfo.role !== 'User' && showFilter!=='byDate' && showFilter!=='byUnit' &&
                            columnsFilterForManager.map((column) => (
                              <TableCell
                                key={column.id}
                                align={column.align}
                                style={{ minWidth: column.minWidth }}
                              >
                                {
                                  showFilter==='byUnit' && (
                                    column.label==='日期' ? '廣告版位' : column.label)
                                }
                                {
                                  showFilter==='byPlatform' && (
                                    column.label==='日期' ? '廣告平台' : column.label)
                                }
                                {
                                  showFilter==='byWebsite' && (
                                    column.label==='日期' ? '網站' : column.label)
                                }
                              </TableCell>
                            ))
                          }
                          { userInfo.role !== 'User' && showFilter==='byUnit' &&
                            columnsFilterByUnitForManager.map((column) => (
                              <TableCell
                                key={column.id}
                                align={column.align}
                                style={{ minWidth: column.minWidth }}
                              > {column.label} </TableCell>
                            ))
                          }
                          { userInfo.role === 'User' && showFilter!=='byDate' &&
                            columnsFilterForUser.map((column) => (
                              <TableCell
                                key={column.id}
                                align={column.align}
                                style={{ minWidth: column.minWidth }}
                              >
                                {
                                  showFilter==='byUnit' && (
                                    column.label==='日期' ? '廣告版位' : column.label)
                                }
                                {
                                  showFilter==='byPlatform' && (
                                    column.label==='日期' ? '廣告平台' : column.label)
                                }
                                {
                                  showFilter==='byWebsite' && (
                                    column.label==='日期' ? '網站' : column.label)
                                }
                              </TableCell>
                            ))
                          }
                          { userInfo.role === 'User' && showFilter==='byDate' &&
                              columnsForUser.map((column) => (
                                <TableCell
                                  key={column.id}
                                  align={column.align}
                                  style={{ minWidth: column.minWidth }}
                                >
                                  {
                                    showFilter==='byDate'&& (
                                      column.label==='日期' && dayOrMonth==='monthly' 
                                      ? '月份'
                                      : column.label
                                    )
                                  }
                                </TableCell>
                              ))
                          }
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {
                          loading && 
                          <TableRow>
                            <TableCell align="center" colSpan={userInfo.role==='User'?9:11}>
                              <CircularProgress />
                            </TableCell>
                          </TableRow>
                        }
                        { 
                          !loading && ((showFilter==='byDate' &&reportArr.length === 0) || (showFilter!=='byDate' && reportByFilterArr.length===0)) &&
                          <TableRow>
                            <TableCell align="center" colSpan={userInfo.role==='User'?9:11}>
                              {t('no_data')}
                            </TableCell>
                          </TableRow>
                        }
                        { 
                          (!loading && showFilter!=='byDate' && showFilter!=='byUnit') && 
                          reportByFilterArr.map((row, index) => (
                            <StyledTableRow key={index}>
                              <TableCell align='center'>{row.name}</TableCell>
                              <TableCell align='right'>
                                {parseFloat(row.impressions).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})}</TableCell>
                              <TableCell align='right'>{parseFloat(row.clicks).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})}</TableCell>
                              <TableCell align='right'>{parseFloat(row.ctr).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})}%</TableCell>
                              <TableCell align='right'>{parseFloat(row.cpm).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})}</TableCell>
                              {
                                userInfo.role !== 'User' &&
                                <TableCell align='right'>{parseFloat(row.true_revenue).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})}</TableCell>
                              }
                              <TableCell align='right'>{parseFloat(row.final_revenue).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})}</TableCell>
                            </StyledTableRow>
                          ))
                        }
                        { 
                          (!loading && showFilter==='byUnit') && 
                          reportByFilterArr.map((row, index) => (
                            <StyledTableRow key={index}>
                              <TableCell align='center'>{row.user.username}</TableCell>
                              <TableCell align='center'>{row.website.name}</TableCell>
                              <TableCell align='center'>{row.name}</TableCell>
                              <TableCell align='right'>
                                {parseFloat(row.impressions).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})}</TableCell>
                              <TableCell align='right'>{parseFloat(row.clicks).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})}</TableCell>
                              <TableCell align='right'>{parseFloat(row.ctr).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})}%</TableCell>
                              <TableCell align='right'>{parseFloat(row.cpm).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})}</TableCell>
                              {
                                userInfo.role !== 'User' &&
                                <TableCell align='right'>{parseFloat(row.true_revenue).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})}</TableCell>
                              }
                              <TableCell align='right'>{parseFloat(row.final_revenue).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})}</TableCell>
                            </StyledTableRow>
                          ))
                        }
                        {
                          !loading && showFilter==='byDate' &&
                          reportArr.map((row, index) => (
                            <StyledTableRow key={index} className={checkedArr[index]?'checked':''}>
                              {
                                userInfo.role !== 'User' && showFilter==='byDate' &&
                                columnsForManager.map((column) => (
                                  <TableCell key={column.id} align={column.align}>
                                    {
                                      column.format
                                        ? column.format(row[column.id])
                                        : (column.id==='website'
                                            ? row.website.name
                                            : (column.id==='platform'
                                                ? row.platform.name
                                                : ((column.id==='adunit' && row.adunit)
                                                  ? row.adunit.display_name
                                                  : ((column.id==='adunit' && !row.adunit)
                                                    ? 'All'
                                                    : (column.id==='action' && showUnit)
                                                      ? ((deleteMultiple) 
                                                        ? <Checkbox checked={checkedArr[index]} onClick={event=>handleCheckOne(event,row.id,index)} />
                                                        :
                                                          <IconButton sx={{p:0}} aria-label="delete_report" size="small" onClick={()=>{
                                                            setOpen(true)
                                                            setDeleteReport(row.id)}}>
                                                            <DeleteIcon fontSize="small" />
                                                          </IconButton>)
                                                      : row[column.id]
                                                  )
                                              )
                                          )
                                        )
                                    }
                                  </TableCell>
                                ))
                              }
                              {
                                userInfo.role === 'User' && 
                                columnsForUser.map((column, index) => (
                                  <TableCell key={index} align={column.align}>
                                    {
                                      column.format
                                        ? column.format(row[column.id])
                                        : (column.id==='website'
                                            ? row.website.name
                                            : ((column.id==='adunit' && row.adunit)
                                                ? row.adunit.display_name
                                                : ((column.id==='adunit' && !row.adunit)
                                                  ? 'All'
                                                  : (column.id==='platform'
                                                    ? row.platform.name
                                                    : row[column.id]
                                                  )
                                                )
                                            )
                                        )
                                    }
                                  </TableCell>
                                ))
                              }
                            </StyledTableRow>
                          )) 
                        }
                        {
                          (summary && !loading && showFilter==='byDate' && reportArr.length>0) &&
                          <TableRow sx={{backgroundColor: '#ccc'}}>
                            <TableCell align='center' colSpan={4} sx={{fontWeight: 700}}> Total </TableCell>
                            <TableCell align='right' sx={{fontWeight: 700}}>{(parseInt(summary.impressions)).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})}</TableCell>
                            <TableCell align='right' sx={{fontWeight: 700}}>{(parseInt(summary.clicks)).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})}</TableCell>
                            <TableCell align='right' sx={{fontWeight: 700}}>{(parseFloat(summary.ctr)).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})+'%'}</TableCell>
                            <TableCell align='right' sx={{fontWeight: 700}}>{(parseFloat(summary.cpm)).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})}</TableCell>
                            { userInfo.role!=='User' &&
                              <TableCell align='right' sx={{fontWeight: 700}}>{(parseFloat(summary.true_revenue)).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})}</TableCell>
                            }                        
                              <TableCell align='right' sx={{fontWeight: 700}}>{(parseFloat(summary.final_revenue)).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})}</TableCell>
                            { userInfo.role!=='User' &&
                              <TableCell align='center'>
                              {
                                deleteMultiple && 
                                <>
                                  <Button variant="contained" color="error" onClick={()=>setOpen(true)} size="small">刪除</Button>
                                  <Button variant="outkined" color="primary" onClick={()=>{    setDeleteMultiple(false);handleUnCheckAll()}} size="small">取消</Button>
                                </>
                              }
                              {
                                !deleteMultiple && 
                                '-' 
                              }
                              </TableCell>
                            }
                          </TableRow>
                        }
                        {
                          (summary && !loading && showFilter!=='byDate' && showFilter!=='byUnit' && reportByFilterArr.length>0) &&
                          <TableRow sx={{backgroundColor: '#ccc', fontWeight: 700}}>
                            <TableCell align='center' sx={{fontWeight: 700}}> Total </TableCell>
                            <TableCell align='right' sx={{fontWeight: 700}}>{(parseInt(summary.impressions)).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})}</TableCell>
                            <TableCell align='right' sx={{fontWeight: 700}}>{(parseInt(summary.clicks)).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})}</TableCell>
                            <TableCell align='right' sx={{fontWeight: 700}}>{(parseFloat(summary.ctr)).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})+'%'}</TableCell>
                            <TableCell align='right' sx={{fontWeight: 700}}>{(parseFloat(summary.cpm)).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})}</TableCell>
                            {
                              userInfo.role!=='User' &&
                              <TableCell align='right' sx={{fontWeight: 700}}>{(parseFloat(summary.true_revenue)).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})}</TableCell>
                            }
                            <TableCell align='right' sx={{fontWeight: 700}}>{(parseFloat(summary.final_revenue)).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})}</TableCell>
                          </TableRow>
                        }
                      </TableBody>
                    </Table>  
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[30, 50]}
                    component="div"
                    count={pageInfo}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Paper>
              }
            </Container>
          </Box>
        </Box>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClick={handleClose}
          aria-describedby="alert-dialog-slide-description"
          sx={{zIndex: "1301"}}
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              確定要{deleteMultiple?'批量刪除':'刪除這筆'}資料嗎？
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{justifyContent: "space-between"}}>
            <Button onClick={handleClose}>取消</Button>
            <Button onClick={deleteMultiple?handleDeleteMultiple:handleDelete}>確定</Button>
          </DialogActions>
        </Dialog>
      </MenuContext.Provider>
    </ThemeProvider>
  )
}
