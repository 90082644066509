import React, { useState, useContext, useEffect } from 'react'
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Slide from '@mui/material/Slide';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import { OpenContext } from '../context/OpenContext'
import { UserContext } from '../context/UserContext'
import { AlertContext } from '../context/AlertContext'
import useDidMountEffect from '../hooks/useDidMountEffect'
import Autocomplete from '@mui/material/Autocomplete';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddSiteDialog({title}) {
  const { dialogOpen, setDialogOpen } = useContext(OpenContext)
  const { setAlertOpen } = useContext(AlertContext)
  const { userInfo } = useContext(UserContext)
  const [ errorMessage, setErrorMessage ] = useState('')
  const [ userArr, setUserArr ] = useState([])
  const [ user, setUser ] = useState('');
  const [ userValue, setUserValue ] = useState('')
  const [ inputUser, setInputUser ] = useState('')
  const [ formData, setFormData ] = useState({
    user_id: '',
    name: '',
    domain: '',
    dable_code: '',
    pmc_name: '',
    taboola_name:'',
    auto: true
  })
  const [formValid, setFormValid] = useState(true)
  const [loading, setLoading] = useState(false)

  const handleClose = () => { 
    setDialogOpen({
      add: false,
      edit: false,
    })
    setFormData({
      user_id: '',
      name: '',
      domain: '',
      auto: true,
      dable_code: '',
      pmc_name: '',
      taboola_name:'',
    })
    setUser('')
    setUserValue('')
    setInputUser('')
    setLoading(false)
  }

  const handleUserChange = (event) => {
    setUser(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    setLoading(true)
    setFormValid(true)

    if(data.get('name')==='' || user==='' ){
      setFormValid(false)
      setErrorMessage('請確認所有必填欄位已填寫正確')
      return
    }

    setFormData({
      user_id: user,
      name: data.get('name'),
      domain: data.get('domain'),
      dable_code: data.get('dable_code'),
      pmc_name: data.get('pmc_name'),
      taboola_name: data.get('taboola_name'),
      status: true,
      auto: true
    })

  }

  useDidMountEffect(() => {
    if(formValid && formData.name !== ''){
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userInfo.token },
        body: JSON.stringify(formData)
      };
      
      fetch('https://api.futurekey.org/api/websites', requestOptions)
        .then(response => response.json())
        .then(data => {
          if(data.message==='OK')  {
            handleClose()
            setLoading(false)
            setAlertOpen({
              open: true,
              message: '新增成功'
            })
          }else{
            console.log(data)
            setLoading(false)
            setFormValid(false)
          }
        })
    }
   }, [formData])

  useEffect (() => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userInfo.token},
    };

    fetch('https://api.futurekey.org/api/users?role=User&per_page=500&page=1', requestOptions)
    .then(response => response.json())
    .then(data => {
      if(data.message==='OK'){
        let newUserArr = data.data.users.map((u) => {
          return {
            label: u.username,
            value: u.id,
          }
        })
        setUserArr(newUserArr)
      }else{
        console.log(data.message)
      }
    })
  },[userInfo.token])

  return (
    <Dialog open={dialogOpen.add} onClose={handleClose} TransitionComponent={Transition}>
      <DialogTitle>
        {title}
        <IconButton aria-label="close" onClick={handleClose} sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500], }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Box component="form" noValidate onSubmit={handleSubmit} >
        <DialogContent sx={{ py: 0 }}>
          <Autocomplete
            disableClearable
            id="user"
            value={userValue}
            onChange={(event, newValue) => {
              setUserValue(newValue);
              handleUserChange(event);
            }}
            inputValue={inputUser}
            onInputChange={(event, newInputValue) => {
              setInputUser(newInputValue);
            }}
            options={userArr}
            sx={{ width: '100%' }}
            noOptionsText="無相關網站主"
            size="small"
            renderOption={(props, option) => {
              return (
                <li {...props} value={option.value}>
                  {option.label}
                </li>
              );
            }}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            renderInput={(params) => <TextField size="small" variant='standard' name="user" {...params} label="網站主" />}
          />
          {/* <FormControl error={!formValid} required variant='standard' margin="dense" fullWidth>
            <InputLabel id="user-select-helper-label">網站主</InputLabel>
            <Select
              size="small"
              labelId="user-select-label"
              id="user_select"
              name="user_select"
              label="網站主"
              value={user}
              onChange={handleUserChange}
            >
              {
                userArr && 
                userArr.map((u, index) => (
                  <MenuItem key={u.id} value={u.id}>{u.username}</MenuItem>
                ))
              }
            </Select>
          </FormControl> */}
          <TextField
            required
            margin="dense"
            id="name"
            name="name"
            label="網站名稱"
            type="text"
            fullWidth
            variant="standard"
            error={!formValid}
            helperText={!formValid ? errorMessage : ''}
          />
          <TextField
            margin="dense"
            id="domain"
            name="domain"
            label="域名"
            type="text"
            fullWidth
            variant="standard"
          />
          <Grid container sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
            <Grid item xs={6}>
              <TextField
                margin="dense"
                id="pmc_name"
                name="pmc_name"
                label="第一平台網站名稱"
                sx={{ width: '95%' }}
                type="text"
                fullWidth
                variant="standard"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin="dense"
                id="dable_code"
                name="dable_code"
                label="Dable平台網站ID"
                sx={{ width: '95%' }}
                type="text"
                fullWidth
                variant="standard"
              />
            </Grid>
          </Grid>
          <Grid container sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
            <Grid item xs={6}>
              <TextField
                margin="dense"
                id="taboola_name"
                name="taboola_name"
                label="taboola平台網站名稱"
                sx={{ width: '95%' }}
                type="text"
                fullWidth
                variant="standard"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>取消</Button>
          {
            loading &&
            <LoadingButton
              loading
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="outlined"
            >
              處理中
            </LoadingButton>
          }
          {
            !loading &&
            <Button variant="contained" type="submit">儲存</Button>
          }
        </DialogActions>
      </Box>
    </Dialog>
  )
}
