import React, { useState, useContext } from 'react'

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import { OpenContext } from '../context/OpenContext'
import { UserContext } from '../context/UserContext'
import useDidMountEffect from "../hooks/useDidMountEffect";

function Row(props) {
  const { row } = props;
  return(
    <>
      <TableRow>
        <TableCell sx={{ width : '50%', fontWeight: 700 }} align="left">{row.name}</TableCell>
        <TableCell sx={{ width : '50%', fontWeight: 700 }} align="right">{parseFloat(row.total).toLocaleString('en-US')}</TableCell>
      </TableRow>
      {row.items.map((i)=>(
          <TableRow key={i.name}>
            <TableCell sx={{ width : '50%', pl:5}} align="left">{i.name}</TableCell>
            <TableCell sx={{ width : '50%'}} align="right">{parseFloat(i.subtotal).toLocaleString('en-US')}</TableCell>
          </TableRow>
      ))}
    </>
  )
}

export default function ViewAccountingDetailDialog({title, id}) {
  const { dialogOpen, setDialogOpen } = useContext(OpenContext)
  const { userInfo } = useContext(UserContext)
  const [ loading, setLoading ] = useState(false)
  const [ bill, setBill ] = useState({
    type: '',
    currency: {
      code: ''
    },
    details: [{
      name: "",
      total: '',
      items: [
        {
          name: "",
          total: ""
        }
      ]
    }]
  })

  //取消編輯時
  const handleClose = () => {
    setDialogOpen({
      edit: false,
      view: false  
    })
  };

  useDidMountEffect (() => {
    if(id!==''){
      setLoading(true)
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userInfo.token }
      };

      fetch('https://api.futurekey.org/api/billings/' + id, requestOptions)
      .then(response => response.json())
      .then(data => {
        if(data.message==='OK')  {
          setBill(data.data)
          setLoading(false)
        }else{
          setLoading(true)
          console.log(data)
        }
      })
    }
  },[id])

  return (
    <Dialog open={dialogOpen.view} fullWidth onClose={handleClose}>
      <DialogTitle>
        {title}
        <IconButton aria-label="close" onClick={handleClose} sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500], }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
        <DialogContent>
        <TableContainer>
          {
            loading ?
            <CircularProgress sx={{ margin: '10px auto 20px' }} />
            :
            <Table sx={{ minWidth: 300, width: '100%' }} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width : '50%'}} align="center">項目</TableCell>
                  <TableCell sx={{ width : '50%'}} align="right">收益({bill.currency.code})</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {bill.details.map((row) => (
                row.items.length>0 &&
                  <Row key={row.name} row={row} />
              ))}
              <TableRow sx={{ backgroundColor: '#ccc' }}>
                <TableCell sx={{ width : '50%', fontWeight:700 }} align="left">當月總計</TableCell>
                <TableCell sx={{ width : '50%', fontWeight:700 }} align="right">{(parseFloat(bill.income)).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})}</TableCell>
              </TableRow>
              </TableBody>
            </Table>
          }
          </TableContainer>
        </DialogContent>
    </Dialog>
  )
}
