import React, { useState, useContext, useEffect } from 'react'
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import { OpenContext } from '../context/OpenContext'
import { UserContext } from '../context/UserContext'
import { AlertContext } from '../context/AlertContext'
import useDidMountEffect from '../hooks/useDidMountEffect'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function EditUserDialog({title, data}) {
  const { dialogOpen, setDialogOpen } = useContext(OpenContext)
  const { setAlertOpen } = useContext(AlertContext)
  const { userInfo } = useContext(UserContext)
  const [ errorMessage, setErrorMessage ] = useState('')
  const [ currencyArr, setCurrencyArr ] = useState([])
  const [ currency, setCurrency ] = useState('');
  const [ formData, setFormData ] = useState({
    name: '',
    currency_id:'',
    username: '',
    email: '',
    status: ''
  })
  const [ formValid, setFormValid ] = useState(true)
  const [ loading, setLoading ] = useState(false)
  const [ open, setOpen ] = useState(false)
  const [ loadingD, setLoadingD ] = useState(false)

  const handleClose = () => { 
    setDialogOpen({
      add: false,
      view: false,
      edit: false,
    })
    setFormData({
      name: '',
      currency_id:'',
      username: '',
      email: '',
      status: ''
    })
    setCurrency(1)
    setLoading(false)
  }

  const handleCurrencyChange = (event) => {
    setCurrency(event.target.value);
  };

  const handleConfirmClose = () => {
    setOpen(false)
  }

  const handleDelete = () => {
    if(open){
      setLoadingD(true)
      var requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userInfo.token},
      };

      fetch('https://api.futurekey.org/api/users/'+ data.id, requestOptions)
      .then(response => response.json())
      .then(data => {
        if(data.message==='OK'){
          setAlertOpen({
            open: true,
            message: '帳號已刪除'
          })
          setLoadingD(false)
          handleClose()
          handleConfirmClose()
        }else{
          setLoadingD(false)
          console.log(data.message)
        }
      })

    }else{
      setOpen(true)
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const d = new FormData(event.currentTarget);
    setFormValid(true)
    setLoading(true)

    var regNumber = /\d+/;
    var regString = /[a-zA-Z]+/; 

    if(d.get('username')===''){
      setFormValid(false)
      setErrorMessage('請確認所有欄位已填寫正確')
      return
    }

    if(d.get('password') !=='' && (!regNumber.test(d.get('password')) || !regString.test(d.get('password')) )){
      setFormValid(false)
      setErrorMessage('密碼需至少為8位數的英數混合')
      return
    }
    
    if(d.get('password') !==''){
      setFormData({
        name: d.get('name'),
        currency_id: currency,
        password: d.get('password'),
        email: d.get('email'),
        status: data.status
      })
    }else{
      setFormData({
        name: d.get('name'),
        currency_id: currency,
        email: d.get('email'),
        mails: d.get('mails'),
        status: data.status
      })
    }

  }

  useDidMountEffect(() => {
    if(formValid && formData.username !== ''){
      const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userInfo.token },
        body: JSON.stringify(formData)
      };
      
      fetch('https://api.futurekey.org/api/users/'+ data.id, requestOptions)
        .then(response => response.json())
        .then(data => {
          if(data.message==='OK')  {
            setLoading(false)
            handleClose()
            setAlertOpen({
              open: true,
              message: '儲存成功'
            })
          }else{
            setLoading(false)
            console.log(data)
            setFormValid(false)
          }
        })
    }
   }, [formData])

  useEffect (() => {
    setCurrency(data.currency.id)
  },[data])

  useEffect (() => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userInfo.token},
    };

    fetch('https://api.futurekey.org/api/currencies', requestOptions)
    .then(response => response.json())
    .then(data => {
      if(data.message==='OK'){
        setCurrencyArr(data.data)
      }else{
        console.log(data.message)
      }
    })
  },[userInfo.token])

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClick={handleClose}
        aria-describedby="alert-dialog-slide-description"
        sx={{zIndex: "1301"}}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            確定要刪除此帳號嗎？
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{justifyContent: "space-between"}}>
          <Button onClick={handleConfirmClose}>取消</Button>
          {
            loadingD &&
            <LoadingButton
              loading
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="outlined"
            >
              處理中
            </LoadingButton>
          }
          {
            !loadingD &&
            <Button onClick={handleDelete}>確定</Button>
          }
        </DialogActions>
      </Dialog>
      <Dialog open={dialogOpen.edit} onClose={handleClose} TransitionComponent={Transition}>
        <DialogTitle>
          {title}
          <IconButton aria-label="close" onClick={handleClose} sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500], }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Box component="form" noValidate onSubmit={handleSubmit} >
          <DialogContent sx={{ py: 0 }}>
            { data.role === 'User' &&
              <FormControl error={!formValid} required variant='standard' margin="dense" fullWidth>
                <InputLabel id="type-select-helper-label">結算貨幣</InputLabel>
                <Select
                  size="small"
                  variant='standard'
                  labelId="currency-select-label"
                  id="currency_select"
                  name="currency_select"
                  label="結算貨幣"
                  value={currency}
                  onChange={handleCurrencyChange}
                >
                  {
                    currencyArr.map((c, index) => (
                      <MenuItem key={c.id} value={c.id}>{c.name}{c.code}</MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            }
            <TextField
              margin="dense"
              value={data.username}
              id="username"
              name="username"
              label="帳號"
              type="text"
              fullWidth
              variant="standard"
              disabled
            />
            <TextField
              margin="dense"
              defaultValue={data.name}
              id="name"
              name="name"
              label="聯絡人"
              type="text"
              fullWidth
              variant="standard"
            />
            <TextField
              margin="dense"
              defaultValue={data.email}
              id="email"
              name="email"
              label="Email"
              type="email"
              fullWidth
              variant="standard"
            />
            <TextField
              margin="dense"
              defaultValue={data.mails}
              id="mails"
              name="mails"
              label="會計聯絡人Email"
              type="text"
              fullWidth
              variant="standard"
              helperText='請以逗點","分隔多個email'
            />
            <TextField
              margin="dense"
              id="password"
              name="password"
              label="新密碼"
              type="text"
              fullWidth
              variant="standard"
              helperText={ !formValid ? errorMessage : "密碼需至少為8位數的英數混合" }
              error={!formValid}
            />
          </DialogContent>
          <DialogActions sx={{justifyContent: 'space-between'}}>
            <Grid item>
              <Button color="error" variant='outlined' onClick={handleDelete}>刪除</Button>
            </Grid>
            <Grid item>
              <Button sx={{mr: 2}} onClick={handleClose}>取消</Button>
              {
                loading &&
                <LoadingButton
                  loading
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  variant="outlined"
                >
                  處理中
                </LoadingButton>
              }
              {
                !loading &&
                <Button variant="contained" type="submit">儲存</Button>
              }
            </Grid>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  )
}
