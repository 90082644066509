import React, { useState, useContext, useEffect } from 'react'
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ForwardIcon from '@mui/icons-material/Forward';
import { OpenContext } from '../context/OpenContext'
import { UserContext } from '../context/UserContext'
import { AlertContext } from '../context/AlertContext'

import useDidMountEffect from "../hooks/useDidMountEffect";

export default function AddRateDialog({title}) {
  const { dialogOpen, setDialogOpen } = useContext(OpenContext)
  const { userInfo } = useContext(UserContext)
  const { setAlertOpen } = useContext(AlertContext)
  const [ errorMessage, setErrorMessage ] = useState('')
  const [ currencyArr, setCurrencyArr ] = useState([])
  const [ formData, setFormData ] = useState({
    from_id: '',
    to_id: '',
    rate: ''
  })
  const [ formValid, setFormValid ] = useState(true)
  const [ from, setFrom ] = useState('') 
  const [ to, setTo ] = useState('') 
  const [ rate, setRate ] = useState('') 

  const handleClose = () => {
    setDialogOpen({
      edit: false,
      add: false
    })
    setFormValid(true)
    setFormData({
      from_id: '',
      to_id: '',
      rate: ''
    })
    setRate('')
    setTo('1')
    setFrom('0')
    setErrorMessage('')
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data= {
      from_id: currencyArr[from].id,
      to_id: currencyArr[to].id,
      rate: rate
    }

    if(rate===''){
      setFormValid(false)
      setErrorMessage('請確認欄位皆已填寫')
      return
    }
    
    setFormData(data)
  }

  const handleFromChange = (event) => {
    setFrom(event.target.value);
  };

  const handleToChange = (event) => {
    setTo(event.target.value);
  };

  const handleChange = (event) => {
    setRate(event.target.value);
  };

  const handleSetCurrentRate = () => {
    const fromCode = currencyArr[from].code
    const toCode = currencyArr[to].code
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userInfo.token},
    };

    fetch('https://api.futurekey.org/api/realtime/exchange_rate?from='+fromCode+'&to='+toCode, requestOptions)
    .then(response => response.json())
    .then(data => {
      if(data.message==='OK'){
        setRate(data.data.rate)

      }else{
        console.log(data.message)
      }
    })

  }

  useEffect(() => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userInfo.token},
    };
    
    fetch('https://api.futurekey.org/api/currencies', requestOptions)
    .then(response => response.json())
    .then(data => {
      if(data.message==='OK'){
        setCurrencyArr(data.data)
        setTo('0')
        setFrom('0')
      }else{
        console.log(data.message)
      }
    })
  },[userInfo])

  useDidMountEffect(() => {
    if(formValid && rate!==''){
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userInfo.token },
        body: JSON.stringify(formData)
      };

      fetch('https://api.futurekey.org/api/exchange_rates', requestOptions)
      .then(response => response.json())
      .then(data => {
        if(data.message==='OK'){
          setAlertOpen(true)
          handleClose()
        }else{
          console.log(data.message)
          setFormValid(false)
          setErrorMessage('重複新增！')
        }
      })
    }

  },[formData])

  return (
    <Dialog open={dialogOpen.add} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <Box component="form" noValidate onSubmit={handleSubmit} >
        <DialogContent sx={{ py: 0 }}>
          <Grid container alignItems='center'>
            <Grid item xs={5} >
              <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} size="small">
                <Select
                  id="from"
                  name="from"
                  value={from}
                  onChange={handleFromChange}
                  error={!formValid}
                >
                  {
                    currencyArr.length>0 &&
                    currencyArr.map((c, index) => (
                      <MenuItem key={c.code} value={index}>{c.name}</MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2} sx={{textAlign: 'center'}}>
              <ForwardIcon />
            </Grid>
            <Grid item xs={5} >
              <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} size="small">
                <Select
                  id="to"
                  name="to"
                  value={to}
                  onChange={handleToChange}
                  error={!formValid}
                >
                  {
                    currencyArr.length>0 &&
                    currencyArr.map((c, index) => (
                      <MenuItem key={c.code} value={index}>{c.name}</MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container alignItems='flex-end'>
            <Grid item xs={5}>
              <TextField
                margin="dense"
                id="rate"
                name="rate"
                label="匯率"
                type="text"
                fullWidth
                value={rate}
                onChange={handleChange}
                variant="standard"
                sx={{ ml: 1 }}
                error={!formValid}
                helperText={!formValid ? errorMessage : ""}
              />
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={5}>
              <Button fullWidth variant="outlined" onClick={handleSetCurrentRate}>即時匯率</Button>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} type="button">取消</Button>
          <Button variant="contained" type="submit">確認</Button>
        </DialogActions>
      </Box>
    </Dialog>
  )
}
