import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './i18/i18n'; 

import HomePage from './HomePage';
import Login from './Login';
import Profile from './Profile'
import Report from './Report'
import AddReport from './AddReport'
import ExchangeRate from './ExchangeRate'
import User from './User';
import { UserContext } from './context/UserContext'
import Website from './Website';
import Post from './Post';
import Adunit from './Adunit';
import Platform from './Platform';
import Accounting from './Accounting';
import Billing from './Billing';
import BillNotice from './BillNotice';
import ResetPsd from './ResetPsd';

export default function App() { 
  const { t } = useTranslation();

  const [userInfo, setUserInfo] = useState({
    token: sessionStorage.getItem('token'),
    username: sessionStorage.getItem('username'),
    name: sessionStorage.getItem('name'),
    role: sessionStorage.getItem('role'),
    last_ip: sessionStorage.getItem('last_ip'),
    last_date: sessionStorage.getItem('last_date'),
    email: sessionStorage.getItem('email'),
    id: sessionStorage.getItem('id'),
    is_login: sessionStorage.getItem('token') ? true : false
  })

  useEffect(() => {
    document.title = t("main_title");  // 设置页面标题
  }, [t]);

  if(userInfo.is_login){
    const events = ['load', 'mousemove', 'mousedown', 'click', 'scroll', 'keypress'];

    const handleAutoLogout = () =>{ 
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userInfo.token},
      };
  
      fetch('https://api.futurekey.org/api/auth/logout', requestOptions)
      .then(response => response.json())
      .then(data => {
        if(data.message==='OK'){
          sessionStorage.clear()
          setUserInfo({})
          window.location.reload()
        }else{
          console.log(data.message)
        }
      })
    }

    var logoutTimeout = setTimeout(handleAutoLogout,10*60*1000);
   
    const resetTimeout = () => {
      clearTimeout(logoutTimeout)
      logoutTimeout = setTimeout(handleAutoLogout,10*60*1000);
    }

    events.forEach(function(event) {
      window.addEventListener(event, resetTimeout);
    });

  }

  return (
    <BrowserRouter>
      <UserContext.Provider value={{userInfo, setUserInfo}}>
        <Routes>
          <Route path="/login" element={userInfo.is_login ? <Navigate to="/" /> : <Login />} />
          <Route path="/forget_password" element={userInfo.is_login ? <Navigate to="/" /> : <ResetPsd />} />
          <Route path="*" element={<p>找不到頁面</p>}/>

          <Route path="/" element={userInfo.is_login ? <HomePage /> : <Navigate to="/login" />} />
          <Route path="/profile" element={userInfo.is_login ? <Profile /> : <Navigate to="/login" />} />
          <Route path="/report" element={userInfo.is_login ? <Report /> : <Navigate to="/login" />} />
          <Route path="/add_report" element={userInfo.is_login ? <AddReport /> : <Navigate to="/login" />} />
          <Route path="/exchange_rate" element={userInfo.is_login ? <ExchangeRate /> : <Navigate to="/login" />} />
          <Route path="/billing" element={userInfo.is_login ? <Billing /> : <Navigate to="/login" />} />
          <Route path="/accounting" element={userInfo.is_login ? <Accounting /> : <Navigate to="/login" />} />
          <Route path="/bill_notice" element={userInfo.is_login ? <BillNotice /> : <Navigate to="/login" />} />
          <Route path="/user_list" element={userInfo.is_login ? <User /> : <Navigate to="/login" />} />
          <Route path="/website_list" element={userInfo.is_login ? <Website /> : <Navigate to="/login" />} />
          <Route path="/post_list" element={userInfo.is_login ? <Post /> : <Navigate to="/login" />} />
          <Route path="/adunit_list" element={userInfo.is_login ? <Adunit /> : <Navigate to="/login" />} />
          <Route path="/platform_list" element={userInfo.is_login ? <Platform /> : <Navigate to="/login" />} />
        </Routes>

      </UserContext.Provider>
    </BrowserRouter>
  );
}