import { grey, red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

// A custom theme for this app
export const theme = createTheme({
  palette: {
    primary: {
      main: '#0589D2',
    },
    secondary: {
      main: '#CD9895',
    },
    error: {
      main: red.A400,
    },
    active: {
      hover: '#0464B4',
      disabled: grey[300]
    },
    info: {
      main: grey[600]
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 425,
      md: 768,
      lg: 1024,
      xl: 1440,
    },
  },
  typography: {
    fontFamily: "'Noto Sans TC', 'Helvetica', 'Arial'",
    h1:{fontFamily: "'Noto Serif TC', 'Noto Sans TC', 'Helvetica', 'Arial'"},
    h2:{fontFamily: "'Noto Serif TC', 'Noto Sans TC', 'Helvetica', 'Arial'"},
    h3:{fontFamily: "'Noto Serif TC', 'Noto Sans TC', 'Helvetica', 'Arial'"},
    h4:{fontFamily: "'Noto Serif TC', 'Noto Sans TC', 'Helvetica', 'Arial'"},
    h5:{fontFamily: "'Noto Serif TC', 'Noto Sans TC', 'Helvetica', 'Arial'"},
    h6:{fontFamily: "'Noto Sans TC', 'Helvetica', 'Arial'", fontWeight:"400"},
  }
});