import React, { useEffect, useState, useContext } from 'react'
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

import Container from '@mui/material/Container';
import { ThemeProvider, styled } from '@mui/material/styles';
import { theme } from './theme'
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses }  from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Moment from 'react-moment';
import AddIcon from '@mui/icons-material/Add';
import HistoryIcon from '@mui/icons-material/History';
import Chip from '@mui/material/Chip';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import ForwardIcon from '@mui/icons-material/Forward';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { UserContext } from "./context/UserContext"
import { AlertContext } from "./context/AlertContext"
import { OpenContext } from "./context/OpenContext"
import { MenuContext } from "./context/MenuContext"
import HeaderAndMenu from './components/HeaderAndMenu'
import AddRateDialog from './components/AddRateDialog'
import EditRateDialog from './components/EditRateDialog'

function TransitionDown(props) {
  return <Slide {...props} direction="down" />;
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function ExchangeRate() {
  const { userInfo } = useContext(UserContext);
  const [ menuOpen, setMenuOpen ] = useState(true);
  const [ open, setOpen ] = useState(false)
  const [ pageOpen, setPageOpen ] = useState(false)
  const [ alertOpen, setAlertOpen ] = useState(false);
  const [ dialogTitle, setDialogTitle ] = useState('')
  const [ editId, setEditId ] = useState('')
  const [ dialogOpen, setDialogOpen ] = useState({
    edit: false,
    add: false
  })
  const [ exchangeArr, setExchangeArr ] = useState({
    id: '',
    rate: '',
    from: {
      name: '',
      code: ''
    },
    to: {
      name: '',
      code: ''
    },
  })
  const [ recordArr, setRecordArr ] = useState({
    action: '',
    rate: '',
    from: {
      name: '',
      code: ''
    },
    to: {
      name: '',
      code: ''
    },
  })
  const [pageInfo, setPageInfo] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [ currencyArr, setCurrencyArr ] = useState([])
  const [ from, setFrom ] = useState('all')
  const [ to, setTo ] = useState('all')

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handlePage = () => {
    setPageOpen(!pageOpen)
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleAdd = () => {
    setDialogOpen({
      edit: false,
      add: true
    })
    setDialogTitle('設置新匯率')
  }

  const handleEdit = (event) => {
    setEditId(event.target.attributes.attr_id.nodeValue)
    setDialogOpen({
      edit: true,
      add: false
    })
    setDialogTitle('變更匯率')
  }

  const handleSearch = () => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userInfo.token},
    };

    fetch('https://api.futurekey.org/api/exchange_rates/records?sorting=created_at&direction=desc&per_page=' + rowsPerPage + '&page=' + (page+1) + '&from_id='+from+'&to_id='+to, requestOptions)
    .then(response => response.json())
    .then(data => {
      if(data.message==='OK'){
        setRecordArr(data.data.records)
        setPageInfo(data.data.meta.total)
      }else{
        console.log(data.message)
      }
    })
  }

  const handleReset = () => {
    setFrom('all')
    setTo('all')
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userInfo.token},
    };

    fetch('https://api.futurekey.org/api/exchange_rates/records?sorting=created_at&direction=desc&per_page=' + rowsPerPage + '&page=' + (page+1), requestOptions)
    .then(response => response.json())
    .then(data => {
      if(data.message==='OK'){
        setRecordArr(data.data.records)
        setPageInfo(data.data.meta.total)
      }else{
        console.log(data.message)
      }
    })
  }

  const handleDelete = (event) => {
    if(open){
      setOpen(false);
      const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userInfo.token},
      };

      fetch('https://api.futurekey.org/api/exchange_rates/' + editId, requestOptions)
      .then(response => response.json())
      .then(data => {
        if(data.message==='OK'){
          setAlertOpen(true)
        }else{
          console.log(data.message)
        }
      })

    }else{
      setEditId(event.target.attributes.attr_id.nodeValue)
      setOpen(true);
    }
  }

  useEffect (() => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userInfo.token},
    };
    
    fetch('https://api.futurekey.org/api/exchange_rates', requestOptions)
    .then(response => response.json())
    .then(data => {
      if(data.message==='OK'){
        setExchangeArr(data.data)
      }else{
        console.log(data.message)
      }
    })

    fetch('https://api.futurekey.org/api/exchange_rates/records?sorting=created_at&direction=desc&per_page=' + rowsPerPage + '&page=' + (page+1), requestOptions)
    .then(response => response.json())
    .then(data => {
      if(data.message==='OK'){
        setRecordArr(data.data.records)
        setPageInfo(data.data.meta.total)
      }else{
        console.log(data.message)
      }
    })

    fetch('https://api.futurekey.org/api/currencies', requestOptions)
    .then(response => response.json())
    .then(data => {
      if(data.message==='OK'){
        setCurrencyArr(data.data)
      }else{
        console.log(data.message)
      }
    })

    setTimeout(() => {
      setAlertOpen(false)
    }, 3000);
  },[alertOpen,userInfo,page,rowsPerPage])

  return (
    <ThemeProvider theme={theme}>
      <MenuContext.Provider value={{menuOpen, setMenuOpen}}>
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />
          <HeaderAndMenu mainTitle="富塏多媒體系統"/>
          <Box component="main" sx={{ flexGrow: 1, height: '100vh', overflow: 'auto' }}>
            <Toolbar className='fixToolbar' />
            <Snackbar open={alertOpen} TransitionComponent={TransitionDown} anchorOrigin={{vertical: 'top', horizontal:'right' }}>
              <Alert severity="success" open={false}>變更成功</Alert>
            </Snackbar>
            <Container className='mainContainer' maxWidth="lg" sx={{ mt: 4, mb: 4 }} >
              <Card sx={{ minWidth: 275 }}>
                <CardContent>
                {
                  !pageOpen &&
                    <>
                      <Button variant="contained" sx={{mr: 2}} startIcon={<AddIcon />} onClick={handleAdd}>
                        新增
                      </Button>
                      <Button variant="contained" startIcon={<HistoryIcon />} onClick={handlePage}>
                        查看匯率設置紀錄
                      </Button>
                    </>
                }
                {
                  pageOpen && 
                    <>
                      <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" onClick={handlePage}>匯率設置</Link>
                        <Typography color="text.primary">匯率設置紀錄</Typography>
                      </Breadcrumbs>
                      <Grid container sx={{alignItems: 'center'}}>
                        <Grid item>
                          <FormControl sx={{ minWidth: 180, mr: 2 }} size="small">
                            <Select
                              value={from}
                              onChange={(e)=>{setFrom(e.target.value)}}
                            >
                              <MenuItem value='all'>全部</MenuItem>
                              {currencyArr.map((c)=>(
                                <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item>
                          <ForwardIcon />
                        </Grid>
                        <Grid item>
                          <FormControl sx={{ minWidth: 180, mx: 2 }} size="small">
                            <Select
                              value={to}
                              onChange={(e)=>{setTo(e.target.value)}}
                            >
                              <MenuItem value='all'>全部</MenuItem>
                              {currencyArr.map((c)=>(
                                <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item>
                          <Button onClick={handleSearch} disabled={from==='all'||to==='all'} type="submit" variant="contained">查詢</Button>
                        </Grid>
                        <Grid item>
                          <Button sx={{ml: 2}} onClick={handleReset} disabled={from==='all'&& to==='all'} type="submit" variant="contained">清除</Button>
                        </Grid>
                      </Grid>
                    </>
                }
                </CardContent>
              </Card>
              <Card sx={{ minWidth: 275, mt: "16px" }}>
                <CardContent>
                  {
                    !pageOpen &&
                    <>
                      <Typography variant='h6' component="h4" mb={2}>目前匯率</Typography>
                      <TableContainer >
                        <Table aria-label="table" size="small">
                          <TableBody>
                            {
                              exchangeArr && exchangeArr.length >0 &&
                              // eslint-disable-next-line array-callback-return
                              exchangeArr.map((rate, index) => {
                                if(rate.from.code !== 'TWD' && (rate.from.code!==rate.to.code) ){
                                  return(
                                  <StyledTableRow key={rate.id}>
                                    <StyledTableCell style={{ width: '15%' }} component="td">
                                      { rate.from.name } { rate.from.code }
                                    </StyledTableCell>
                                    <StyledTableCell style={{ width: '5%' }} component="td">
                                      <ForwardIcon />
                                    </StyledTableCell>
                                    <StyledTableCell style={{ width: '15%' }} component="td">
                                      { rate.to.name } { rate.to.code }
                                    </StyledTableCell>
                                    <StyledTableCell style={{ width: '10%' }} component="td">
                                      { parseFloat(rate.rate).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 4}) }
                                    </StyledTableCell>
                                    <StyledTableCell style={{ width: '55%' }} align='right' component="td">
                                      <Button onClick={handleEdit} attr_id={rate.id} variant="outlined" sx={{ mr: 2 }} startIcon={<EditIcon />}>
                                        變更
                                      </Button>
                                      <Button onClick={handleDelete} attr_id={rate.id} variant="outlined" color='error' startIcon={<DeleteForeverIcon />}>
                                        移除
                                      </Button>
                                    </StyledTableCell>
                                  </StyledTableRow>
                                )}
                              })
                            }
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </>
                  }
                  {
                    pageOpen && 
                    <>
                      <TableContainer>
                        <Table size="small" aria-label="a dense table">
                          <TableBody>
                            {
                              recordArr.length > 0 &&
                              // eslint-disable-next-line array-callback-return
                              recordArr.map((row, index) => {
                                if(row.from.code!=='TWD' && (row.from.code!==row.to.code)){
                                  return(
                                  <StyledTableRow hover key={index}>
                                    <StyledTableCell >
                                      <Moment format="YYYY/MM/DD">{row.created_at}</Moment>
                                    </StyledTableCell>
                                    <StyledTableCell >
                                      {row.action.includes('建立')
                                        ? <Chip label="新增" color="success" variant="outlined" sx={{mr:1}}/>
                                        : (row.action.includes('刪除')
                                          ? <Chip label="刪除" color="error" variant="outlined" sx={{mr:1}}/>
                                          : <Chip label="變更" color="primary" variant="outlined" sx={{mr:1}}/>)}
                                      {row.from.name}{row.from.code}
                                      <ForwardIcon sx={{verticalAlign: 'middle', mx:1}} />
                                      {row.to.name}{row.to.code}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      {row.rate}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      {row.user}
                                    </StyledTableCell>
                                  </StyledTableRow>
                                )}
                              })
                            }
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        rowsPerPageOptions={[15, 30]}
                        component="div"
                        count={pageInfo}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </>
                  }
                </CardContent>
              </Card>
            </Container>
            <OpenContext.Provider value={{dialogOpen, setDialogOpen}}>
              <AlertContext.Provider value={{alertOpen, setAlertOpen}}>
                <EditRateDialog title={dialogTitle} editId={editId}/>
                <AddRateDialog title={dialogTitle}/>
              </AlertContext.Provider>
            </OpenContext.Provider>
            <Dialog
              open={open}
              TransitionComponent={Transition}
              keepMounted
              onClose={handleClose}
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  確定要移除此匯率設定嗎？
                </DialogContentText>
              </DialogContent>
              <DialogActions sx={{justifyContent: "space-between"}}>
                <Button onClick={handleClose}>取消</Button>
                <Button onClick={handleDelete}>確定</Button>
              </DialogActions>
            </Dialog>
          </Box>
        </Box>
      </MenuContext.Provider>
    </ThemeProvider>
  )
}
