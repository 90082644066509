import React, { useState, useEffect, useContext } from 'react'
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import { OpenContext } from '../context/OpenContext'
import { AlertContext } from '../context/AlertContext'
import { UserContext } from '../context/UserContext'
import useDidMountEffect from "../hooks/useDidMountEffect";

const tempMonthArr = [1,2,3,4,5,6,7,8,9,10,11,12] 
const tempYearArr = []
function setSelectYearOptions() {
  let nowDate = new Date()
  for(var y=nowDate.getFullYear() ; y>=2022 ; y--){
    tempYearArr.push(y)
  }
}

export default function AddNoticeDialog({title}) {
  const { dialogOpen, setDialogOpen } = useContext(OpenContext)
  const { setAlertOpen } = useContext(AlertContext)
  const { userInfo } = useContext(UserContext)
  let nowDate = new Date()
  const [ dateValue, setDateValue ] = useState(nowDate);
  const [ formData, setFormData ] = useState({})
  const [ type, setType ] = useState('')
  const [ loading, setLoading ] = useState(false)
  const [ formValid, setFormValid ] = useState(true)
  const [ errorMessage, setErrorMessage ] = useState('')
  const [ userArr, setUserArr ] = useState([])
  const [ user, setUser ] = useState('')
  const [ userAccount, setUserAccount ] = useState({
    bank_accounts: [{account_name:'',account:''}],
    currency: {code:''}
  })
  const [ file, setFile ] = useState('');
  const [ year, setYear ] = useState('')
  const [ month, setMonth ] = useState('')
  const [ income, setIncome ] = useState('')
  const [ vat, setVat ] = useState('')
  const [ total, setTotal ] = useState('')

  //取消編輯時
  const handleClose = () => {
    setDialogOpen(false)
    setFormValid(true)
    setType('')
    setUser('')
    setYear('')
    setMonth('')
    let nowDate = new Date()
    setDateValue(nowDate);
    setIncome('')
    setUserAccount({
      bank_accounts: [{account_name:'',account:''}],
      currency: {code:'',id:''}
    })
    setFile('')
    setVat('')
    setTotal('')
    setErrorMessage('')
  };

  const handleIncomeChange = (event) => {
    setIncome(event.target.value);
    if(event.target.value===''){
      setVat('')
      setTotal('')
    }else{
      if(userAccount.currency.code==='TWD'){
        const income = parseInt(event.target.value.replace(/,/g, ""))
        setVat((income*0.05).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 0}))
        setTotal((income*1.05).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 0}))
      }else{
        const income = parseFloat(event.target.value.replace(/,/g, ""))
        setVat((income*0).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2}))
        setTotal((income).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2}))
      }

    }
  }

  const handleTotalChange = (event) => {
    setTotal(event.target.value);
    if(event.target.value===''){
      setVat('')
      setIncome('')
    }else{
      if(userAccount.currency.code==='TWD'){
        const total = Math.round(event.target.value.replace(/,/g, ""))
        setVat((total-total/1.05).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 0}))
        setIncome((total/1.05).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 0}))
      }else{
        const total = parseFloat(event.target.value.replace(/,/g, ""))
        setVat((total*0).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2}))
        setIncome((total).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2}))
      }

    }
  }

  const handleTypeChange = (event) => {
    setType(event.target.value);
  }

  useEffect(() => {
    
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userInfo.token }
    }
    
    if(user!=='' && year!=='' && month!=='' && type==='receivable'){
      fetch('https://api.futurekey.org/api/billings?year=' + year + '&month=' + month + '&keyword=' + userAccount.username, requestOptions)
      .then(response => response.json())
      .then(data => {
        if(data.message==='OK')  {
          if(data.data.bills.length>0){
            if(userAccount.currency.code==='TWD'){
              var income = Math.round(data.data.bills[0].income)
              setIncome(income.toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 0}))
              setVat((income*0.05).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 0}))
              setTotal((income*1.05).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 0}))
            }else{
              setIncome((parseFloat(data.data.bills[0].income)).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2}))
              setVat(0)
              setTotal((parseFloat(data.data.bills[0].income)).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2}))
            }
          }else{
            alert('尚無當月帳款資訊')
            setVat('')
            setIncome('')
            setTotal('')
          }
        }else{
          console.log(data)
        }
      })
    }else if((user!=='' && year!=='' && month!=='' && type!=='receivable')){
      fetch('https://api.futurekey.org/api/billing_notices?year=' + year + '&month=' + month + '&keyword=' + userAccount.username, requestOptions)
      .then(response => response.json())
      .then(data => {
        if(data.message==='OK')  {
          if(data.data.billing_notices.length>0){
            if(userAccount.currency.code==='TWD'){
              var income = Math.round(data.data.billing_notices[0].income)
              setIncome(income.toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 0}))
              setVat((income*0.05).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 0}))
              setTotal((income*1.05).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 0}))
            }else{
              setIncome(parseFloat(data.data.billing_notices[0].income).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2}))
              setVat(0)
              setTotal(parseFloat(data.data.billing_notices[0].income).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2}))
            }
          }else{
            alert('尚無當月請款通知紀錄')
            setVat('')
            setIncome('')
            setTotal('')
          }
        }else{
          console.log(data)
        }
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[userAccount, type, year, month])

  const handleUserChange = (event) => {
    setUser(event.target.value);

    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userInfo.token }
    }

    fetch('https://api.futurekey.org/api/users/' + event.target.value, requestOptions)
    .then(response => response.json())
    .then(data => {
      if(data.message==='OK')  {
        setUserAccount(data.data)
      }else{
        console.log(data)
      }
    })
  };

  const handleYearChange = (event) => {
    setYear(event.target.value);
  };

  const handleMonthChange = (event) => {
    setMonth(event.target.value);
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0].name)
  }

  const handleRemoveFile = () => {
    setFile('')
  }

  const formatDate = (v) => {
    let date = new Date(v)
    let y = date.getFullYear()
    let m = date.getMonth()+1
    let d = date.getDate()
    return y+"-"+m+"-"+d
  }

  //儲存時
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    setFormValid(true) 
    setLoading(true)

    if(type==='' || user==='' || year==='' || month==='' || income===''){
      setFormValid(false)
      setErrorMessage('請確認所有*欄位已填寫正確')
      setLoading(false)
      return
    }

    var nowDate = new Date() 

    if(file===''){
      if(type==='payable'){
        setFormData({
          _method: 'POST',
          type: type==='payable'?'匯款':'請款',
          user_id: user,
          notice_date: formatDate(nowDate),
          year: year,
          month: month,
          paid_date: formatDate(dateValue),
          currency_id: userAccount.currency.id,
          income: (parseFloat(income.replace(/,/g, ""))).toString(),
          note: data.get('note')
        })
      }else{
        setFormData({
          _method: 'POST',
          type: type==='payable'?'匯款':'請款',
          user_id: user,
          notice_date: formatDate(nowDate),
          year: year,
          month: month,
          currency_id: userAccount.currency.id,
          income: (parseFloat(income.replace(/,/g, ""))).toString(),
          note: data.get('note')
        })
      }
    }else{
      if(type==='payable'){
        setFormData({
          _method: 'POST',
          type: type==='payable'?'匯款':'請款',
          user_id: user,
          notice_date: formatDate(nowDate),
          year: year,
          month: month,
          paid_date: formatDate(dateValue),
          file: data.get('file'),
          currency_id: userAccount.currency.id,
          income: (parseFloat(income.replace(/,/g, ""))).toString(),
          note: data.get('note')
        })
      }else{
        setFormData({
          _method: 'POST',
          type: type==='payable'?'匯款':'請款',
          user_id: user,
          notice_date: formatDate(nowDate),
          year: year,
          month: month,
          file: data.get('file'),
          currency_id: userAccount.currency.id,
          income: (parseFloat(income.replace(/,/g, ""))).toString(),
          note: data.get('note')
        })
      }
    }

  }

  useEffect(()=> {
    setSelectYearOptions()
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userInfo.token }
    };

    fetch('https://api.futurekey.org/api/users?page=1&per_page=500&role=User&status=true', requestOptions)
    .then(response => response.json())
    .then(data => {
      if(data.message==='OK')  {
        let newUserArr = data.data.users.map((u) => {
          return {
            label: u.username,
            value: u.id,
          }
        })
        setUserArr(newUserArr)
      }else{
        console.log(data)
      }
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useDidMountEffect(() => {
    if(formValid && formData.type){
      if(formData.file){
        var uploadData = new FormData()
        // eslint-disable-next-line array-callback-return
        Object.keys(formData).map((key) => {
          console.log(key,formData[key])
          uploadData.append(key,formData[key])
        })

        console.log(uploadData)

        var requestOptions = {
          method: 'POST',
          headers: {'Authorization': 'Bearer ' + userInfo.token},
          body: uploadData
        };
      }else{
        // eslint-disable-next-line no-redeclare
        var requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userInfo.token},
          body: JSON.stringify(formData)
        };
      }

      fetch('https://api.futurekey.org/api/billing_notices', requestOptions)
      .then(response => response.json())
      .then(data => {
        if(data.message==='OK'){
          setLoading(false)
          setAlertOpen({
            open: true,
            message: '新增成功'
          })
          handleClose()
        }else{
          if(data.message==='重複建立'){
            alert('該月份已新增過通知')
          }
          setLoading(false)
          setFormData({})
          console.log(data.message)
        }
      })
    }
  }, [formData])

  return (
    <Dialog open={dialogOpen} fullWidth onClose={handleClose}>
      <DialogTitle>
        {title}
        <IconButton aria-label="close" onClick={handleClose} sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500], }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {
        loading 
        ? 
        <CircularProgress sx={{ margin: '10px auto 20px' }} />
        :
        <Box component="form" noValidate onSubmit={handleSubmit} >
          <DialogContent>
            <FormControl error={!formValid} required variant='standard' margin="dense" fullWidth>
              <InputLabel id="type-select-helper-label">通知類型</InputLabel>
              <Select
                size="small"
                labelId="type-select-label"
                id="type_select"
                name="type_select"
                label="通知類型"
                value={type}
                onChange={handleTypeChange}
              >
                <MenuItem value='receivable'>請款通知</MenuItem>
                <MenuItem value='payable'>匯款通知</MenuItem>
              </Select>
            </FormControl>
            <Autocomplete
              disableClearable
              id="user"
              options={userArr}
              fullWidth
              onChange={handleUserChange}
              noOptionsText="無相關網站"
              size="small"
              required
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderOption={(props, option) => {
                return (
                  <li {...props} value={option.value}>
                    {option.label}
                  </li>
                );
              }}
              renderInput={(params) => <TextField size="small" required margin="dense" variant="standard" error={!formValid} name="suer" {...params} label="網站主" />}
            />
            <Typography variant="subtitle1" component="h6" mb={0}>匯款資訊:{userAccount.bank_accounts.length===0?"無資料":(userAccount.bank_accounts[0].account_name+userAccount.bank_accounts[0].account)}</Typography>
            <FormControl error={!formValid} required variant='standard' margin="dense" fullWidth>
              <InputLabel id="year-select-helper-label">年份</InputLabel>
              <Select
                size="small"
                labelId="year-select-label"
                id="year_select"
                name="year_select"
                label="年份"
                value={year}
                onChange={handleYearChange}
              >
                {
                  tempYearArr.map((y)=>(
                    <MenuItem value={y} key={y}>{y}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
            <FormControl error={!formValid} required variant='standard' margin="dense" fullWidth>
              <InputLabel id="month-select-helper-label">月份</InputLabel>
              <Select
                size="small"
                labelId="month-select-label"
                id="month_select"
                name="month_select"
                label="月份"
                value={month}
                onChange={handleMonthChange}
              >
                {
                  tempMonthArr.map((m)=>(
                    <MenuItem value={m} key={m}>{m}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                disabled={type!=='payable'}
                margin="dense"
                inputFormat="yyyy-MM-dd"
                error={!formValid}
                label="匯款日期"
                id="date"
                value={dateValue}
                onChange={(newValue) => {setDateValue(newValue);}}
                renderInput={(params) => <TextField variant='standard' size="small" error={!formValid} name="date" {...params} fullWidth margin="dense"/>}
              />
            </LocalizationProvider>
            { type==='payable' 
              ?
              <Typography variant="subtitle1" component="h6">結算金額: {userAccount.currency.code} {income}</Typography>
              :
              <TextField required disabled={user===''} size="small" error={!formValid} value={income} onChange={handleIncomeChange} id="income" name="income" label="結算金額" variant="standard" margin="dense" fullWidth/>
            }
            <Typography variant="subtitle1" component="h6">稅額: {userAccount.currency.code} {vat}</Typography>
            {
              type==='receivable'
              ?
              <Typography variant="subtitle1" component="h6">應付金額: {userAccount.currency.code} {total}</Typography>
              :
              <TextField required disabled={user===''} size="small" error={!formValid} value={total} onChange={handleTotalChange} id="total" name="total" label="應付金額" variant="standard" margin="dense" fullWidth/>
            }
          <TextField disabled={user===''} multiline minRows={2} size="small" id="note" name="note" label="備註" variant="outlined" margin="dense" fullWidth/>
          <Grid container sx={{ alignItems: 'center'}}>
            <Grid item xs={5}>
              <Button sx={{ my: 1.5, minWidth: "40%"}} size="large" component="label" variant="outlined" startIcon={<DriveFolderUploadIcon />}>
              上傳檔案
                <input name="file" id="file" hidden accept="*" type="file" onChange={handleFileChange}/>
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1" component="p">{file}</Typography>
            </Grid>
            <Grid item xs={1}>
              {
                file !== '' &&
                <IconButton aria-label="delete" onClick={handleRemoveFile}>
                  <DeleteIcon />
                </IconButton>
              }
            </Grid>
          </Grid>
          <Typography variant="subtitle1" component="p" color="error">{errorMessage}</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>取消</Button>
            {
              loading &&
              <LoadingButton
                loading
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="outlined"
              >
                處理中
              </LoadingButton>
            }
            {
              !loading &&
              <Button variant="contained" type="submit">儲存</Button>
            }
          </DialogActions>
        </Box>
      }
    </Dialog>
  )
}
