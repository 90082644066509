import React, { useState, useContext, useEffect } from 'react'
import { ThemeProvider, styled } from '@mui/material/styles';
import { theme } from './theme'
import ExcelJs from "exceljs";
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell, {tableCellClasses} from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import CircularProgress from '@mui/material/CircularProgress';
import { UserContext } from './context/UserContext'
import { MenuContext } from "./context/MenuContext"
import { AlertContext } from "./context/AlertContext"
import { OpenContext } from "./context/OpenContext"
import HeaderAndMenu from "./components/HeaderAndMenu";
import AddPlatformDialog from './components/AddPlatformDialog'
import EditPlatformDialog from './components/EditPlatformDialog'
import PlatformTypeDialog from './components/PlatformTypeDialog'
import DownloadIcon from '@mui/icons-material/Download';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function TransitionDown(props) {
  return <Slide {...props} direction="down" />;
}

const columns = [
  { id: 'uid', label: '編號', minWidth: 100 },
  { id: 'name', label: '平台名稱', minWidth: 100 },
  { id: 'url', label: '登入後台', minWidth: 100 },
  { id: 'username', label: '帳號', minWidth: 100 },
  { id: 'password', label: '密碼', minWidth: 100 },
  { id: 'status', label: '狀態', minWidth: 80 },
  { id: 'type', label: '類型', minWidth: 80 },
  { id: 'action', label: '操作', minWidth: 220 }
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function Platform() {
  const { userInfo } = useContext(UserContext);
  const [ menuOpen, setMenuOpen ] = useState(true);
  const [ alertOpen, setAlertOpen ] = useState({
    open: false,
    message: ''
  });
  const [ dialogOpen, setDialogOpen] = useState({
    type: false,
    add: false,
    edit: false
  }) 
  const [ dialogTitle, setDialogTitle ] = useState('') 
  const [ platformArr, setPlatformArr ] = useState([]);
  const [ status, setStatus ] = useState(true)
  const [ keyword, setKeyword ] = useState('')
  const [ open, setOpen ] = useState(false)
  const [ editPlatformStatus, setEditPlatformStatus ] = useState()
  const [ editPlatform, setEditPlatform ] = useState({
    name: "",
    full_name: "",
    address: "",
    company_num: "",
    contact_name: "",
    url: "",
    username: "",
    password: "",
    bank_name: "",
    bank_num: "",
    account_name: "",
    account_num: ""
  })
  const [ loading, setLoading ] = useState(false)
  const [ viewId, setViewId ] = useState('');
  const [ page, setPage ] = useState(0);
  const [ pageInfo, setPageInfo ] = useState(0);
  const [ rowsPerPage, setRowsPerPage ] = useState(15);
  const [ downloadPlatform, setDownloadPlatform ] = useState([]);
  const getExcel = new ExcelJs.Workbook();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setLoading(true)
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    setLoading(true)
  };

  const handleSearchChange = (event) => {
    setLoading(true)
    setPage(0)
    setKeyword(event.target.value);
  }

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
    setPage(0)
  };

  const handlePlatformStatus = (event) => {
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userInfo.token },
    };

    fetch('https://api.futurekey.org/api/platforms/' + editPlatformStatus + '/status', requestOptions)
    .then(response => response.json())
    .then(data => {
      if(data.message==='OK'){
        setAlertOpen({
          open: true,
          message: '變更成功'
        })
        setOpen(false)
      }else{
        setOpen(false)
        console.log(data.message)
      }
    })
  };

  const handleClose = () => {
    setOpen(false)
  }

  const handleAddUnit = () => {
    setDialogTitle('新增廣告平台')
    setDialogOpen({
      type: false,
      add: true,
      edit: false,
    })
  }

  useEffect (() => {
    if(!dialogOpen.add && !dialogOpen.edit && !open && !dialogOpen.type){
      setLoading(true)
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userInfo.token},
      };

      if(keyword!==''){
        fetch('https://api.futurekey.org/api/platforms?status=' + status + '&keyword=' + keyword+ '&per_page=' + rowsPerPage + '&page=1', requestOptions)
        .then(response => response.json())
        .then(data => {
          if(data.message==='OK'){
            setLoading(false)
            setPlatformArr(data.data.platforms)
            setPageInfo(data.data.meta.total)
          }else{
            setLoading(false)
            console.log(data.message)
          }
        })
      }else{
        fetch('https://api.futurekey.org/api/platforms?status='+ status + '&per_page=' + rowsPerPage + '&page=' + (page+1), requestOptions)
        .then(response => response.json())
        .then(data => {
          if(data.message==='OK'){
            setPlatformArr(data.data.platforms)
            setPageInfo(data.data.meta.total)
            setLoading(false)
          }else{
            console.log(data.message)
          }
        })
      }

      setTimeout(() => {
        setAlertOpen({
          open: false,
          message: ''
        })
      }, 3000);
    }
  },[dialogOpen, userInfo, status, open, rowsPerPage, page, keyword])

  const handleDownloadCSV = () => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userInfo.token }
    };

    fetch('https://api.futurekey.org/api/platforms?status='+ status + '&per_page=500&page=1', requestOptions)
    .then(response => response.json())
    .then(data => {
      if(data.message==='OK')  {
        setDownloadPlatform(data.data.platforms)
      }else{
        console.log(data)
      }
    })
  }

  useEffect(()=>{
    if(downloadPlatform.length>0){
      var column=[]
      var data=[]
      const sheet = getExcel.addWorksheet('帳號列表', {properties: {defaultColWidth: 15}});

      column = [{name:'編號'}, {name:'平台名稱'}, {name:'登入後台'}, {name:'帳號'}, {name:'密碼'}, {name:'狀態'}]
      // eslint-disable-next-line array-callback-return
      downloadPlatform.map((p)=>{
          data.push([p.uid, p.name, p.url, p.username, p.password, p.status?'啟用':'停用'])
      })

      sheet.addTable({
        name: 'reportCSV',
        ref: 'A1',
        columns: column,
        rows: data
      });

      getExcel.xlsx.writeBuffer().then((content) => {
        const link = document.createElement("a");
        const blobData = new Blob([content], {
          type: "application/vnd.ms-excel;charset=utf-8;"
        });
        link.download = '平台列表.xlsx';
        link.href = URL.createObjectURL(blobData);
        link.click();
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[downloadPlatform])

  return (
    <ThemeProvider theme={theme}>
      <MenuContext.Provider value={{menuOpen, setMenuOpen}}>
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />
          <HeaderAndMenu mainTitle="廣告平台管理"/>
          <Box component="main" sx={{ flexGrow: 1, height: '100vh', overflow: 'auto', }}>
            <Toolbar className='fixToolbar' />
            <Snackbar open={alertOpen.open} TransitionComponent={TransitionDown} anchorOrigin={{vertical: 'top', horizontal:'right' }}>
              <Alert severity="success" open={false}>{alertOpen.message}</Alert>
            </Snackbar>
            <Container className='mainContainer' maxWidth="lg" sx={{ mt: 4, mb: 4 }} >
              <Card sx={{ minWidth: 275 }}>
                <CardContent>
                  <Grid container sx={{alignItems: 'center'}}>
                    <Grid item sx={{alignItems: 'center'}} xs={8}>
                      <Grid container sx={{alignItems: 'center'}}>
                        <Grid item mr={2}>
                          <Button onClick={handleAddUnit} variant="contained" sx={{ minWidth: 130 }} startIcon={<AddIcon />} >新增廣告平台</Button>
                        </Grid>
                        <Grid item mr={2}>
                          <FormControl margin="dense" sx={{ minWidth: 130 }}>
                            <InputLabel id="status-select-helper-label">顯示資料</InputLabel>
                            <Select
                              size="small"
                              labelId="status-select-label"
                              id="status_select"
                              name="status_select"
                              label="顯示資料"
                              value={status}
                              onChange={handleStatusChange}
                            >
                              <MenuItem value={true}>啟用中</MenuItem>
                              <MenuItem value={false}>已停用</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item mr={2}>
                          <Button onClick={handleDownloadCSV}  variant="outlined" color='primary' startIcon={<DownloadIcon />}>下載CSV</Button>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item sx={{alignItems: 'center', textAlign: 'right'}} xs={4}>
                      <TextField
                        margin="dense"
                        sx={{ width: 200 }}
                        id="search_key"
                        placeholder='請輸入關鍵字搜尋'
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                        value={keyword}
                        onChange={handleSearchChange}
                        size="small"
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <Card sx={{ minWidth: 275, mt: 2 }}>
                <TableContainer>
                  <Table size="small" aria-label="table">
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align="center"
                            style={{ minWidth: column.minWidth }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        loading 
                        ? 
                        <StyledTableRow>
                          <StyledTableCell align="center" colSpan={8}>
                            <CircularProgress />
                          </StyledTableCell>
                        </StyledTableRow>
                        :
                        (
                        platformArr.length === 0 ?
                          <StyledTableRow>
                            <StyledTableCell align="center" colSpan={8}>
                              無相關資料
                            </StyledTableCell>
                          </StyledTableRow>
                        :
                        platformArr.length > 0 &&
                        platformArr.map((row, index) => (
                          <StyledTableRow hover role="checkbox" tabIndex={-1} key={index}>
                            <StyledTableCell align="center">{row.uid}</StyledTableCell>
                            <StyledTableCell align="center">{row.name}</StyledTableCell>
                            <StyledTableCell align="center">
                              {
                                row.url!=='' &&
                                <a href={row.url} target="_blank" rel="noreferrer"><OpenInNewIcon/></a>
                              }
                            </StyledTableCell>
                            <StyledTableCell align="center">{row.username}</StyledTableCell>
                            <StyledTableCell align="center">{row.password}</StyledTableCell>
                            <StyledTableCell align="center">
                              {
                                row.status === false ? "停用" : "啟用"
                              }
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <IconButton onClick={()=>{
                                  setViewId(row.id)
                                  setDialogTitle(row.name+'的類型')
                                  setDialogOpen({
                                    type: true,
                                    add: false,
                                    edit: false
                                  }) 
                                }} aria-label="account_detail" size="small">
                                <RemoveRedEyeIcon fontSize="small" />
                              </IconButton>
                            </StyledTableCell>
                            <StyledTableCell align="center">    
                              <Button onClick={()=>{
                                  setEditPlatform(row)
                                  setDialogTitle('編輯廣告平台')
                                  setDialogOpen({
                                    type: false,
                                    add: false,
                                    edit: true
                                  })
                                }}variant="text" sx={{ mr: 2 }} startIcon={<EditIcon />}>
                                編輯
                              </Button>
                              {
                                row.status === false
                                ? <Button onClick={() => {
                                  setEditPlatformStatus(row.id)
                                  setOpen(true)
                                }} variant="text" color='success' startIcon={<CheckCircleIcon />}>啟用</Button>
                                : <Button onClick={() => {
                                  setEditPlatformStatus(row.id)
                                  setOpen(true)
                                }} variant="text" color='error' startIcon={<CancelIcon />}>停用</Button>
                              }
                            </StyledTableCell>
                          </StyledTableRow>
                        ))
                        )
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[15, 30]}
                  component="div"
                  count={pageInfo}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Card>
            </Container>
          </Box>
        </Box>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              確定要
              {
                status === false ? "啟用" : "停用"
              }
              此廣告平台嗎？
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{justifyContent: "space-between"}}>
            <Button onClick={handleClose}>取消</Button>
            <Button onClick={handlePlatformStatus}>確定</Button>
          </DialogActions>
        </Dialog>
        <OpenContext.Provider value={{dialogOpen, setDialogOpen}}>
          <AlertContext.Provider value={{alertOpen, setAlertOpen}}>
            <EditPlatformDialog title={dialogTitle} editData={editPlatform}/>
            <AddPlatformDialog title={dialogTitle}/>
            <PlatformTypeDialog title={dialogTitle} id={viewId}/>
          </AlertContext.Provider>
        </OpenContext.Provider>
      </MenuContext.Provider>
    </ThemeProvider>
  )
}
