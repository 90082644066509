import React from 'react'
import { useState, useContext, useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from './theme'
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Moment from 'react-moment';
import Slide from '@mui/material/Slide';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import { UserContext } from './context/UserContext'
import { OpenContext } from "./context/OpenContext"
import { AlertContext } from './context/AlertContext'
import { MenuContext } from "./context/MenuContext"
import HeaderAndMenu from './components/HeaderAndMenu'
import AddNoticeDialog from './components/AddNoticeDialog'

const tempMonthArr = [1,2,3,4,5,6,7,8,9,10,11,12] 
var tempYearArr = []
function setSelectYearOptions() {
  tempYearArr=[]
  let nowDate = new Date()
  for(var y=nowDate.getFullYear() ; y>=2022 ; y--){
    tempYearArr.push(y)
  }
}

const columns = [
  { id: 'type', label: '項目', minWidth: 100 },
  { id: 'date', label: '通知日期', minWidth: 100 },
  { id: 'year_month', label: '款項期別', minWidth: 100 },
  { id: 'user', label: '使用者帳號', minWidth: 100 },
  { id: 'email', label: 'Email', minWidth: 150 },
  //{ id: 'action', label: '操作', minWidth: 220 }
];
function TransitionDown(props) {
  return <Slide {...props} direction="down" />;
}

export default function BillNotice() {
  const { userInfo } = useContext(UserContext)
  const [ menuOpen, setMenuOpen ] = useState(true);
  const [ alertOpen, setAlertOpen ] = useState({
    open: false,
    message: ''
  });
  const [ type, setType ] = useState('')
  const [ year, setYear ] = useState('all')
  const [ month, setMonth ] = useState('all')
  const [ keyword, setKeyword ] = useState('')
  const [ billsArr, setBillsArr] = useState([])
  const [ loading, setLoading ] = useState(0)
  const [ page, setPage ] = useState(0);
  const [ pageInfo, setPageInfo ] = useState(0);
  const [ rowsPerPage, setRowsPerPage ] = useState(15);
  const [ dialogTitle, setDialogTitle ] = useState('');
  const [ dialogOpen, setDialogOpen ] = useState(false)

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleAddNotice = () => {
    setDialogOpen(true)
    setDialogTitle('新增通知')
  }

  const handleSetDefault = () => {
    setType('')
    setYear('all')
    setMonth('all')
    setKeyword('')
  }

  const handleSearchChange = (event) => {
    setLoading(true)
    setKeyword(event.target.value);
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userInfo.token},
    };

    const filter = '?per_page=' + rowsPerPage+ '&page='+ page + (event.target.value===''?'':('&keyword='+event.target.value)) + (type===''?'':('&type='+(type==='payable'?'匯款':'請款')))+(year==='all'?'':('&year='+year))+(month==='all'?'':('&month='+month))
    console.log(filter)
      fetch('https://api.futurekey.org/api/billing_notices'+ filter, requestOptions)
      .then(response => response.json())
      .then(data => {
        if(data.message==='OK'){
          setBillsArr(data.data.billing_notices)
          setPageInfo(data.data.meta.total)
          setLoading(false)
        }else{
          console.log(data.message)
          setLoading(false)
        }
      })
  
  }

  const handleYearChange = (event) => {
    setYear(event.target.value);
  };

  const handleMonthChange = (event) => {
    setMonth(event.target.value);
  };

  useEffect (() => {
    if(!dialogOpen.edit && !dialogOpen.view){
      setLoading(true)
      setSelectYearOptions()

      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userInfo.token},
      };

      var filter = '?per_page=' + rowsPerPage+ '&page='+ page +
        (type===''?'':('&type='+(type==='receivable'?'請款':"匯款")))+
        (year==='all'?'':('&year='+year))+
        (month==='all'?'':('&month='+month))

      fetch('https://api.futurekey.org/api/billing_notices'+filter, requestOptions)
      .then(response => response.json())
      .then(data => {
        if(data.message==='OK'){
          setBillsArr(data.data.billing_notices)
          setPageInfo(data.data.meta.total)
          setLoading(false)
        }else{
          console.log(data.message)
        }
      })

      setTimeout(() => {
        setAlertOpen({
          open: false,
          message: ''
        })
      }, 3000);
    }
  },[userInfo, type, dialogOpen, year, month, page, rowsPerPage])

  return (
    <ThemeProvider theme={theme}>
      <MenuContext.Provider value={{menuOpen, setMenuOpen}}>
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />
          <HeaderAndMenu mainTitle="帳款通知"/>
          <Box component="main" sx={{ flexGrow: 1, height: '100vh', overflow: 'auto' }}>
            <Toolbar className='fixToolbar' />
            <Snackbar open={alertOpen.open} TransitionComponent={TransitionDown} anchorOrigin={{vertical: 'top', horizontal:'right' }}>
              <Alert severity="success" open={false}>{alertOpen.message}</Alert>
            </Snackbar>
            <Container className='mainContainer' maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
              <Card sx={{ minWidth: 275 }}>
                <CardContent>
                  <Grid container sx={{alignItems: 'center'}}>
                    <Grid item mr={2}>
                    <Button onClick={handleAddNotice} variant="contained" sx={{ minWidth: 130 }} startIcon={<AddIcon />} >新增通知</Button>
                    </Grid>
                  </Grid>
                  <Grid container sx={{alignItems: 'center'}}>
                    <Grid item xs={8}>
                      <Grid container sx={{alignItems: 'center'}}>
                        <Grid item mr={2}>
                          <Button onClick={() => setType('receivable')} sx={{ minWidth: 80 }} variant={ type==='receivable' ? 'contained' : 'outlined' }>請款</Button>
                        </Grid>
                        <Grid item mr={2}>
                          <Button onClick={() => setType('payable')} sx={{ minWidth: 80 }} variant={ type==='payable' ? 'contained' : 'outlined' }>匯款</Button>
                        </Grid>
                        <Grid item mr={2}>
                          <FormControl sx={{ minWidth: 100 }}>
                            <InputLabel id="year-select-helper-label">年份</InputLabel>
                            <Select
                              size="small"
                              labelId="year-select-label"
                              id="year_select"
                              name="year_select"
                              label="年份"
                              value={year}
                              onChange={handleYearChange}
                            >
                              <MenuItem value='all'>全部</MenuItem>
                              {
                                tempYearArr.map((y)=>(
                                  <MenuItem value={y} key={y}>{y}</MenuItem>
                                ))
                              }
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item mr={2}>
                          <FormControl sx={{ minWidth: 100 }}>
                            <InputLabel id="month-select-helper-label">月份</InputLabel>
                            <Select
                              size="small"
                              labelId="month-select-label"
                              id="month_select"
                              name="month_select"
                              label="月份"
                              value={month}
                              onChange={handleMonthChange}
                            >
                              <MenuItem value='all'>全部</MenuItem>
                              {
                                tempMonthArr.map((m)=>(
                                  <MenuItem value={m} key={m}>{m}</MenuItem>
                                ))
                              }
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item mr={2}>
                          <Button onClick={handleSetDefault} sx={{ minWidth: 100 }} variant='outlined'>顯示全部</Button>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item sx={{alignItems: 'center', textAlign: 'right'}} xs={4}>
                      <TextField
                        sx={{ width: 200 }}
                        id="search_key"
                        placeholder='請輸入關鍵字搜尋'
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                        value={keyword}
                        onChange={handleSearchChange}
                        size="small"
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <Card sx={{ minWidth: 275, mt: 2 }}>
              <TableContainer>
                  <Table aria-label="table" size="small">
                    <TableHead>
                      <TableRow>
                        {
                          columns.map((column) => (
                            <TableCell
                              key={column.id}
                              align="center"
                              style={{ minWidth: column.minWidth }}
                            >
                              {column.label}
                            </TableCell>
                          ))
                        }

                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        loading 
                        ? 
                        <TableRow>
                          <TableCell align="center" colSpan={8}>
                            <CircularProgress />
                          </TableCell>
                        </TableRow>
                        :
                        (
                          billsArr.length === 0 ?
                          <TableRow>
                            <TableCell align="center" colSpan={8}>
                              無相關資料
                            </TableCell>
                          </TableRow>
                        :
                        billsArr.length > 0 &&
                        billsArr.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => (
                          <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                            <TableCell align="center">{row.type}</TableCell>
                            <TableCell align="center"><Moment format="YYYY-MM-DD">{row.notice_date}</Moment></TableCell>
                            <TableCell align="center">{row.year}-{row.month}</TableCell>
                            <TableCell align="center">{row.user.username}</TableCell>
                            <TableCell align="center">{row.user.email}</TableCell>
                          </TableRow>
                        ))
                        )
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[15, 30]}
                  component="div"
                  count={pageInfo}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Card>
            </Container>
            <OpenContext.Provider value={{dialogOpen, setDialogOpen}}>
              <AlertContext.Provider value={{alertOpen, setAlertOpen}}>
                <AddNoticeDialog title={dialogTitle} />
                {/* <ViewAccountingDetailDialog title={dialogTitle} id={viewBill}/> */}
              </AlertContext.Provider>
            </OpenContext.Provider>
          </Box>
        </Box>
      </MenuContext.Provider>
    </ThemeProvider>
  )
}

