import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LanguageIcon from '@mui/icons-material/Language';
import Link from '@mui/material/Link';
import {useNavigate} from 'react-router-dom';
import { UserContext } from "../context/UserContext"

export default function AccountMenu() {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const { userInfo, setUserInfo } = useContext(UserContext);
  const open = Boolean(anchorEl);

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userInfo.token},
    };

    fetch('https://api.futurekey.org/api/auth/logout', requestOptions)
    .then(response => response.json())
    .then(data => {
      if(data.message==='OK'){
        sessionStorage.clear()
        setUserInfo({})
        navigate('/login')
      }else{
        console.log(data.message)
      }
    })
  }
  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center', ml: [1] }}>
        <IconButton
          sx={{ color: '#fff' }}
          onClick={handleClick}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <AccountCircleIcon />
        </IconButton>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem>
          <Link href="/profile" underline="none" color="inherit">
            <ListItemIcon>
              <Settings fontSize="small" />
            </ListItemIcon>
            {t("account_setting")}
          </Link>
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          {t("logout")}
        </MenuItem>
        {i18n.language === 'zh' || i18n.language === 'zh-TW'
          ? <MenuItem onClick={() => changeLanguage('en')}><ListItemIcon><LanguageIcon fontSize="small" /></ListItemIcon>English</MenuItem>
          : <MenuItem onClick={() => changeLanguage('zh')}><ListItemIcon><LanguageIcon fontSize="small" /></ListItemIcon>中文</MenuItem>
        }
      </Menu>
    </React.Fragment>
  );
}
