import React, { useEffect, useState, useContext } from 'react'
import { ThemeProvider } from '@mui/material/styles';
import { theme } from './theme'
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';
import { Dialog, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import {Stack} from '@mui/material';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Autocomplete from '@mui/material/Autocomplete';
import DeleteIcon from '@mui/icons-material/Delete';
import { UserContext } from "./context/UserContext"
import { MenuContext } from "./context/MenuContext"
import useDidMountEffect from './hooks/useDidMountEffect'
import HeaderAndMenu from './components/HeaderAndMenu'
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';

function TransitionDown(props) {
  return <Slide {...props} direction="down" />;
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const columns = [
  { id: 'date', label: '日期', minWidth: 80, align: "center" },
  { id: 'impressions', label: '曝光', align: "right", minWidth: 90, format: (value) => parseFloat(value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})},
  { id: 'clicks', label: '點擊', align: "right", minWidth: 80, format: (value) => parseFloat(value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2}) },
  { id: 'ctr', label: 'CTR(%)', align: "right", minWidth: 80, format: (value)=>parseFloat(value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})+'%'},
  { id: 'cpm', label: 'CPM', align: "right", minWidth: 60, format: (value) => parseFloat(value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})},
  { id: 'true_revenue', label: '實際收益', align: "right", minWidth: 90, format: (value) => parseFloat(value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})},
  { id: 'final_revenue', label: '預估收益', align: "right", minWidth: 90, format: (value) => parseFloat(value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})},
  { id: 'action', label: '', minWidth: 50, align: "center" },
];

export default function AddReport() {
  const { userInfo } = useContext(UserContext);
  const [ menuOpen, setMenuOpen ] = useState(true);
  const [ alertOpen, setAlertOpen ] = useState({
    status: false,
    msg: ''
  });
  let nowDate = new Date()
  const [ dateValue, setDateValue ] = useState(nowDate);
  const [ siteArr, setSiteArr ] = useState([]);
  const [ site, setSite ] = useState('');
  const [ unitArr, setUnitArr ] = useState([]);
  const [ unit, setUnit ] = useState('');
  const [ unitValue, setUnitValue ] = useState(null);
  const [ inputUnit, setInputUnit ] = useState('');
  const [ impressions, setImpressions ] = useState('');
  const [ clicks, setClicks ] = useState('');
  const [ true_revenue, setTrue_revenue ] = useState('');
  const [ unitData, setUnitData ] = useState({
    id: 0,
    user: '',
    platform: '',
    platform_type: '',
    deduction: '',
    currency: ''
  });
  const [ formData, setFormData] = useState({
    "website_id": '',
    "adunit_id": '',
    "impressions": '',
    "clicks": '',
    "ctr": '',
    "cpm": '',
    "true_revenue": '',
    "date": ""
  })
  const [formValid, setFormValid] = useState(true)
  const [errorMessage, setErrorMessage] = useState('')
  const [reportArr, setReportArr] = useState([])
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [open, setOpen] = useState(false)
  const [ deleteReport, setDeleteReport ] = useState('')
  const [ loading, setLoading ] = useState(false)

  const handleSiteChange = (event) => {
    setSite(event.target.value);
    setUnitValue(null)
    setUnit('')
    setUnitData({
      id: 0,
      user: '',
      platform: '',
      platform_type: '',
      deduction: '',
      currency: ''
    })
  };

  const handleImpressionChange = (event) => {
    setImpressions(event.target.value);
  };

  const handleClickChange = (event) => {
    setClicks(event.target.value);
  };

  const handleTrueRevenueChange = (event) => {
    setTrue_revenue(event.target.value);
  };
  const handleUnitChange = (event) => {
    setUnit(event.target.value);
  };

  const handleClose = () => {
    setOpen(false)
  }

  const handleDelete = () => {
    const requestOptions = {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userInfo.token},
    };
    fetch('https://api.futurekey.org/api/reports/' + deleteReport , requestOptions)
    .then(response => response.json())
    .then(data => {
      if(data.message==='OK'){
        setAlertOpen({
          status: true,
          msg: '已刪除'
        })
        resetData()
        setTimeout(() => {
          setAlertOpen({
            status: false,
            msg: ''
          })
        }, 1000);
      }else{
        console.log(data.message)
      }
    })
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    setFormValid(true)

    if(unit===undefined || site===undefined){
      setFormValid(false)
      setErrorMessage('請確認所有欄位皆已填寫')
      return
    }

    if(data.get('impression')==='' || data.get('clicks')==='' || data.get('true_revenue')===''){
      setFormValid(false)
      setErrorMessage('請確認所有欄位皆已填寫')
      return
    }

    var impressions = data.get('impression').replace(/,/g, "")
    var true_revenue = data.get('true_revenue').replace(/,/g, "")
    var clicks = data.get('clicks').replace(/,/g, "")

    setFormData({
      "website_id": site,
      "adunit_id": unit,
      "impressions": impressions,
      "clicks": clicks,
      "true_revenue": true_revenue,
      "date": data.get('date')
    })
  }

  const resetData = () => {
    setFormData({
      "website_id": site,
      "adunit_id": unit,
      "impressions": '',
      "clicks": '',
      "ctr": '',
      "cpm": '',
      "true_revenue": '',
      "date": ""
    })
    setImpressions('')
    setClicks('')
    setTrue_revenue('')
  }

  const handleChangeDate = (newValue) => {
    setDateValue(newValue)
    var temp_date = new Date(newValue);
    var first_date = new Date(temp_date.getFullYear(), temp_date.getMonth(), 1);
    var last_date = new Date(temp_date.getFullYear(), temp_date.getMonth() + 1, 0);
    setStartDate(first_date)
    setEndDate(last_date)
  }

  useEffect(()=>{
    var temp_date = new Date();
    var first_date = new Date(temp_date.getFullYear(), temp_date.getMonth(), 1);
    var last_date = new Date(temp_date.getFullYear(), temp_date.getMonth() + 1, 0);
    setStartDate(first_date)
    setEndDate(last_date)
  },[])

  const formatDate = (v) => {
    let date = new Date(v)
    let y = date.getFullYear()
    let m = date.getMonth()+1
    let d = date.getDate()
    return y+"-"+m+"-"+d
  }

  useEffect(() => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userInfo.token},
    };
    fetch('https://api.futurekey.org/api/websites?status=true&page=1&per_page=500', requestOptions)
    .then(response => response.json())
    .then(data => {
      if(data.message==='OK'){
        let newSiteArr = data.data.websites.map((w) => {
          return {
            label: w.name,
            value: w.id,
          }
          
        })
        setSiteArr(newSiteArr)
      }else{
        console.log(data.message)
      }
    })
  },[userInfo])

  useDidMountEffect(() => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userInfo.token},
    };
    fetch('https://api.futurekey.org/api/ad_positions?page=1&per_page=500&website_id=' + site, requestOptions)
    .then(response => response.json())
    .then(data => {
      if(data.message==='OK'){
        let newUnitArr = data.data.adunits.map((u) => {
          return {
            label: u.display_name,
            value: u.id,
          }
        })
        setUnitArr(newUnitArr)
      }else{
        console.log(data.message)
      }
    })
  },[site])

  useDidMountEffect(() => {
    if(unit!=='' && !alertOpen.status){
      setLoading(true)
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userInfo.token},
      };
      fetch('https://api.futurekey.org/api/ad_positions/' + unit, requestOptions)
      .then(response => response.json())
      .then(data => {
        if(data.message==='OK'){
          let the_unit = {
            id: data.data.id,
            platform: data.data.platform.name,
            platform_type: data.data.platform_type.name,
            deduction: data.data.deduction,
            currency: data.data.currency.code,
            user: data.data.user.username
          }
          setUnitData(the_unit)
        }else{
          console.log(data.message)
        }
      })

      fetch('https://api.futurekey.org/api/reports?sorting=date&direction=desc&adunit_id=' + unit + '&start_date='+formatDate(startDate)+'&end_date='+ formatDate(endDate) + '&type=daily&per_page=31&page=1', requestOptions)
      .then(response => response.json())
      .then(data => {
        if(data.message==='OK'){
          setReportArr(data.data.reports)
          setLoading(false)
        }else{
          console.log(data.message)
        }
      })
    }
  },[unit, alertOpen])

  useDidMountEffect(() => {
    if(formValid && formData.impressions!==''){
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userInfo.token},
        body: JSON.stringify(formData)
      };
      fetch('https://api.futurekey.org/api/reports', requestOptions)
      .then(response => response.json())
      .then(data => {
        if(data.message==='OK'){
          setAlertOpen({
            status: true,
            msg: '新增成功'
          })
          resetData()
          setTimeout(() => {
            setAlertOpen({
              status: false,
              msg: ''
            })
          }, 1000);
          
        }else{
          console.log(data.message)
          setAlertOpen({
            status: true,
            msg: '重複新增'
          })
          setTimeout(() => {
            setAlertOpen({
              status: false,
              msg: ''
            })
          }, 1000);
        }
      })
    }
  },[formData])

  return (
    <ThemeProvider theme={theme}>
      <MenuContext.Provider value={{menuOpen, setMenuOpen}}>
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />
          <HeaderAndMenu mainTitle="富塏多媒體系統"/>
          <Box component="main" sx={{ flexGrow: 1, height: '100vh', overflow: 'auto' }}>
            <Toolbar className='fixToolbar' />
            <Snackbar open={alertOpen.status} TransitionComponent={TransitionDown} anchorOrigin={{vertical: 'top', horizontal:'right' }}>
              <Alert severity="success" open={false}>{alertOpen.msg}</Alert>
            </Snackbar>
            <Container className='mainContainer' maxWidth="lg" sx={{ mt: 4, mb: 4 }} >
              <Card sx={{ minWidth: 275 }}>
                <CardContent>
                  <Box component="form" noValidate onSubmit={handleSubmit}>
                    <Grid container alignItems="flex-start" justifyContent="flex-start">
                      <Grid item>
                        <Stack>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              inputFormat="yyyy-MM-dd"
                              error={!formValid}
                              label="日期"
                              id="date"
                              value={dateValue}
                              onChange={handleChangeDate}
                              renderInput={(params) => <TextField size="small" error={!formValid} name="date" {...params} sx={{ width: 200 }} />}
                            />
                          </LocalizationProvider>
                          <Autocomplete
                            disableClearable
                            id="website"
                            options={siteArr}
                            sx={{ width: 200, mt: 2 }}
                            onChange={handleSiteChange}
                            noOptionsText="無相關網站"
                            size="small"
                            renderOption={(props, option) => {
                              return (
                                <li {...props} value={option.value}>
                                  {option.label}
                                </li>
                              );
                            }}
                            renderInput={(params) => <TextField size="small" error={!formValid} name="website" {...params} label="網站" />}
                          />
                          <Autocomplete
                            disableClearable
                            value={unitValue}
                            onChange={(event, newValue) => {
                              setUnitValue(newValue);
                              handleUnitChange(event);
                            }}
                            inputValue={inputUnit}
                            onInputChange={(event, newInputValue) => {
                              setInputUnit(newInputValue);
                            }}
                            id="unit"
                            options={unitArr}
                            sx={{ width: 200, mt: 2 }}
                            noOptionsText="無相關廣告版位"
                            size="small"
                            renderOption={(props, option) => {
                              return (
                                <li {...props} value={option.value}>
                                  {option.label}
                                </li>
                              );
                            }}
                            renderInput={(params) => <TextField size="small" error={!formValid} name="unit" {...params} label="廣告版位" />}
                          />
                          <TextField size="small" error={!formValid} value={impressions} onChange={handleImpressionChange} id="impression" name="impression" label="曝光" variant="outlined" sx={{ width: 200, mt: 2 }}/>
                          <TextField size="small" error={!formValid} value={clicks} onChange={handleClickChange} id="clicks" name="clicks" label="點擊" variant="outlined" sx={{ width: 200, mt: 2 }}/>
                          <TextField size="small" error={!formValid} value={true_revenue} onChange={handleTrueRevenueChange} id="true_revenue" name="true_revenue" label="真實金額" variant="outlined" sx={{ width: 200, mt: 2 }} helperText={!formValid ? errorMessage : ""}/>
                          <Button sx={{ mt:2 }} variant="contained" type="submit">儲存</Button>
                          <TableContainer sx={{mt: 2}}>
                            <Table size='small'  className='reportTable'>
                              <TableBody>
                                <TableRow>
                                  <TableCell>網站主</TableCell>
                                  <TableCell>{unitData.user}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>廣告平台</TableCell>
                                  <TableCell>{unitData.platform}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>廣告類型</TableCell>
                                  <TableCell>{unitData.platform_type}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>扣量</TableCell>
                                  <TableCell>{unit?parseInt(unitData.deduction)+'%':''}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>輸入貨幣</TableCell>
                                  <TableCell>{unitData.currency}</TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Stack>
                      </Grid>
                      <Grid item sx={{ml: 3}}>
                        <TableContainer>
                          <Table className='reportTable' sx={{width: '100%'}} aria-label="table" size="small">
                            <TableHead>
                              <TableRow>
                              {
                                columns.map((column) => (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                  >{column.label}</TableCell>
                                ))
                              }
                              </TableRow>
                            </TableHead>
                            {
                              unit!=='' &&
                              <TableBody>
                                {
                                  loading && 
                                  <TableRow>
                                    <TableCell align="center" colSpan={8}>
                                      <CircularProgress />
                                    </TableCell>
                                  </TableRow>
                                }
                                {
                                  !loading && reportArr.map((row, index) => (
                                    <TableRow key={index}>
                                      {
                                        columns.map((column, index) => (
                                          <TableCell key={index} align={column.align}>
                                            {
                                              column.format
                                                ? column.format(row[column.id])
                                                : column.id ==='action'
                                                  ? <IconButton sx={{p:0}} aria-label="delete_report" size="small" onClick={()=>{
                                                      setOpen(true)
                                                      setDeleteReport(row.id)}}>
                                                      <DeleteIcon fontSize="small" />
                                                    </IconButton>
                                                  : row[column.id]
                                            }
                                          </TableCell>
                                        ))
                                      }
                                    </TableRow>
                                  ))
                                }
                              </TableBody>
                            }
                          </Table>
                        </TableContainer>
                      </Grid>
                    </Grid>
                  </Box>
                </CardContent>
              </Card>
            </Container>
          </Box>
        </Box>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClick={handleClose}
          aria-describedby="alert-dialog-slide-description"
          sx={{zIndex: "1301"}}
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              確定要刪除這筆資料嗎？
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{justifyContent: "space-between"}}>
            <Button onClick={handleClose}>取消</Button>
            <Button onClick={handleDelete}>確定</Button>
          </DialogActions>
        </Dialog>
      </MenuContext.Provider>
    </ThemeProvider>
  )
}
