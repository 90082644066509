import React, { useState, useContext, useEffect } from 'react'
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Slide from '@mui/material/Slide';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import { OpenContext } from '../context/OpenContext'
import { UserContext } from '../context/UserContext'
import { AlertContext } from '../context/AlertContext'
import useDidMountEffect from '../hooks/useDidMountEffect'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddUserDialog({title}) {
  const { dialogOpen, setDialogOpen } = useContext(OpenContext)
  const { setAlertOpen } = useContext(AlertContext)
  const { userInfo } = useContext(UserContext)
  const [ errorMessage, setErrorMessage ] = useState('')
  const [ role ,setRole ] = useState('User')
  const [ currencyArr, setCurrencyArr ] = useState([])
  const [ currency, setCurrency ] = useState("");
  const [ formData, setFormData ] = useState({
    role: '',
    currency_id: '',
    password: '',
    username: '',
    email: '',
  })
  const [formValid, setFormValid] = useState(true)
  const [ loading, setLoading ] = useState(false)

  const handleClose = () => { 
    setDialogOpen({
      add: false,
      view: false,
      edit: false,
    })
    setFormData({
      role: '',
      currency_id: '',
      password: '',
      username: '',
      email: ''
    })
    setRole('User')
    setCurrency(1)
    setLoading(false)
    setFormValid(true)
  }

  const handleRoleChange = (event) => {
    setRole(event.target.value);
  };

  const handleCurrencyChange = (event) => {
    setCurrency(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    setFormValid(true)
    setLoading(true)

    var regNumber = /\d+/;
    var regString = /[a-zA-Z]+/; 

    if(data.get('username').match(/[\u4E00-\u9FA5]/g)){
      setFormValid(false)
      setErrorMessage('使用者名稱只可輸入英數混合')
      setLoading(false)
      return
    }

    if(data.get('username')==='' || data.get('password')==='' || data.get('email')==='' || data.get('password').length < 8 ){
      setFormValid(false)
      setErrorMessage('請確認所有欄位已填寫正確')
      setLoading(false)
      return
    }

    if(!regNumber.test(data.get('password')) || !regString.test(data.get('password')) ){
      setFormValid(false)
      setErrorMessage('密碼需至少為8位數的英數混合')
      setLoading(false)
      return
    }

    setFormData({
      name: '-',
      role: role,
      currency_id: currency==='' ? "1" : currency,
      password: data.get('password'),
      username: data.get('username'),
      email: data.get('email'),
      mails: data.get('mails'),
      status: 1
    })

  }

  useDidMountEffect(() => {
    if(formValid && formData.password !== ''){
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userInfo.token },
        body: JSON.stringify(formData)
      };
      
      fetch('https://api.futurekey.org/api/users/', requestOptions)
        .then(response => response.json())
        .then(data => {
          console.log(data)
          if(data.message==='OK')  {
            setLoading(false)
            handleClose()
            setAlertOpen({
              open: true,
              message: '新增成功'
            })
          }else{
            console.log(data)
            if(data.errors.username){
              setErrorMessage('使用者名稱已存在！')
              setLoading(false)
            }
            if(data.errors.email){
              setErrorMessage('Email已存在！')
              setLoading(false)
            }
            if(data.errors.message==='create fail'){
              setErrorMessage('輸入的Email無效！')
              setLoading(false)
            }
            setFormValid(false)
            setLoading(false)
          }
        })
    }
   }, [formData])

  useEffect (() => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userInfo.token},
    };

    fetch('https://api.futurekey.org/api/currencies', requestOptions)
    .then(response => response.json())
    .then(data => {
      if(data.message==='OK'){
        setCurrencyArr(data.data)
      }else{
        console.log(data.message)
      }
    })
  },[userInfo.token])

  return (
    <Dialog open={dialogOpen.add} onClose={handleClose} TransitionComponent={Transition}>
      <DialogTitle>
        {title}
        <IconButton aria-label="close" onClick={handleClose} sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500], }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Box component="form" noValidate onSubmit={handleSubmit} >
        <DialogContent sx={{ py: 0 }}>
          <Grid container sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
            <Grid item xs={6}>
              <FormControl sx={{ width: '95%' }}>
                <InputLabel sx={{left: '-14px'}} id="demo-simple-select-helper-label">帳號類別</InputLabel>  
                <Select
                  size="small"
                  variant='standard'
                  labelId="role-select-label"
                  id="role_select"
                  name="role_select"
                  label="帳號類別"
                  value={role}
                  onChange={handleRoleChange}
                >
                  <MenuItem value={"User"}>網站主</MenuItem>
                  <MenuItem value={"Accounting"}>會計人員</MenuItem>
                  {
                    userInfo.role === 'Administrator' &&
                    <MenuItem value={"Manager"}>管理人員</MenuItem>
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              { role==='User' &&
                <FormControl sx={{ width: '95%' }}>
                  <InputLabel sx={{left: '-14px'}} id="demo-simple-select-helper-label">結算貨幣</InputLabel>
                  <Select
                    disabled={role!=='User'}
                    size="small"
                    variant='standard'
                    labelId="currency-select-label"
                    id="currency_select"
                    name="currency_select"
                    label="結算貨幣"
                    value={currency}
                    onChange={handleCurrencyChange}
                  >
                    {
                      currencyArr.map((c, index) => (
                        <MenuItem key={c.id} value={c.id}>{c.name}{c.code}</MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              }
            </Grid>
          </Grid>
          <TextField
            margin="dense"
            id="username"
            name="username"
            label="帳號"
            type="text"
            fullWidth
            variant="standard"
            error={!formValid}
          />
          <TextField
            margin="dense"
            id="email"
            name="email"
            label="Email"
            type="email"
            fullWidth
            variant="standard"
            error={!formValid}
          />
          <TextField
            margin="dense"
            id="mails"
            name="mails"
            label="會計聯絡人Email"
            type="text"
            fullWidth
            variant="standard"
            helperText='請以逗點","分隔多個email'
          />
          <TextField
            margin="dense"
            id="password"
            name="password"
            label="密碼"
            type="text"
            fullWidth
            variant="standard"
            helperText={ !formValid ? errorMessage : "密碼需至少為8位數的英數混合" }
            error={!formValid}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>取消</Button>
          {
            loading &&
            <LoadingButton
              loading
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="outlined"
            >
              處理中
            </LoadingButton>
          }
          {
            !loading &&
            <Button variant="contained" type="submit">儲存</Button>
          }
        </DialogActions>
      </Box>
    </Dialog>
  )
}
