import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';

import { styled } from '@mui/material/styles';
import { useContext } from 'react';
import { UserContext } from "../context/UserContext"
import { MenuContext } from "../context/MenuContext"
import { Dialog, Slide, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MuiAppBar from '@mui/material/AppBar';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import MenuOpenRoundedIcon from '@mui/icons-material/MenuOpenRounded';
import AccountMenu from './AccountMenu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import MuiDrawer from '@mui/material/Drawer';
import CircularProgress from '@mui/material/CircularProgress';
import List from '@mui/material/List';
import Link from '@mui/material/Link';
import AdminListItems from './AdminListItems'
import UserListItems from './UserListItems'
import AccountingListItems from './AccountingListItems'
import useMediaQuery from '@mui/material/useMediaQuery';
const drawerWidth = 200;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      whiteSpace: 'nowrap',
      width: drawerWidth+'px',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: '60px',
        [theme.breakpoints.down('md')]: {
          width: '0px',
        },
      }),
    },
  }),
);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function HeaderAndMenu({mainTitle}) {
  const { t } = useTranslation();

  const {userInfo} = useContext(UserContext)
  const {menuOpen, setMenuOpen} = useContext(MenuContext);
  const [ messageArr, setMessageArr ] = useState([])
  const [ anchorEl, setAnchorEl ] = useState(null);
  const [ message, setMessage ] = useState('');
  const [ messageData, setMessageData ] = useState({
    title:'',
    content:'',
    id:''
  });
  const [ messageOpen, setMessageOpen ] = useState(false);
  const open = Boolean(anchorEl);
  const [ loading, setLoading ] = useState(false)
  const matches = useMediaQuery('(max-width:900px)');
  const matches_sm = useMediaQuery('(max-width:767px)');

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMessageClose = () => {
    setMessageOpen(false);
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userInfo.token},
    };

    fetch('https://api.futurekey.org/api/messages/' + message, requestOptions)
    .then(response => response.json())
    .then(data => {
      if(data.message==='OK'){
        
      }else{
        console.log(data.message)
      }
    })
    setMessage('')
  };

  useEffect(()=>{
    if(matches){
      setMenuOpen(false)
    }else{
      setMenuOpen(true)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[matches])

  const toggleDrawer = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(()=>{
    if(messageOpen){
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userInfo.token},
      };

      fetch('https://api.futurekey.org/api/messages/' + message, requestOptions)
      .then(response => response.json())
      .then(data => {
        if(data.message==='OK'){
          setMessageData(data.data)
          setLoading(false)
        }else{
          console.log(data.message)
        }
      })
    }
  },[message, messageOpen, userInfo.token])

  useEffect(() => {
    if(message===''){
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userInfo.token},
      };

      fetch('https://api.futurekey.org/api/messages', requestOptions)
      .then(response => response.json())
      .then(data => {
        if(data.message==='OK'){
          setMessageArr(data.data)
        }else{
          console.log(data.message)
        }
      })
    }
  },[userInfo, message])

  return(
    <React.Fragment>
      <AppBar position="absolute" open={menuOpen} sx={{py:[1]}}>
        <Toolbar
          sx={{
            pr: '24px', // keep right padding when drawer closed
          }}
        >
          <IconButton 
            onClick={toggleDrawer}
            color="inherit"
            aria-label="close drawer"
            edge="start"
            sx={{
              ...(!menuOpen.open && { display: 'none' }),
            }}
          >
            <MenuOpenRoundedIcon />
          </IconButton>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: '36px',
              ...(menuOpen.open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h5"
            color="inherit"
            fontWeight="700"
            letterSpacing="1.5px"
            noWrap
            sx={{ flexGrow: 1 }}
          >
            {mainTitle}
          </Typography>
          <IconButton color="inherit" onClick={handleClick} sx={{ color: '#fff' }}>
            {messageArr.length>0 
              ?
              <Badge badgeContent={messageArr.length} color="secondary">
                <NotificationsIcon />
              </Badge>
              :
              <NotificationsIcon />
            }
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            id="message_menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                maxHeight: 300,
                width: 250,
                overflow: 'scroll',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            {
              messageArr.length>0 
              ?
              messageArr.map((m)=>(
                <MenuItem onClick={()=>{
                  setMessage(m.id)
                  setMessageOpen(true)
                  setLoading(true)
                }} sx={{borderBottom: '1px #ccc solid', py: 1}} key={m.id}>{m.title}</MenuItem>
              ))
              :
              <Typography align='center'>{t("no_new_notifications")}</Typography>
            }
          </Menu>
          <AccountMenu />
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={menuOpen} style={menuOpen?{overflowY:"hidden", width: drawerWidth+'px'}:(matches_sm?{overflowY:"hidden", width: '0px'}:{overflowY:"hidden", width: '60px'})}>
        <Toolbar
          position="sticky"
          className='fixToolbar'
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            px: [1],
            py: [1]
          }}
        >
          <Link href="/" underline="none" color="inherit">
            <img 
              src={process.env.PUBLIC_URL+'/images/Logox1.png'} 
              alt='Logo' 
              style={{ width: '100%' }}
            />
          </Link>
        </Toolbar>
        <List component="nav" sx={{overflowY:"scroll", p: 0}}>
          { (userInfo.role==='User') && <UserListItems open={{menuOpen}} />}
          { (userInfo.role==='Accounting') && <AccountingListItems open={{menuOpen}} />}
          { (userInfo.role==='Administrator' || userInfo.role==='Manager') && <AdminListItems open={{menuOpen}}/>}
        </List>
      </Drawer>
      <Dialog
        fullWidth
        maxWidth='md'
        open={messageOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleMessageClose}
        aria-describedby="alert-dialog-slide-description"
        sx={{zIndex: "1301" }}
      >
        <DialogTitle>
          {messageData.title}
          <IconButton aria-label="close" onClick={handleMessageClose} sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500], }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {
            loading && <CircularProgress />
          }
          {
            !loading &&
            <DialogContentText dangerouslySetInnerHTML={{
                  __html: messageData.content
                }} id="alert-dialog-slide-description">
            </DialogContentText>
          }
        </DialogContent>
      </Dialog>
    </React.Fragment>
  )
}

