import React, { useState, useContext, useEffect } from 'react'
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ForwardIcon from '@mui/icons-material/Forward';
import { OpenContext } from '../context/OpenContext'
import { UserContext } from '../context/UserContext'
import { AlertContext } from '../context/AlertContext'
import useDidMountEffect from "../hooks/useDidMountEffect";

export default function EditRateDialog({title, editId}) {
  const { dialogOpen, setDialogOpen } = useContext(OpenContext)
  const { userInfo } = useContext(UserContext)
  const { setAlertOpen } = useContext(AlertContext)
  const [ errorMessage, setErrorMessage ] = useState('')
  const [ formData, setFormData ] = useState({
    rate: ''
  })

  const [ subFormData, setSubFormData ] = useState({
    rate: ''
  })
  const [ formValid, setFormValid ] = useState(true)
  const [ fromCode, setFromCode ] = useState({}) 
  const [ toCode, setToCode ] = useState({}) 
  const [ rate, setRate ] = useState('') 

  const handleClose = () => {
    setDialogOpen({
      edit: false,
      add: false
    })
    setFormValid(true)
    setFormData({
      rate: ''
    })
    setSubFormData({
      rate: ''
    })
    setRate('')
    setToCode({})
    setFromCode({})
    setErrorMessage('')
  };

  const handleChange = (event) => {
    setRate(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data= {
      rate: rate
    }
    const data_reverse = {
      rate: 1/parseFloat(rate)
    }

    if(rate===''){
      setFormValid(false)
      setErrorMessage('請確認欄位皆已填寫')
      return
    }
    
    setFormData(data)
    setSubFormData(data_reverse)
  }

  const handleSetCurrentRate = () => {
    const from = fromCode.code
    const to = toCode.code
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userInfo.token},
    };

    fetch('https://api.futurekey.org/api/realtime/exchange_rate?from='+from+'&to='+to, requestOptions)
    .then(response => response.json())
    .then(data => {
      if(data.message==='OK'){
        setRate(data.data.rate)
      }else{
        console.log(data.message)
      }
    })

  }

  useEffect(() => {
    if(editId!==''){
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userInfo.token},
      };

      fetch('https://api.futurekey.org/api/exchange_rates/'+ editId , requestOptions)
      .then(response => response.json())
      .then(data => {
        if(data.message==='OK'){
          setFromCode(data.data.from)
          setToCode(data.data.to)
          setRate(data.data.rate)
        }else{
          console.log(data.message)
        }
      })

    }
  },[userInfo, editId])

  useDidMountEffect(() => {
    if(formValid && formData.rate!==''){
      const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userInfo.token },
        body: JSON.stringify(formData)
      };

      fetch('https://api.futurekey.org/api/exchange_rates/' + editId, requestOptions)
      .then(response => response.json())
      .then(data => {
        if(data.message==='OK'){
          setAlertOpen(true)
          handleClose()
        }else{
          console.log(data.message)
        }
      })
    }

  },[formData])

  useDidMountEffect(() => {
    if(formValid && subFormData.rate!==''){
      const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userInfo.token },
        body: JSON.stringify(subFormData)
      };

      var exchange_Id =0

      if(fromCode.code==='USD'){
        exchange_Id=3
      }else if(fromCode.code==='CNY'){
        exchange_Id=1
      }

      fetch('https://api.futurekey.org/api/exchange_rates/' + exchange_Id, requestOptions)
      .then(response => response.json())
      .then(data => {
        if(data.message==='OK'){
          //setAlertOpen(true)
          //handleClose()
        }else{
          console.log(data.message)
        }
      })
    }

  },[subFormData])

  return (
    <Dialog open={dialogOpen.edit} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <Box component="form" noValidate onSubmit={handleSubmit} >
        <DialogContent sx={{ py: 0 }}>
          <Grid container alignItems='center'>
            <Grid item xs={5} >
              <Typography color="text.primary">{fromCode.name} {fromCode.code}</Typography>
            </Grid>
            <Grid item xs={2} sx={{textAlign: 'center'}}>
              <ForwardIcon />
            </Grid>
            <Grid item xs={5} >
              <Typography color="text.primary">{toCode.name} {toCode.code}</Typography>
            </Grid>
          </Grid>
          <Grid container alignItems='flex-end'>
            <Grid item xs={5}>
              <TextField
                margin="dense"
                id="rate"
                name="rate"
                label="匯率"
                type="text"
                fullWidth
                value={rate}
                onChange={handleChange}
                variant="standard"
                sx={{ ml: 1 }}
                error={!formValid}
                helperText={!formValid ? errorMessage : ""}
              />
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={5}>
              <Button fullWidth variant="outlined" onClick={handleSetCurrentRate}>即時匯率</Button>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} type="button">取消</Button>
          <Button variant="contained" type="submit">確認</Button>
        </DialogActions>
      </Box>
    </Dialog>
  )
}
