import React, { useState, useContext } from 'react'
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import { OpenContext } from '../context/OpenContext'
import { AlertContext } from '../context/AlertContext'
import { UserContext } from '../context/UserContext'
import useDidMountEffect from "../hooks/useDidMountEffect";

export default function EditAccountingDialog({title, id}) {
  const { dialogOpen, setDialogOpen } = useContext(OpenContext)
  const { setAlertOpen } = useContext(AlertContext)
  const { userInfo } = useContext(UserContext)
  let nowDate = new Date()
  const [ dateValue, setDateValue ] = useState(nowDate);
  const [ formData, setFormData ] = useState({})
  const [ loading, setLoading ] = useState(false)
  const [ editBill, setEditBill ] = useState({
    user:{username:''},
    platform: {name:''},
    type: '',
    month: '',
    income: '',
    currency: {code:''}
  })

  //取消編輯時
  const handleClose = () => {
    setDialogOpen({
      edit: false,
      view: false  
    })
  };

  useDidMountEffect (() => {
    setLoading(true)
    if(id!==''){
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userInfo.token }
      };

      fetch('https://api.futurekey.org/api/billings/' + id, requestOptions)
      .then(response => response.json())
      .then(data => {
        if(data.message==='OK')  {
          setEditBill(data.data)
          setLoading(false)
        }else{
          setLoading(true)
          console.log(data)
        }
      })
    }
  },[id])

  const formatDate = (v) => {
    let date = new Date(v)
    let y = date.getFullYear()
    let m = date.getMonth()+1
    let d = date.getDate()
    return y+"-"+m+"-"+d
  }

  //儲存時
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    var date =''
    if(data.get('receipt_num')!==''){
      date = dateValue
    }

    setFormData({
      receipt_num: data.get('receipt_num'),
      receipt_date: formatDate(date),
      note: data.get('note')
    });

  }

  useDidMountEffect(() => {
    if(formData.receipt_num){
      const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userInfo.token },
        body: JSON.stringify(formData)
      };
      
      fetch('https://api.futurekey.org/api/billings/' +id, requestOptions)
        .then(response => response.json())
        .then(data => {
          if(data.message==='OK')  {
            setAlertOpen({
              open: true,
              message: '變更成功'
            })
            handleClose()
            setFormData({})
          }else{
            console.log(data)
            setFormData({})
          }
        })

    }
  }, [formData])

  return (
    <Dialog open={dialogOpen.edit} onClose={handleClose}>
      <DialogTitle>
        {title}
        <IconButton aria-label="close" onClick={handleClose} sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500], }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {
        loading 
        ? 
        <CircularProgress sx={{ margin: '10px auto 20px' }} />
        :
        <Box component="form" noValidate onSubmit={handleSubmit} >
          <DialogContent>
            <Typography variant='subtitle1' component="h4" sx={{ mb: 2}}>{editBill.type==='請款'?'廣告平台':'網站主'} : {editBill.platform?editBill.platform.name:editBill.user.username}</Typography>
            <Typography variant='subtitle1' component="h4" sx={{ mb: 2}}>結算月份 : {editBill.month}</Typography>
            <Typography variant='subtitle1' component="h4" sx={{ mb: 2}}>收益 : {editBill.currency.code} {parseFloat(editBill.income).toLocaleString('en-US')}</Typography>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                inputFormat="yyyy-MM-dd"
                label="發票日期"
                id="receipt_date"
                value={dateValue}
                onChange={(newValue) => {setDateValue(newValue);}}
                renderInput={(params) => <TextField name="receipt_date" {...params} fullWidth size="small" variant='standard' margin="dense" />}
              />
            </LocalizationProvider>
            <TextField
              required
              defaultValue={editBill.receipt_num}
              margin="dense"
              id="receipt_num"
              name="receipt_num"
              label="發票編號"
              type="text"
              fullWidth
              variant='standard'
            />
            <TextField multiline minRows={2} size="small" id="note" name="note" label="備註" variant="outlined" margin="dense" fullWidth/>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>取消</Button>
            <Button variant="contained" type="submit">確認</Button>
          </DialogActions>
        </Box>
      }
    </Dialog>
  )
}
