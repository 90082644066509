import React, { useContext } from 'react'
import Dialog from '@mui/material/Dialog';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { OpenContext } from "../context/OpenContext"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PostDetail({data}) {
  const { dialogOpen, setDialogOpen } = useContext(OpenContext)

  const handleClose = () => {
    setDialogOpen(false)
  };
  return (
    <Dialog 
      open={dialogOpen}
      onClose={handleClose}
      TransitionComponent={Transition}
      fullWidth={true}
      maxWidth="lg">
      <DialogTitle>
        {data.title}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Box noValidate >
        <DialogContent sx={{ py: 0 }}>
          <DialogContentText dangerouslySetInnerHTML={{
                __html: data.content
              }} id="alert-dialog-slide-description">
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          { data.file &&
          <Button><a href={data.file} style={{color: "unset"}} target="_blank" rel="noreferrer">下載附件</a></Button>
}
        </DialogActions>
      </Box>
    </Dialog>
  )
}
