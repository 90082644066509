import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// 直接导入翻译文件
import translationEN from './en/translation.json';
import translationZH from './zh/translation.json';

// 配置 i18next
i18n
  .use(LanguageDetector) // 使用 LanguageDetector
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: translationEN },
      zh: { translation: translationZH },
    },
    // 默认语言
    fallbackLng: 'zh',
    // 使用 localStorage 来检测和保存用户的语言偏好
    detection: {
      order: ['localStorage', 'navigator'],
      caches: ['localStorage']
    },
    interpolation: {
      escapeValue: false, // React 已经对 XSS 提供保护
    }
  });

export default i18n;
