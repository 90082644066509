import React, { useState, useContext, useEffect } from 'react'
import { ThemeProvider, styled } from '@mui/material/styles';
import { theme } from './theme'
import ExcelJs from "exceljs";
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell, {tableCellClasses} from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Moment from 'react-moment';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';
import Alert from '@mui/material/Alert';
import {Tooltip, IconButton} from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import Autocomplete from '@mui/material/Autocomplete';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircularProgress from '@mui/material/CircularProgress';
import LanguageIcon from '@mui/icons-material/Language';
import { UserContext } from './context/UserContext'
import { MenuContext } from "./context/MenuContext"
import { AlertContext } from "./context/AlertContext"
import { OpenContext } from "./context/OpenContext"
import HeaderAndMenu from "./components/HeaderAndMenu";
import useDidMountEffect from "./hooks/useDidMountEffect";
import AddSiteDialog from './components/AddSiteDialog'
import EditSiteDialog from './components/EditSiteDialog'
import DownloadIcon from '@mui/icons-material/Download';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function TransitionDown(props) {
  return <Slide {...props} direction="down" />;
}

const columns = [
  { id: 'wid', label: '編號', minWidth: 100 },
  { id: 'user', label: '網站主', minWidth: 100 },
  { id: 'name', label: '網站名稱', minWidth: 100 },
  { id: 'create_date', label: '新增日期', minWidth: 110 },
  { id: 'domain', label: '域名', minWidth: 60 },
  { id: 'status', label: '狀態', minWidth: 65 },
  { id: 'action', label: '操作', minWidth: 190 }
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function Website() {
  const { userInfo } = useContext(UserContext);
  const [ menuOpen, setMenuOpen ] = useState(true);
  const [ alertOpen, setAlertOpen ] = useState({
    open: false,
    message: ''
  });
  const [ dialogOpen, setDialogOpen] = useState({
    add: false,
    edit: false,
  }) 
  const [ dialogTitle, setDialogTitle ] = useState('') 
  const [ siteArr, setSiteArr ] = useState([]);
  const [ userArr, setUserArr ] = useState([]);
  const [ user, setUser ] = useState('all')
  const [ userValue, setUserValue ] = useState('全部')
  const [ inputUser, setInputUser ] = useState('全部')
  const [ status, setStatus ] = useState('all')
  const [ keyword, setKeyword ] = useState('')
  const [ open, setOpen ] = useState(false)
  const [ editSiteStatus, setEditSiteStatus ] = useState()
  const [ editSite, setEditSite ] = useState({
    user: {id: ''},
    name: '',
    domain: '',
    pmc_name: '',
    dable_code: ''
  })
  const [ viewAccount, setViewAccount ] = useState({})
  const [ loading, setLoading ] = useState(false)
  const [ page, setPage ] = useState(0);
  const [ rowsPerPage, setRowsPerPage ] = useState(15);
  const [ pageInfo, setPageInfo ] = useState(0);
  const [ downloadSite, setDownloadSite ] = useState([]);
  const getExcel = new ExcelJs.Workbook();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setLoading(true)
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    setLoading(true)
  };

  const handleSearchChange = (event) => {
    setKeyword(event.target.value);
    setPage(0)
    setLoading(true)
  }

  const handleUserChange = (event) => {
    setUser(event.target.value);
    setPage(0)
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
    setPage(0)
  };

  const handleSiteStatus = (event) => {
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userInfo.token },
    };

    fetch('https://api.futurekey.org/api/websites/' + editSiteStatus + '/status', requestOptions)
    .then(response => response.json())
    .then(data => {
      if(data.message==='OK'){
        setAlertOpen({
          open: true,
          message: '變更成功'
        })
        setOpen(false)
      }else{
        setOpen(false)
        console.log(data.message)
      }
    })
  };

  const handleClose = () => {
    setOpen(false)
  }

  const handleAddSite = () => {
    setDialogTitle('新增網站')
    setDialogOpen({
      add: true,
      edit: false,
    })
  }

  useDidMountEffect (() => {
    if(dialogOpen.view === false && dialogOpen.edit === false){
      setDialogTitle('')
      setViewAccount({})
    }
  },[dialogOpen])

  useDidMountEffect (() => {
    if(viewAccount.uid !== undefined){
      setDialogTitle(viewAccount.uid+'的匯款資料')
      setDialogOpen({
        view: true,
        edit: false,
      })
    }
  },[viewAccount])

  useEffect (() => {
    if(!dialogOpen.add && !dialogOpen.edit && !open){
      setLoading(true)
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userInfo.token},
      };

      if(keyword!==''){
        fetch('https://api.futurekey.org/api/websites?role=' + (user==='all'?'':user) + '&status=' + (status==='all' ? '' : status) + '&keyword=' + keyword + '&per_page=' + rowsPerPage + '&page=' + (page+1), requestOptions)
        .then(response => response.json())
        .then(data => {
          if(data.message==='OK'){
            setLoading(false)
            setSiteArr(data.data.websites)
            setPageInfo(data.data.meta.total)
          }else{
            console.log(data.message)
          }
        })
      }else{
      
        fetch('https://api.futurekey.org/api/users?role=User&status=1&per_page=500&page=1', requestOptions)
        .then(response => response.json())
        .then(data => {
          if(data.message==='OK'){
            let newUserArr = data.data.users.map((u) => {
              return {
                label: u.username,
                value: u.id,
              }
            })
            newUserArr.unshift({label: '全部', value: 'all'})
            setUserArr(newUserArr)
          }else{
            console.log(data.message)
          }
        })

        fetch('https://api.futurekey.org/api/websites?user_id=' + (user==='all' ? '' : user) + '&status=' + (status==='all' ? '' : status) + '&per_page=' + rowsPerPage + '&page=' + (page+1), requestOptions)
        .then(response => response.json())
        .then(data => {
          if(data.message==='OK'){
            setSiteArr(data.data.websites)
            setPageInfo(data.data.meta.total)
            setLoading(false)
          }else{
            console.log(data.message)
          }
        })
      }

      setTimeout(() => {
        setAlertOpen({
          open: false,
          message: ''
        })
      }, 3000);
    }
  },[open, dialogOpen, userInfo, status, user, rowsPerPage, page, keyword])

  const handleDownloadCSV = () => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userInfo.token }
    };

    fetch('https://api.futurekey.org/api/websites?user_id=' + (user==='all' ? '' : user) + '&status=' + (status==='all' ? '' : status) + '&per_page=500&page=1', requestOptions)
    .then(response => response.json())
    .then(data => {
      if(data.message==='OK')  {
        setDownloadSite(data.data.websites)
      }else{
        console.log(data)
      }
    })
  }

  useEffect(()=>{
    if(downloadSite.length>0){
      var column=[]
      var data=[]
      const sheet = getExcel.addWorksheet('網站列表', {properties: {defaultColWidth: 15}});

      column = [{name:'編號'}, {name:'網站主'}, {name:'網站名稱'}, {name:'新增日期'}, {name:'域名'}, {name:'狀態'}]
      // eslint-disable-next-line array-callback-return
      downloadSite.map((w)=>{
          data.push([w.uid, w.user.username, w.name, w.created_at, w.domain,  w.status?'啟用':'停用'])
      })

      sheet.addTable({
        name: 'reportCSV',
        ref: 'A1',
        columns: column,
        rows: data
      });

      getExcel.xlsx.writeBuffer().then((content) => {
        const link = document.createElement("a");
        const blobData = new Blob([content], {
          type: "application/vnd.ms-excel;charset=utf-8;"
        });
        link.download = '網站列表.xlsx';
        link.href = URL.createObjectURL(blobData);
        link.click();
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[downloadSite])

  return (
    <ThemeProvider theme={theme}>
      <MenuContext.Provider value={{menuOpen, setMenuOpen}}>
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />
          <HeaderAndMenu mainTitle="網站管理"/>
          <Box component="main" sx={{ flexGrow: 1, height: '100vh', overflow: 'auto', }}>
            <Toolbar className='fixToolbar' />
            <Snackbar open={alertOpen.open} TransitionComponent={TransitionDown} anchorOrigin={{vertical: 'top', horizontal:'right' }}>
              <Alert severity="success" open={false}>{alertOpen.message}</Alert>
            </Snackbar>
            <Container className='mainContainer' maxWidth="lg" sx={{ mt: 4, mb: 4 }} >
              <Card sx={{ minWidth: 275 }}>
                <CardContent>
                  <Grid container sx={{alignItems: 'flex-end'}}>
                    <Grid item sx={{alignItems: 'flex-end'}} xs={8}>
                      <Grid container sx={{alignItems: 'flex-end'}}>
                        <Grid item mr={2}>
                          <Button onClick={handleAddSite} variant="contained" sx={{ minWidth: 130 }} startIcon={<AddIcon />} >新增網站</Button>
                        </Grid>
                        <Grid item mr={2}>
                          <Autocomplete
                            disableClearable
                            id="user"
                            value={userValue}
                            onChange={(event, newValue) => {
                              setUserValue(newValue);
                              handleUserChange(event);
                            }}
                            inputValue={inputUser}
                            onInputChange={(event, newInputValue) => {
                              setInputUser(newInputValue);
                            }}
                            options={userArr}
                            sx={{ width: 160, mt: 2 }}
                            noOptionsText="無相關網站主"
                            size="small"
                            renderOption={(props, option) => {
                              return (
                                <li {...props} value={option.value}>
                                  {option.label}
                                </li>
                              );
                            }}
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            renderInput={(params) => <TextField size="small" name="user" {...params} label="網站主" />}
                          />
                          {/* <FormControl sx={{ minWidth: 130 }}>
                            <InputLabel id="user-select-helper-label">網站主</InputLabel>
                            <Select
                              size="small"
                              labelId="user-select-label"
                              id="user_select"
                              name="user_select"
                              label="網站主"
                              value={user}
                              onChange={handleUserChange}
                            >
                              <MenuItem value="all">全部</MenuItem>
                              {
                                userArr.map((u)=>(
                                  <MenuItem value={u.id} key={u.id}>{u.username}</MenuItem>
                                ))
                              }
                            </Select>
                          </FormControl> */}
                        </Grid>
                        <Grid item mr={2}>
                          <FormControl sx={{ minWidth: 130 }}>
                            <InputLabel id="status-select-helper-label">顯示資料</InputLabel>
                            <Select
                              size="small"
                              labelId="status-select-label"
                              id="status_select"
                              name="status_select"
                              label="顯示資料"
                              value={status}
                              onChange={handleStatusChange}
                            >
                              <MenuItem value={'all'}>全部</MenuItem>
                              <MenuItem value={true}>啟用中</MenuItem>
                              <MenuItem value={false}>已停用</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item mr={2}>
                          <Button onClick={handleDownloadCSV}  variant="outlined" color='primary' startIcon={<DownloadIcon />}>下載CSV</Button>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid className='fix' item sx={{alignItems: 'center', textAlign: 'right'}} xs={4}>
                      <TextField
                        sx={{ width: 200 }}
                        id="search_key"
                        placeholder='請輸入關鍵字搜尋'
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                        value={keyword}
                        onChange={handleSearchChange}
                        size="small"
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <Card sx={{ minWidth: 275, mt: 2 }}>
                <TableContainer>
                  <Table aria-label="table" size="small">
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align="center"
                            style={{ minWidth: column.minWidth }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        loading 
                        ? 
                        <TableRow>
                          <TableCell align="center" colSpan={8}>
                            <CircularProgress />
                          </TableCell>
                        </TableRow>
                        :
                        (
                        siteArr.length === 0 ?
                          <StyledTableRow>
                            <StyledTableCell align="center" colSpan={8}>
                              無相關資料
                            </StyledTableCell>
                          </StyledTableRow>
                        :
                        siteArr.length > 0 &&
                        siteArr.map((row, index) => (
                          <StyledTableRow hover role="checkbox" tabIndex={-1} key={index}>
                            <StyledTableCell align="center">{row.uid}</StyledTableCell>
                            <StyledTableCell align="center">{row.user.username}</StyledTableCell>
                            <StyledTableCell align="center">{row.name}</StyledTableCell>
                            <StyledTableCell align="center">
                              <Moment format="YYYY-MM-DD">{row.created_at}</Moment>
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row.domain &&
                                <Tooltip disableFocusListener title={row.domain}>
                                  <IconButton sx={{p:0}} aria-label="view-domain">
                                    <LanguageIcon />
                                  </IconButton>
                                </Tooltip>
                              }
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {
                                row.status === false ? "停用" : "啟用"
                              }
                            </StyledTableCell>
                            <StyledTableCell align="center">    
                              <Button onClick={()=>{
                                  setEditSite(row)
                                  setDialogOpen({
                                    add: false,
                                    edit: true
                                  })
                                  setDialogTitle('編輯網站')
                                }}variant="text" sx={{ mr: 2 }} startIcon={<EditIcon />}>
                                編輯
                              </Button>
                              {
                                row.status === false
                                ? <Button onClick={() => {
                                  setEditSiteStatus(row.id)
                                  setOpen(true)
                                }} variant="text" color='success' startIcon={<CheckCircleIcon />}>啟用</Button>
                                : <Button onClick={() => {
                                  setEditSiteStatus(row.id)
                                  setOpen(true)
                                }} variant="text" color='error' startIcon={<CancelIcon />}>停用</Button>
                              }
                            </StyledTableCell>
                          </StyledTableRow>
                        ))
                        )
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[15, 30]}
                  component="div"
                  count={pageInfo}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Card>
            </Container>
          </Box>
        </Box>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              確定要
              {
                status === false ? "啟用" : "停用"
              }
              此網站嗎？
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{justifyContent: "space-between"}}>
            <Button onClick={handleClose}>取消</Button>
            <Button onClick={handleSiteStatus}>確定</Button>
          </DialogActions>
        </Dialog>
        <OpenContext.Provider value={{dialogOpen, setDialogOpen}}>
          <AlertContext.Provider value={{alertOpen, setAlertOpen}}>
            <EditSiteDialog title={dialogTitle} data={editSite}/>
            <AddSiteDialog title={dialogTitle}/>
          </AlertContext.Provider>
        </OpenContext.Provider>
      </MenuContext.Provider>
    </ThemeProvider>
  )

}
