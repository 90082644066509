import React, { useState, useContext, useEffect } from 'react'
import { ThemeProvider } from '@mui/material/styles';
import { theme } from './theme'
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Moment from 'react-moment';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import DownloadIcon from '@mui/icons-material/Download';
import { UserContext } from './context/UserContext'
import { MenuContext } from "./context/MenuContext"
import { AlertContext } from "./context/AlertContext"
import { OpenContext } from "./context/OpenContext"
import HeaderAndMenu from "./components/HeaderAndMenu";
import AddPostDialog from './components/AddPostDialog'
import EditPostDialog from './components/EditPostDialog'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function TransitionDown(props) {
  return <Slide {...props} direction="down" />;
}

const columns = [
  { id: 'title', label: '標題', minWidth: 150 },
  { id: 'content', label: '內容', minWidth: 300 },
  { id: 'file', label: '附件', minWidth: 50 },
  { id: 'period', label: '公告時間', minWidth: 110 },
  { id: 'status', label: '狀態', minWidth: 80 },
  { id: 'action', label: '操作', minWidth: 220 }
];

export default function Post() {
  const { userInfo } = useContext(UserContext);
  const [ menuOpen, setMenuOpen ] = useState(true);
  const [ alertOpen, setAlertOpen ] = useState({
    open: false,
    message: ''
  });
  const [ dialogOpen, setDialogOpen ] = useState({
    add: false,
    edit: false
  }) 
  const [ dialogTitle, setDialogTitle ] = useState('') 
  const [ postArr, setPostArr ] = useState([]);
  const [ editPost, setEditPost ] = useState({
    title: '',
    content: '',
    data: ''
  })
  const [ status, setStatus ] = useState(true)
  const [ open, setOpen ] = useState(false)
  const [ editStatus, setEditStatus] = useState()
  const [ loading, setLoading ] = useState(false)
  const [ page, setPage ] = React.useState(0);
  const [ rowsPerPage, setRowsPerPage ] = React.useState(15);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const handleEditStatus = (event) => {
    if(open){
      const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userInfo.token },
        body: JSON.stringify({ status: false })
      };

      fetch('https://api.futurekey.org/api/posts/' + editStatus + '/status', requestOptions)
      .then(response => response.json())
      .then(data => {
        if(data.message==='OK'){
          setOpen(false)
          setAlertOpen(true)
        }else{
          console.log(data.message)
        }
      })

    }else{
      setEditStatus(event.target.attributes.attr_id.nodeValue)
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false)
  }

  const handleAddPost = () => {
    setDialogTitle('新增公告')
    setDialogOpen({
      add: true,
      edit: false
    })
  }

  useEffect (() => {
    if(!dialogOpen.add && !dialogOpen.edit && !open){
      setLoading(true)
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userInfo.token},
      };
      
      fetch('https://api.futurekey.org/api/posts?&direction=asc&status=' + status, requestOptions)
      .then(response => response.json())
      .then(data => {
        if(data.message==='OK'){
          setPostArr(data.data.posts)
          setLoading(false)
          setTimeout(() => {
            setAlertOpen({
              open: false,
              message: ''
            })
          }, 3000);
        }else{
          console.log(data.message)
        }
      })
    }
  },[dialogOpen, userInfo, status, open])

  return (
    <ThemeProvider theme={theme}>
      <MenuContext.Provider value={{menuOpen, setMenuOpen}}>
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />
          <HeaderAndMenu mainTitle="公告管理"/>
          <Box component="main" sx={{ flexGrow: 1, height: '100vh', overflow: 'auto', }}>
            <Toolbar className='fixToolbar' />
            <Snackbar open={alertOpen.open} TransitionComponent={TransitionDown} anchorOrigin={{vertical: 'top', horizontal:'right' }}>
              <Alert severity="success" open={false}>{alertOpen.message}</Alert>
            </Snackbar>
            <Container className='mainContainer' maxWidth="lg" sx={{ mt: 4, mb: 4 }} >
              <Card sx={{ minWidth: 275 }}>
                <CardContent>
                  <Grid container sx={{alignItems: 'center'}}>
                    <Grid item mr={2}>
                      <Button onClick={handleAddPost} variant="contained" sx={{ minWidth: 130 }} startIcon={<AddIcon />} >新增公告</Button>
                    </Grid>
                    <Grid item mr={3}>
                      <FormControl sx={{ minWidth: 150 }}>
                        <InputLabel id="status-select-helper-label">顯示資料</InputLabel>
                        <Select
                          size="small"
                          labelId="status-select-label"
                          id="status_select"
                          name="status_select"
                          label="顯示資料"
                          value={status}
                          onChange={handleStatusChange}
                        >
                          <MenuItem value={true}>發布中</MenuItem>
                          <MenuItem value={false}>已過期</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <Card sx={{ minWidth: 275, mt: 2 }}>
                <TableContainer>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align="center"
                            style={{ minWidth: column.minWidth }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        loading 
                        ? 
                        <TableRow>
                          <TableCell align="center" colSpan={8}>
                          <CircularProgress />
                          </TableCell>
                        </TableRow>
                        :
                        (
                        postArr.length === 0 ?
                          <TableRow>
                            <TableCell align="center" colSpan={8}>
                              無相關資料
                            </TableCell>
                          </TableRow>
                        :
                        postArr.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => (
                          <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                            <TableCell align="center">{row.title}</TableCell>
                            <TableCell align="left" dangerouslySetInnerHTML={{
                              __html: row.content
                            }}></TableCell>
                            <TableCell align="center">
                              {
                                row.file &&
                                <IconButton color="info" aria-label="download">
                                  <a href={row.file} style={{color: "unset"}} target="_blank" rel="noreferrer">
                                    <DownloadIcon />
                                  </a>
                                </IconButton>
                              }
                            </TableCell>
                            <TableCell align="center">
                              <Moment format="YYYY-MM-DD">{row.created_at}</Moment><br/>
                              <Moment format="YYYY-MM-DD">{row.period}</Moment>
                            </TableCell>
                            <TableCell align="center">
                              {
                                row.status === false ? "已過期" : "發布中"
                              }
                            </TableCell>
                            <TableCell align="center">    
                              <Button onClick={()=>{
                                  setEditPost(postArr[index]);
                                  setDialogOpen({
                                    edit:true,
                                    add:false
                                  })}
                                } variant="text" startIcon={<EditIcon />}>
                                編輯
                              </Button>
                              {
                                row.status === true &&
                                <Button attr_id={row.id} onClick={handleEditStatus} variant="text" color='error' startIcon={<CancelIcon />}>下架</Button>
                              }
                            </TableCell>
                          </TableRow>
                        ))
                        )
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[15, 30]}
                  component="div"
                  count={postArr.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Card>
            </Container>
          </Box>
        </Box>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              確定要下架此公告嗎？
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{justifyContent: "space-between"}}>
            <Button onClick={handleClose}>取消</Button>
            <Button onClick={handleEditStatus}>確定</Button>
          </DialogActions>
        </Dialog>
        <OpenContext.Provider value={{dialogOpen, setDialogOpen}}>
          <AlertContext.Provider value={{alertOpen, setAlertOpen}}>
            <AddPostDialog title={dialogTitle}/>
            <EditPostDialog title={dialogTitle} data={editPost}/>
          </AlertContext.Provider>
        </OpenContext.Provider>
      </MenuContext.Provider>
    </ThemeProvider>
  )
}
