import React from 'react'
import { useTranslation } from 'react-i18next';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Link from '@mui/material/Link';
import BarChartIcon from '@mui/icons-material/BarChart';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';

const path = window.location.pathname

export default function UserListItems() {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Link  href="/report" underline="none" color="inherit">
        <ListItemButton selected={path === '/report'}>
          <ListItemIcon>
            <BarChartIcon />
          </ListItemIcon>
          <ListItemText primary={t("revenue_report")} />
        </ListItemButton>
      </Link>
      <Link  href="/billing" underline="none" color="inherit">
        <ListItemButton selected={path === '/billing'}>
          <ListItemIcon>
            <AccountBalanceWalletIcon />
          </ListItemIcon>
          <ListItemText primary={t("payment_history")} />
        </ListItemButton>
      </Link>
    </React.Fragment>
  )
}
