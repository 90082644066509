import React, { useState, useContext, useEffect } from 'react'
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import Grid from '@mui/material/Grid';
import CloseIcon from '@mui/icons-material/Close';
import { OpenContext } from '../context/OpenContext'
import { UserContext } from '../context/UserContext'
import { AlertContext } from '../context/AlertContext'
import useDidMountEffect from '../hooks/useDidMountEffect'
import { EditorState, convertFromHTML, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { Editor } from 'react-draft-wysiwyg';
import '../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function EditPostDialog({title, data}) {
  const { dialogOpen, setDialogOpen } = useContext(OpenContext)
  const { setAlertOpen } = useContext(AlertContext)
  const { userInfo } = useContext(UserContext)
  const [ errorMessage, setErrorMessage ] = useState('')
  const [ dateValue, setDateValue ] = useState();
  const [ file, setFile ] = useState('');
  const [ formData, setFormData ] = useState({
    title: '',
    content: '',
    period: '',
  })
  const [ formValid, setFormValid ] = useState(true)
  const [ loading, setLoading ] = useState(false)
  const [ open, setOpen ] = useState(false)
  const [ loadingD, setLoadingD ] = useState(false)
  const [ editor, setEditor] = useState(EditorState.createEmpty())

  useEffect (() => {
    setDateValue(data.period)
  },[data])

  const handleClose = () => { 
    setDialogOpen({
      add: false,
      edit: false
    })
    setFormData({
      title: '',
      content: '',
      period: '',
    })
  }

  const handleDelete = () => {
    if(open){
      setLoadingD(true)
      var requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userInfo.token},
      };

      fetch('https://api.futurekey.org/api/posts/'+ data.id, requestOptions)
      .then(response => response.json())
      .then(data => {
        if(data.message==='OK'){
          setAlertOpen({
            open: true,
            message: '公告已刪除'
          })
          setLoadingD(false)
          handleClose()
          handleConfirmClose()
        }else{
          setLoadingD(false)
          console.log(data.message)
        }
      })

    }else{
      setOpen(true)
    }
  }

  const handleConfirmClose = () => {
    setOpen(false)
  }

  const handleFileChange = (event) => {
    setFile(event.target.files[0].name)
  }

  const handleRemoveFile = () => {
    setFile('')
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    setFormValid(true)
    setLoading(true)

    if(data.get('title')==='' || data.get('content')===''){
      setFormValid(false)
      setErrorMessage('請確認所有欄位已填寫正確')
      return
    }

    if(file===''){
      setFormData({
        _method: 'PUT',
        title: data.get('title'),
        content: draftToHtml(convertToRaw(editor.getCurrentContent())),
        period: data.get('date'),
        status: 1
      })
    }else{
      setFormData({
        _method: 'PUT',
        title: data.get('title'),
        content: draftToHtml(convertToRaw(editor.getCurrentContent())),
        period: data.get('date'),
        file: data.get('file'),
        status: 1
      })
    }

  }

  const handleEditorChange = (editorState) =>{
    setEditor(editorState)
    //console.log(draftToHtml(convertToRaw(editorState.getCurrentContent())))
  }

  useEffect(()=>{
    const blocksFromHTML = convertFromHTML(data.content);
    const state = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap,
    );
    setEditor(EditorState.createWithContent(state))
  },[data])

  useDidMountEffect (() => {
    if(formValid && formData.title!==''){
      if(formData.file){
        var uploadData = new FormData()
        uploadData.append('title',formData.title)
        uploadData.append('content',formData.content)
        uploadData.append('file',formData.file)
        uploadData.append('period',formData.period)
        uploadData.append('_method',formData._method)
        uploadData.append('status',formData.status)

        var requestOptions = {
          method: 'POST',
          headers: {'Authorization': 'Bearer ' + userInfo.token},
          body: uploadData
        };
      }else{
        // eslint-disable-next-line no-redeclare
        var requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userInfo.token},
          body: JSON.stringify(formData)
        };
      }

      fetch('https://api.futurekey.org/api/posts/'+ data.id, requestOptions)
      .then(response => response.json())
      .then(data => {
        if(data.message==='OK'){
          setLoading(false)
          setAlertOpen({
            open: true,
            message: '變更成功'
          })
          handleClose()
        }else{
          setLoading(false)
          console.log(data.message)
        }
      })
    }
  },[formData])

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        sx={{zIndex: "1301"}}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            確定要刪除此公告嗎？
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{justifyContent: "space-between"}}>
          <Button onClick={handleConfirmClose}>取消</Button>
          {
            loadingD &&
            <LoadingButton
              loading
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="outlined"
            >
              處理中
            </LoadingButton>
          }
          {
            !loadingD &&
            <Button onClick={handleDelete}>確定</Button>
          }
        </DialogActions>
      </Dialog>
      <Dialog open={dialogOpen.edit} fullWidth maxWidth="lg" onClose={handleClose} TransitionComponent={Transition}>
        <DialogTitle>
          {title}
          <IconButton aria-label="close" onClick={handleClose} sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500], }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Box component="form" noValidate onSubmit={handleSubmit} >
          <DialogContent sx={{ py: 0 }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                inputFormat="yyyy-MM-dd"
                error={!formValid}
                label="下架日期"
                id="date"
                defaultValue={data.period}
                value={dateValue}
                onChange={(newValue) => {setDateValue(newValue);}}
                renderInput={(params) => <TextField margin="dense" fullWidth error={!formValid} name="date" {...params} />}
              />
            </LocalizationProvider>
            <TextField
              margin="dense"
              id="title"
              name="title"
              label="標題"
              type="text"
              defaultValue={data.title}
              fullWidth
              variant="outlined"
              error={!formValid}
            />
            <Editor 
              editorState={editor}
              wrapperClassName="editor-wrapper"
              editorClassName="editor"
              onEditorStateChange={handleEditorChange}
              toolbar={{
                options: ['inline','fontSize', 'textAlign', 'colorPicker', 'link'],
              }}/>
            {/* <TextField
              margin="dense"
              multiline
              rows={4}
              id="content"
              name="content"
              label="內容"
              type="text"
              defaultValue={data.content}
              fullWidth
              variant="outlined"
              error={!formValid}
              helperText={!formValid ? errorMessage : ""}
            /> */}
            <Typography variant="caption" component="p" color="error">{!formValid ? errorMessage : ""}</Typography>
            <Grid container sx={{ alignItems: 'center'}}>
              <Grid item xs={5}>
                <Button sx={{ my: 1.5, minWidth: "40%"}} size="large" component="label" variant="outlined" startIcon={<DriveFolderUploadIcon />}>
                  {data.file===null ? "上傳檔案" : "變更檔案"}
                  <input name="file" id="file" hidden accept="image/*" type="file" onChange={handleFileChange}/>
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle1" component="p">{file}</Typography>
              </Grid>
              <Grid item xs={1}>
                {
                  file !== '' &&
                  <IconButton aria-label="delete" onClick={handleRemoveFile}>
                    <DeleteIcon />
                  </IconButton>
                }
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{justifyContent: 'space-between'}}>
            <Grid item>
              <Button color="error" variant='outlined' onClick={handleDelete}>刪除</Button>
            </Grid>
            <Grid item>
              <Button sx={{mr: 2}} onClick={handleClose}>取消</Button>
              {
                loading &&
                <LoadingButton
                  loading
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  variant="outlined"
                >
                  處理中
                </LoadingButton>
              }
              {
                !loading &&
                <Button variant="contained" type="submit">儲存</Button>
              }
            </Grid>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  )
}
