import React from 'react'
import { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ThemeProvider } from '@mui/material/styles';
import { theme } from './theme'
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Title from './components/Title'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Moment from 'react-moment';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { UserContext } from './context/UserContext'
import { OpenContext } from "./context/OpenContext"
import { MenuContext } from "./context/MenuContext"
import HeaderAndMenu from './components/HeaderAndMenu'
import PostDetail from './components/PostDetail'

export default function HomePage() {
  const { t } = useTranslation();
  const { userInfo } = useContext(UserContext)
  const [ menuOpen, setMenuOpen ] = useState(true);
  const [ posts, setPosts ] = useState([])
  const [ post, setPost ] = useState({
    title:'',
    content:'',
    file:''
  })
  const [dialogOpen, setDialogOpen] = useState(false)

  useEffect (() => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userInfo.token},
    };

    fetch('https://api.futurekey.org/api/posts?status=true', requestOptions)
    .then(response => response.json())
    .then(data => {
      if(data.message==='OK'){
        setPosts(data.data.posts)
      }else{
        console.log(data.message)
      }
    })
  },[userInfo])

  const handleOpenPost = (index) => {
    setPost(posts[index])
    setDialogOpen(true)
  }

  return (
    <ThemeProvider theme={theme}>
      <MenuContext.Provider value={{menuOpen, setMenuOpen}}>
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />
          <HeaderAndMenu mainTitle={t('main_title')}/>
          <Box component="main" sx={{ flexGrow: 1, height: '100vh', overflow: 'auto' }}>
            <Toolbar className='fixToolbar' />
            <Container className='mainContainer' maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
              <Card sx={{ minWidth: 275 }}>
                <CardContent>
                  <Title title={t("bulletin_board_title")} />
                  <TableContainer>
                    <Table aria-label="table" className='non-border-table'>
                      <TableBody>
                        {
                          posts && posts.length > 0 &&
                            posts.map((post, index)=>(
                              <TableRow key={index} hover className='clickableTable' onClick={() => handleOpenPost(index)}>
                                <TableCell style={{ width: '50%' }} component="td">
                                  {post.title}
                                </TableCell>
                                <TableCell align="right" style={{ width: '50%' }} component="td">
                                  <Moment format="YYYY/MM/DD">{post.created_at}</Moment>
                                </TableCell>
                              </TableRow>
                            ))
                        }
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardContent>
              </Card>
            </Container>
            <OpenContext.Provider value={{dialogOpen, setDialogOpen}}>
              <PostDetail data={post}/>
            </OpenContext.Provider>
          </Box>
        </Box>
      </MenuContext.Provider>
    </ThemeProvider>
  )
}
