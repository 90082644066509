import React, { useState, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next';

import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { OpenContext } from '../context/OpenContext'
import { UserContext } from '../context/UserContext'
import { AlertContext } from '../context/AlertContext'
import Box from '@mui/material/Box';
import useDidMountEffect from "../hooks/useDidMountEffect";

export default function EditInfoDialog({title}) {
  const { t } = useTranslation();

  const { dialogOpen, setDialogOpen } = useContext(OpenContext)
  const { setAlertOpen } = useContext(AlertContext)
  const { userInfo } = useContext(UserContext)
  const [errorMessage, setErrorMessage] = useState('')
  const [userData, setUserData] = useState({
    name: '',
    email: '',
  })
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    currency_id: '',
    status: ''
  })
  const [formValid, setFormValid] = useState(true)

  const handleClose = () => {
    setDialogOpen({
      changePwd: false,
      editInfo: false,
      editAccount: false,
      addAccount: false
    })
    setFormValid(true)
    setFormData({
      name: '',
      email: '',
      currency_id: '',
      status: ''
    })
    setErrorMessage('')
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    setFormValid(true)

    if(data.get('email')===''){
      setFormValid(false)
      setErrorMessage(t('password_rule3'))
    }

    setFormData({
      name: data.get('name')===''?'-':data.get('name'),
      email: data.get('email'),
      mails: data.get('mails'),
      currency_id: userData.currency.id,
      status: userInfo.status
    });

  }

  useEffect(() => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userInfo.token },
    };
    
    fetch('https://api.futurekey.org/api/users/'+userInfo.id, requestOptions)
      .then(response => response.json())
      .then(data => {
        if(data.message==='OK')  {
          setUserData(data.data)
        }else{
          console.log(data)
        }
      })
  },[userInfo,dialogOpen])

  useDidMountEffect(() => {
    if(formValid && formData.name !== ''){
      const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userInfo.token },
        body: JSON.stringify(formData)
      };
      
      fetch('https://api.futurekey.org/api/users/'+userInfo.id, requestOptions)
        .then(response => response.json())
        .then(data => {
          if(data.message==='OK')  {
            setAlertOpen(true)
            handleClose()
          }else{
            console.log(data)
            if(data.errors){
              setErrorMessage(t('email_error'))
            }
            setFormValid(false)
          }
        })
    }
    setTimeout(() => {
      setAlertOpen(false)
    }, 3000);
   }, [formData])

  return (
    <Dialog open={dialogOpen.editInfo} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <Box component="form" noValidate onSubmit={handleSubmit} >
      <DialogContent>
        <TextField
          defaultValue={userData.name==='-'?'':userData.name}
          autoFocus
          margin="dense"
          id="name"
          name="name"
          label={t("contact_person")}
          type="text"
          fullWidth
          variant="standard"
          error={!formValid}
        />
        <TextField
          defaultValue={userData.email}
          margin="dense"
          id="email"
          required
          name="email"
          label="Email"
          type="email"
          fullWidth
          variant="standard"
          error={!formValid}
          helperText={ !formValid ? errorMessage : "" }
        />
        <TextField
          margin="dense"
          id="mails"
          name="mails"
          label={t('accountant_email_label')}
          type="text"
          fullWidth
          variant="standard"
          helperText={t('accountant_email_helper')}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t('cancel')}</Button>
        <Button variant="contained" type="submit">{t('submit')}</Button>
      </DialogActions>
      </Box>
    </Dialog>
  )
}
