import React, { useState, useContext } from 'react'
import { useTranslation } from 'react-i18next';

import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { OpenContext } from '../context/OpenContext'
import useDidMountEffect from "../hooks/useDidMountEffect";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ForgetPwdDialog() {
  const { t } = useTranslation();

  const { dialogOpen, setDialogOpen } = useContext(OpenContext)
  const [errorMessage, setErrorMessage] = useState('')
  const [formData, setFormData] = useState({
    email: ''
  })
  const [formValid, setFormValid] = useState(true)
  const [open, setOpen] = useState(false)

  const handleClose = () => {
    setDialogOpen(false)
    setFormValid(true)
    setFormData({
      email: '',
    })
    setErrorMessage('')
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    setFormValid(true)

    if(data.get('email')===''){
      setFormValid(false)
      setErrorMessage(t('enter_email'))
    }

    if (!(data.get('email').includes('@'))){
      setFormValid(false)
      setErrorMessage(t('correct_email'))
    }

    setFormData({
      email: data.get('email'),
    });

  }

  useDidMountEffect(() => {
    if(formValid && formData.email !== ''){
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData)
      };
      
      fetch('https://api.futurekey.org/api/auth/forgot_password', requestOptions)
        .then(response => response.json())
        .then(data => {
          console.log(data)
          if(data.message==='Reset password has been sent to your email address.')  {
            setOpen(true)
            setTimeout(() => {
              setOpen(false)
              handleClose()
            }, 8000);
          }else{
            console.log(data)
            setFormValid(false)
            setErrorMessage(t('email_not_found'))
          }
        })
    }
   }, [formData])

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        sx={{zIndex: "1301"}}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {t('email_send1')}{formData.email}
            <br></br>
            ＊＊＊{t('email_send2')}＊＊＊
            <br></br>
            ({t('email_send3')})
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <Dialog open={dialogOpen} onClose={handleClose} fullWidth>
        <DialogTitle>{t('forget_btn')}</DialogTitle>
        <Box component="form" noValidate onSubmit={handleSubmit} >
          <DialogContent sx={{ py: 0 }}>
            <TextField
              margin="dense"
              placeholder={t('enter_email')}
              id="email"
              name="email"
              label="Email"
              type="email"
              fullWidth
              variant="outlined"
              helperText={ !formValid ? errorMessage : '' }
              error={!formValid}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>{t('cancel')}</Button>
            <Button variant="contained" type="submit">{t('submit')}</Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  )
}
