import React, { useState, useContext, useEffect } from 'react'
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Slide from '@mui/material/Slide';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import { OpenContext } from '../context/OpenContext'
import { UserContext } from '../context/UserContext'
import { AlertContext } from '../context/AlertContext'
import useDidMountEffect from '../hooks/useDidMountEffect'
import Autocomplete from '@mui/material/Autocomplete';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddUnitDialog({title}) {
  const { dialogOpen, setDialogOpen } = useContext(OpenContext)
  const { setAlertOpen } = useContext(AlertContext)
  const { userInfo } = useContext(UserContext)
  const [ errorMessage, setErrorMessage ] = useState('')
  const [ userArr, setUserArr ] = useState([])
  const [ user, setUser ] = useState('');
  const [ userValue, setUserValue ] = useState('')
  const [ inputUser, setInputUser ] = useState('')
  const [ siteArr, setSiteArr ] = useState([])
  const [ site, setSite ] = useState('');
  const [ siteValue, setSiteValue ] = useState('')
  const [ inputSite, setInputSite ] = useState('')
  const [ platformArr, setPlatformArr ] = useState([])
  const [ platform, setPlatform ] = useState('');
  const [ platformTypeArr, setPlatformTypeArr ] = useState([])
  const [ platformType, setPlatformType] = useState('');
  const [ name, setName] = useState('');
  const [ displayName, setDisplayName] = useState('');
  const [ currencyArr, setCurrencyArr ] = useState([])
  const [ currency, setCurrency] = useState('');
  const [ auto, setAuto ] = useState(true);
  const [ formData, setFormData ] = useState({
    user_id: '',
    website_id: '',
    platform_id: '',
    platform_type_id: '',
    currency_id: '',
    name: '',
    display_name: '',
    deduction: '',
    auto: true,
  })
  const [formValid, setFormValid] = useState(true)
  const [loading, setLoading] = useState(false)

  const handleClose = () => { 
    setDialogOpen({
      add: false,
      edit: false,
    })
    setFormData({
      user_id: '',
      website_id: '',
      platform_id: '',
      platform_type_id: '',
      currency_id: '',
      name: '',
      display_name: '',
      deduction: '',
      auto: true,
    })
    setUser('')
    setUserValue('')
    setInputUser('')
    setSite('')
    setInputSite('')
    setSiteValue('')
    setLoading(false)
    setFormValid(true)
    setPlatform('')
    setPlatformType('')
    setCurrency('')
    setName('')
    setDisplayName('')
    setAuto(true);
  }

  const handleTypeChange = (event) => {
    setPlatformType(event.target.value);
    if(platform===1){
      var type = platformTypeArr.filter((p)=>p.id===event.target.value)
      //console.log(type[0].name)
      setName(type[0].name)
      setDisplayName(type[0].name)
    }
  }

  const handleNameChange = (event) => {
    setName(event.target.value);
  }

  const handleDisplayNameChange = (event) => {
    setDisplayName(event.target.value);
  }

  const handleSiteChange = (event) => {
    setSite(event.target.value);
  }

  const handleCurrencyChange = (event) => {
    setCurrency(event.target.value);
  }

  const handleUserChange = (event) => {
    setUser(event.target.value);
    setSite('')
    setInputSite('')
    setSiteValue('')

    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userInfo.token},
    };
    
    fetch('https://api.futurekey.org/api/websites?status=true&per_page=500&page=1&user_id=' + event.target.value, requestOptions)
    .then(response => response.json())
    .then(data => {
      if(data.message==='OK'){
        let newSiteArr = data.data.websites.map((w) => {
          return {
            label: w.name,
            value: w.id,
          }
        })
        setSiteArr(newSiteArr)
      }else{
        console.log(data.message)
      }
    })
  };

  const handlePlatformChange = (event) => {
    setPlatform(event.target.value);
    setPlatformType('')
    
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userInfo.token},
    };

    fetch('https://api.futurekey.org/api/platforms/' + event.target.value + '/types', requestOptions)
    .then(response => response.json())
    .then(data => {
      if(data.message==='OK'){
        setPlatformTypeArr(data.data)
      }else{
        console.log(data.message)
      }
    })
  };
  
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    setLoading(true)
    setFormValid(true)

    if(data.get('display_name')==='' || data.get('deduction')==='' || user==='' || site==='' || platform==='' || platformType==='' || currency===''){
      setFormValid(false)
      setLoading(false)
      setErrorMessage('請確認所有必填欄位已填寫正確')
      return
    }

    if(platform===1||platform===2||platform===4){
      if(data.get('name')===''){
        setFormValid(false)
        setLoading(false)
        setErrorMessage('請填寫版位對應名稱')
        return
      }
    }

    var deduction = data.get('deduction').replace(/%/g, "")

    if(platform!==1&&platform!==2){
      setFormData({
        user_id: user,
        website_id: site,
        platform_id: platform,
        platform_type_id: platformType,
        currency_id: currency,
        name: data.get('display_name'),
        display_name: data.get('display_name'),
        deduction: deduction,
        status: true,
        dable_code: "",
        pmc_name: "",
        auto: auto===true?true:false,
      })
    }else{
      setFormData({
        user_id: user,
        website_id: site,
        platform_id: platform,
        platform_type_id: platformType,
        currency_id: currency,
        name: data.get('name'),
        display_name: data.get('display_name'),
        deduction: deduction,
        status: true,
        dable_code: "",
        pmc_name: "",
        auto: auto===true?true:false,
      })
    }

  }

  
  const handleAutoChange = (event) => {
    setAuto(event.target.value);
  };

  useDidMountEffect(() => {
    if(formValid && formData.name !== ''){
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userInfo.token },
        body: JSON.stringify(formData)
      };
      
      fetch('https://api.futurekey.org/api/ad_positions', requestOptions)
        .then(response => response.json())
        .then(data => {
          if(data.message==='OK')  {
            handleClose()
            setLoading(false)
            setAlertOpen({
              open: true,
              message: '新增成功'
            })
          }else{
            console.log(data)
            setLoading(false)
            setFormValid(false)
          }
        })
    }
   }, [formData])

  useEffect (() => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userInfo.token},
    };

    fetch('https://api.futurekey.org/api/users?status=true&role=User&per_page=500&page=1', requestOptions)
    .then(response => response.json())
    .then(data => {
      if(data.message==='OK'){
        let newUserArr = data.data.users.map((u) => {
          return {
            label: u.username,
            value: u.id,
          }
        })
        setUserArr(newUserArr)
      }else{
        console.log(data.message)
      }
    })

    fetch('https://api.futurekey.org/api/platforms?status=true&per_page=500&page=1', requestOptions)
    .then(response => response.json())
    .then(data => {
      if(data.message==='OK'){
        setPlatformArr(data.data.platforms)
      }else{
        console.log(data.message)
      }
    })

    fetch('https://api.futurekey.org/api/currencies', requestOptions)
    .then(response => response.json())
    .then(data => {
      if(data.message==='OK'){
        setCurrencyArr(data.data)
      }else{
        console.log(data.message)
      }
    })

  },[userInfo.token])

  return (
    <Dialog open={dialogOpen.add} onClose={handleClose} TransitionComponent={Transition}>
      <DialogTitle>
        {title}
        <IconButton aria-label="close" onClick={handleClose} sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500], }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Box component="form" noValidate onSubmit={handleSubmit} >
        <DialogContent sx={{ py: 0 }}>
          <Grid container sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
            <Grid item xs={6}>
            <Autocomplete
              disableClearable
              id="user"
              value={userValue}
              onChange={(event, newValue) => {
                setUserValue(newValue);
                handleUserChange(event);
              }}
              inputValue={inputUser}
              onInputChange={(event, newInputValue) => {
                setInputUser(newInputValue);
              }}
              options={userArr}
              sx={{ width: '95%' }}
              noOptionsText="無相關網站主"
              size="small"
              renderOption={(props, option) => {
                return (
                  <li {...props} value={option.value}>
                    {option.label}
                  </li>
                );
              }}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              renderInput={(params) => <TextField size="small" variant='standard' name="user" {...params} label="網站主" />}
            />
              {/* <FormControl error={!formValid} required variant='standard' margin="dense" sx={{ width: '95%' }}>
                <InputLabel id="user-select-helper-label">網站主</InputLabel>
                <Select
                  labelId="user-select-label"
                  id="user_select"
                  name="user_select"
                  label="網站主"
                  value={user}
                  onChange={handleUserChange}
                >
                  {
                    userArr && 
                    userArr.map((u, index) => (
                      <MenuItem key={u.id} value={u.id}>{u.username}</MenuItem>
                    ))
                  }
                </Select>
              </FormControl> */}
            </Grid>
            <Grid item xs={6}>
            <Autocomplete
              disabled={user===''}
              disableClearable
              id="site"
              value={siteValue}
              onChange={(event, newValue) => {
                setSiteValue(newValue);
                handleSiteChange(event);
              }}
              inputValue={inputSite}
              onInputChange={(event, newInputValue) => {
                setInputSite(newInputValue);
              }}
              options={siteArr}
              sx={{ width: '95%' }}
              noOptionsText="無相關網站"
              size="small"
              renderOption={(props, option) => {
                return (
                  <li {...props} value={option.value}>
                    {option.label}
                  </li>
                );
              }}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              renderInput={(params) => <TextField size="small" variant='standard' name="site" {...params} label="網站" />}
            />
              {/* <FormControl disabled={user===''} error={!formValid} required variant='standard' margin="dense" sx={{ width: '95%' }}>
                <InputLabel id="site-select-helper-label">網站</InputLabel>
                <Select
                  labelId="site-select-label"
                  id="site_select"
                  name="site_select"
                  label="網站"
                  value={site}
                  onChange={handleSiteChange}
                >
                  {
                    siteArr && 
                    siteArr.map((s, index) => (
                      <MenuItem key={s.id} value={s.id}>{s.name}</MenuItem>
                    ))
                  }
                </Select>
              </FormControl> */}
            </Grid>
          </Grid>
          <Grid container sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
            <Grid item xs={6}>
              <FormControl error={!formValid} required variant='standard' margin="dense" sx={{ width: '95%' }}>
                <InputLabel id="platform-select-helper-label">廣告平台</InputLabel>
                <Select
                  labelId="platform-select-label"
                  id="platform_select"
                  name="platform_select"
                  label="廣告平台"
                  value={platform}
                  onChange={handlePlatformChange}
                >
                  {
                    platformArr && 
                    platformArr.map((p, index) => (
                      <MenuItem key={p.id} value={p.id}>{p.name}</MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl disabled={platform===''} error={!formValid} required variant='standard' margin="dense" sx={{ width: '95%' }}>
                <InputLabel id="type-select-helper-label">廣告類型</InputLabel>
                <Select
                  labelId="type-select-label"
                  id="type_select"
                  name="type_select"
                  label="廣告類型"
                  value={platformType}
                  onChange={handleTypeChange}
                >
                  {
                    platformTypeArr && 
                    platformTypeArr.map((t, index) => (
                      <MenuItem key={t.id} value={t.id}>{t.name}</MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
            <Grid item xs={12}>
              <TextField
                disabled={(platform!==1 && platform!==2 && platform!==4) || !platform}
                value={name}
                onChange={handleNameChange}
                margin="dense"
                id="name"
                name="name"
                label="版位對應名稱"
                sx={{ width: '100%' }}
                type="text"
                variant="standard"
              />
            </Grid>
          </Grid>
          <Grid container sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
            <Grid item xs={6}>
              <TextField
                margin="dense"
                value={displayName}
                onChange={handleDisplayNameChange}
                id="display_name"
                name="display_name"
                label="廣告版位顯示名稱"
                sx={{ width: '95%' }}
                type="text"
                variant="standard"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin="dense"
                id="deduction"
                name="deduction"
                label="扣量"
                sx={{ width: '95%' }}
                type="num"
                variant="standard"
              />
            </Grid>
          </Grid>
          <Grid container sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
            <Grid item xs={6}>
            <FormControl error={!formValid} required variant='standard' margin="dense" fullWidth >
              <InputLabel id="currency-select-helper-label">輸入貨幣</InputLabel>
              <Select
                sx={{ width: '95%' }}
                labelId="currency-select-label"
                id="currency_select"
                name="currency_select"
                label="輸入貨幣"
                value={currency}
                onChange={handleCurrencyChange}
              >
                {
                  currencyArr && 
                  currencyArr.map((c, index) => (
                    <MenuItem key={c.id} value={c.id}>{c.name}{c.code}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
            </Grid>
            <Grid item xs={6}>
            <FormControl>
              <FormLabel id="auto">是否需要自動抓取數據</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="auto"
                  name="auto"
                  value={auto}
                  onChange={handleAutoChange}
                >
                  <FormControlLabel value="true" control={<Radio />} label="是" />
                  <FormControlLabel value="false" control={<Radio />} label="否" />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
          {
            !formValid &&
            <Typography component="p" variant="body2" gutterBottom fontWeight={400} color="error">
              {errorMessage}
            </Typography>
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>取消</Button>
          {
            loading &&
            <LoadingButton
              loading
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="outlined"
            >
              處理中
            </LoadingButton>
          }
          {
            !loading &&
            <Button variant="contained" type="submit">儲存</Button>
          }
        </DialogActions>
      </Box>
    </Dialog>
  )
}
