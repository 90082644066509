import React, { useState, useContext, useEffect } from 'react'
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import TabPanel from '@mui/lab/TabPanel';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Slide from '@mui/material/Slide';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import EditAccountDialog from '../components/EditAccountDialog'
import AddAccountDialog from '../components/AddAccountDialog'
import { OpenContext } from '../context/OpenContext'
import { UserContext } from '../context/UserContext'
import useDidMountEffect from "../hooks/useDidMountEffect";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function UserDetailDialog({title, propData}) {
  const { dialogOpen, setDialogOpen } = useContext(OpenContext)
  const { userInfo } = useContext(UserContext)
  const [ subDialogOpen, setSubDialogOpen ] = useState({
    editAccount: false,
    addAccount: false
  })
  const [ value, setValue ] = useState("0");
  const [ dialogTitle, setDialogTitle ] = useState('')
  const [ data, setData ] = useState(propData) 

  const handleClose = () => { 
    setDialogOpen({
      add: false,
      view: false,
      edit: false,
    })
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(()=>{
    setData(propData)
  },[propData])

  const handleEditAccount = () =>{
    setSubDialogOpen({
      editAccount: true,
      addAccount: false
    })
    setDialogTitle('編輯匯款資料')
  }
  const handleAddAccount = () =>{
    setSubDialogOpen({
      editAccount: false,
      addAccount: true
    })
    setDialogTitle('新增匯款資料')
  }

  useDidMountEffect(()=>{
    if(!subDialogOpen.addAccount && !subDialogOpen.editAccount){
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userInfo.token }
      };
  
      fetch('https://api.futurekey.org/api/users/'+propData.id, requestOptions)
      .then(response => response.json())
      .then(data => {
        if(data.message==='OK')  {
          setData(data.data)
        }else{
          console.log(data)
        }
      })
    }
  },[subDialogOpen])

  return (
    <Dialog open={dialogOpen.view} onClose={handleClose} fullWidth={true} TransitionComponent={Transition} maxWidth="md">
      <DialogTitle>
        {title}
        <IconButton aria-label="close" onClick={handleClose} sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500], }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ py: 0 }}>
      {
        data.bank_accounts && data.bank_accounts.length > 0 &&
          <TabContext value={""+value+""}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange} aria-label="accounts">
              {
                data.bank_accounts.map((bank_account, index)=>(
                  <Tab key={bank_account.currency.code} label={bank_account.currency.name} value={""+index+""} />
                ))
              }
              </TabList>
            </Box>
          {
            data.bank_accounts.map((bank_account, index)=>(
              <TabPanel key={bank_account.currency.code} sx={{p: "0px",pb :"16px"}} value={""+index+""}>
                {
                  (bank_account.currency.id===1) &&
                    <TableContainer >
                      <Table aria-label="table">
                        <TableBody>
                          <TableRow>
                            <TableCell style={{ width: '30%' }} component="td">
                              銀行戶名
                            </TableCell>
                            <TableCell style={{ width: '70%' }} component="td">
                              {bank_account.account_name}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ width: '30%' }} component="td">
                              銀行匯款帳號
                            </TableCell>
                            <TableCell style={{ width: '70%' }} component="td">
                              {bank_account.account}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ width: '30%' }} component="td">
                              銀行代碼
                            </TableCell>
                            <TableCell style={{ width: '70%' }} component="td">
                              {bank_account.bank_code}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ width: '30%' }} component="td">
                              銀行名稱
                            </TableCell>
                            <TableCell style={{ width: '70%' }} component="td">
                              {bank_account.bank_name}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ width: '30%' }} component="td">
                              備註
                            </TableCell>
                            <TableCell style={{ width: '70%' }} component="td">
                              {bank_account.note}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                }
                {
                  (bank_account.currency.id===2) &&
                    <TableContainer xs={{p:"0px"}}>
                      <Table aria-label="table">
                        <TableBody>
                          <TableRow>
                            <TableCell style={{ width: '30%' }} component="td">
                              Account Name
                            </TableCell>
                            <TableCell style={{ width: '70%' }} component="td">
                              {bank_account.account_name}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ width: '30%' }} component="td">
                              Account Number
                            </TableCell>
                            <TableCell style={{ width: '70%' }} component="td">
                              {bank_account.account}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ width: '30%' }} component="td">
                              Swift Code
                            </TableCell>
                            <TableCell style={{ width: '70%' }} component="td">
                              {bank_account.swift_code}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ width: '30%' }} component="td">
                              Bank Name
                            </TableCell>
                            <TableCell style={{ width: '70%' }} component="td">
                              {bank_account.bank_name}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ width: '30%' }} component="td">
                              Note
                            </TableCell>
                            <TableCell style={{ width: '70%' }} component="td">
                              {bank_account.note}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                }
                {
                  (bank_account.currency.id===3) &&
                    <TableContainer xs={{p:"0px"}}>
                      <Table aria-label="table">
                        <TableBody>
                          <TableRow>
                            <TableCell style={{ width: '30%' }} component="td">
                              銀行戶名
                            </TableCell>
                            <TableCell style={{ width: '70%' }} component="td">
                              {bank_account.account_name}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ width: '30%' }} component="td">
                              銀行匯款帳號
                            </TableCell>
                            <TableCell style={{ width: '70%' }} component="td">
                              {bank_account.account}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ width: '30%' }} component="td">
                              銀行代碼
                            </TableCell>
                            <TableCell style={{ width: '70%' }} component="td">
                              {bank_account.bank_code}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ width: '30%' }} component="td">
                              銀行名稱
                            </TableCell>
                            <TableCell style={{ width: '70%' }} component="td">
                              {bank_account.bank_name}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ width: '30%' }} component="td">
                              銀行所在地(省份)
                            </TableCell>
                            <TableCell style={{ width: '70%' }} component="td">
                              {bank_account.bank_location}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ width: '30%' }} component="td">
                              支行
                            </TableCell>
                            <TableCell style={{ width: '70%' }} component="td">
                              {bank_account.bank_branch}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ width: '30%' }} component="td">
                              Note
                            </TableCell>
                            <TableCell style={{ width: '70%' }} component="td">
                              {bank_account.note}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                }
              </TabPanel>
            ))
          }
          </TabContext>
      }
      <Button variant="contained" sx={{mt:2, mb:2}} startIcon={<AddIcon />} onClick={handleAddAccount}>
        新增匯款資料
      </Button>
      {
        data.bank_accounts && data.bank_accounts.length > 0 &&
        <Button variant="contained" sx={{mt:2,ml:2,mb:2}} startIcon={<EditIcon />} onClick={handleEditAccount}>
          編輯匯款資料
        </Button>
      }
      </DialogContent>
      <OpenContext.Provider value={{subDialogOpen, setSubDialogOpen}}>
        {
          data.bank_accounts && data.bank_accounts.length>0 &&
          <EditAccountDialog title={dialogTitle} existAccount={data.bank_accounts ? data.bank_accounts[value] : {}} userId={data ? data.id : 0} />
        }
        <AddAccountDialog title={dialogTitle} userId={data ? data.id : 0}/>
      </OpenContext.Provider>
    </Dialog>
  )
}