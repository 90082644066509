import React, { useEffect, useState } from 'react'
import Chart from "react-apexcharts";

export default function ReportChart({data}) {
  const [ options, setOptions ] = useState({})
  const [ series, setSeries ] = useState([])
  
  useEffect (() => {
    var clicksArr = []
    var impressionsArr = []
    var ctrArr = []
    var incomeArr = []
    var dateArr = []
    // eslint-disable-next-line array-callback-return
    data.map((d) => {
      clicksArr.push(parseInt(d.clicks))
      impressionsArr.push(parseInt(d.impressions))
      ctrArr.push(parseFloat(d.ctr))
      incomeArr.push(parseFloat(d.final_revenue))
      dateArr.push(d.date)
    })

    var options = {
      chart: {
        height: 100,
        type: "line",
        stacked: false,
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          columnWidth: '60%',
        }
      },
      markers: {
        size: 4,
        colors: '#004182',
        strokeColors: '#004182',
      },
      stroke: {
        width: 3
      },
      dataLabels: {
        enabled: false
      },
      colors: ['#6BCB77','#ffb830' , '#004182', '#11BDF0'],
      xaxis: {
        categories: dateArr
      },
      yaxis: [
        {
          seriesName: '點擊',
          labels: {
            formatter: (value) => { return value.toFixed(0) }
          },
          axisTicks: {
            show: true
          },
          axisBorder: {
            show: true,
          },
          title: {
            text: "點擊"
          }
        },
        {
          seriesName: '曝光',
          labels: {
            formatter: (value) => { return value.toFixed(0) }
          },
          axisTicks: {
            show: true
          },
          axisBorder: {
            show: true,
          },
          title: {
            text: "曝光"
          }
        },
        {
          opposite: true,
          decimalsInFloat: 2,
          seriesName: 'CTR',
          logBase: 1,
          axisTicks: {
            show: true
          },
          axisBorder: {
            show: true,
          },
          title: {
            text: "CTR"
          },
        },
        {
          opposite: true,
          decimalsInFloat: 0,
          seriesName: '預估收益',
          axisTicks: {
            show: true
          },
          axisBorder: {
            show: true,
          },
          title: {
            text: "預估收益"
          },
          style: {
            cssClass: "chartLabel"
          }
        },
      ]
    }

    var series = [
      {
        name: "點擊",
        type: 'column',
        data: clicksArr
      },
      {
        name: "曝光",
        type: 'column',
        data: impressionsArr
      },
      {
        name: "CTR",
        type: 'line',
        data: ctrArr,
      },
      {
        name: "預估收益",
        type: 'column',
        data: incomeArr
      },
    ]

    setOptions(options)
    setSeries(series)


  },[data]) 

  return (
    <Chart
      options={options}
      series={series}
      width="100%"
    />
  )
}
