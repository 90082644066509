import React from 'react'
import { useTranslation } from 'react-i18next';

import {useNavigate, useSearchParams} from 'react-router-dom';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';
import { Dialog, Slide, DialogContent, DialogContentText } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from './theme'
import { useState } from 'react'
import useDidMountEffect from "./hooks/useDidMountEffect";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ResetPsd() {
  const { t } = useTranslation();

  const [ open, setOpen ] = React.useState(false);
  const [ formDate, setFormData ] = useState({
    email: '',
    token: '',
    password: ''
  });
  const [ errorMessage, setErrorMessage ] = useState('')
  const [ formValid, setFormValid ] = useState(true)

  const [searchParams] = useSearchParams();
  

  const navigate = useNavigate();

  const handleClose = () => {
    navigate('/login')
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    setFormValid(true)

    var regNumber = /\d+/;
    var regString = /[a-zA-Z]+/; 

    if(data.get('password')==='' || data.get('password_confirm')===''){
      setFormValid(false)
      setErrorMessage(t('password_rule3'))
      return
    }

    if(data.get('password') !== data.get('password_confirm')){
      setFormValid(false)
      setErrorMessage(t('password_rule2'))
      return
    }

    if(!regNumber.test(data.get('password')) || !regString.test(data.get('password')) ){
      setFormValid(false)
      setErrorMessage(t('password_rule1'))
      return
    }

    setFormData({
      email: searchParams.get("email"),
      token: searchParams.get("token"),
      password: data.get('password'),
    });

  };

  useDidMountEffect(() => {
    if(formValid && formDate.email!==''){
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formDate)
      };
      
      fetch('https://api.futurekey.org/api/auth/reset_password', requestOptions)
        .then(response => response.json())
        .then(data => {
          if(data.message==='Your password has been reset successfully.')  {
            setOpen(true)
            setTimeout(() => {
              handleClose()
            }, 3000);
          }else if(data.message==='Your password has been reset failed, please resend forgot password request.'){
            setFormValid(false)
            setErrorMessage(t('reset_done'))
          }else{
            console.log(data)
            setFormValid(false)
          }
        })
    }
   }, [formDate])

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        sx={{zIndex: "1301"}}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {t('reset1')}
            <br></br>
            {t('reset2')}
            <br></br>
            ({t('reset3')})
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <CssBaseline />
        <Grid item xs={false} sm={12} md={6}>
          <Box 
            sx={{
              py:10,
              px:10,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%'
            }}
          >
            <img style={{width: '100%'}} src={process.env.PUBLIC_URL+'/images/Logox2.png'} alt='Logox2.png'/>
          </Box>
          
        </Grid>
        <Grid item xs={12} sm={12} md={6} component={Paper} elevation={6} square>
          <Box
            sx={{
              py:10,
              px:10,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%'
            }}
          >
            <Typography component="h1" variant="h5" fontWeight="700">
              {t('reset_password')}
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 4 }}>
              <TextField
                margin="normal"
                fullWidth
                label={t("enter_password")}
                name="password"
                type="password"
                id="password"
                error={!formValid}
              />
              <TextField
                margin="normal"
                fullWidth
                label={t("enter_password1")}
                name="password_confirm"
                type="password"
                id="password_confirm"
                helperText={!formValid ? errorMessage : ""}
                error={!formValid}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                className="login_btn"
              >
                {t('submit')}
              </Button>
            </Box>
            <Button variant='text' onClick={handleClose}>{t('back_to_login')}</Button>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  )
}
