import React, { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next';

import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { OpenContext } from '../context/OpenContext'
import { UserContext } from '../context/UserContext'
import { AlertContext } from '../context/AlertContext'
import useDidMountEffect from "../hooks/useDidMountEffect";
import { LoadingButton } from '@mui/lab';

export default function AddAccountDialog({title, userId}) {
  const { t } = useTranslation();

  // console.log(existAccount)

  const {dialogOpen, setDialogOpen} = useContext(OpenContext)
  const {subDialogOpen, setSubDialogOpen} = useContext(OpenContext)
  const { userInfo } = useContext(UserContext)
  const { setAlertOpen } = useContext(AlertContext)
  const [errorMessage, setErrorMessage] = useState('')
  const [formData, setFormData] = useState({
    currency_id: "",
    account_name: "",
    account: "",
    bank_name: "",
    bank_code: "",
    bank_location: "",
    swift_code: "",
    bank_branch: "",
    note: ''
  })
  const [formValid, setFormValid] = useState(true)
  const [currencyArr, setCurrencyArr] = useState([])
  const [currency, setCurrency] = useState('');
  const [loading, setLoading] = useState(false)

  const handleChange = (event) => {
    setCurrency(event.target.value);
  };

  const handleClose = () => {
    if(dialogOpen){
    setDialogOpen({
        changePwd: false,
        editInfo: false,
        editAccount: false,
        addAccount: false
      })
    }
    if(subDialogOpen){
      setSubDialogOpen({
        editAccount: false,
        addAccount: false
      })
    }
    setFormValid(true)
    setFormData({
      currency_id: "",
      account_name: "",
      account: "",
      bank_name: "",
      bank_code: "",
      bank_location: "",
      swift_code: "",
      bank_branch: "",
      note: ''
    })
    setCurrency('');
    setErrorMessage('');
  };

  useEffect (() => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userInfo.token},
    };

    fetch('https://api.futurekey.org/api/currencies', requestOptions)
    .then(response => response.json())
    .then(data => {
      if(data.message==='OK'){
        setCurrencyArr(data.data)
      }else{
        console.log(data.message)
      }
    })
  },[userInfo.token])

  // useDidMountEffect (() => {
  //   console.log(currencyArr)
  // },[currencyArr])

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    setFormValid(true)
    setLoading(true)

    if(data.get('currency_id')===''){
      setFormValid(false)
      setErrorMessage(t("account_currency_text"))
      return
    }

    if(data.get('account_name')==='' || data.get('account')==='' || data.get('bank_name')===''){
      setFormValid(false)
      setErrorMessage(t('password_rule3'))
      return
    }

    if(data.get('currency_id')==="2" && data.get('swift_code')===''){
      setFormValid(false)
      setErrorMessage(t('password_rule3'))
      return
    }

    if(data.get('currency_id')==="1" && data.get('bank_code')===''){
      setFormValid(false)
      setErrorMessage(t('password_rule3'))
      return
    }
    
    
    if(data.get('currency_id')==="3" && (data.get('bank_branch')==='' || data.get('bank_location')==='')){
      setFormValid(false)
      setErrorMessage(t('password_rule3'))
      return
    }

    setFormData({
      currency_id: data.get('currency_id'),
      account_name: data.get('account_name'),
      account: data.get('account'),
      bank_name: data.get('bank_name'),
      bank_code: data.get('bank_code'),
      bank_location: data.get('bank_location'),
      swift_code: data.get('swift_code'),
      bank_branch: data.get('bank_branch'),
      note: data.get('note')
    });

  }

  useDidMountEffect(() => {
    if(formValid && formData.currency_id !== ''){
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userInfo.token },
        body: JSON.stringify(formData)
      };
      
      fetch('https://api.futurekey.org/api/users/'+userId+'/bank_accounts', requestOptions)
        .then(response => response.json())
        .then(data => {
          if(data.message==='OK')  {
            setLoading(false)
            setAlertOpen(true)
            handleClose()
          }else{
            console.log(data)
            setFormValid(false)
          }
        })
    }
   }, [formData,userInfo.token])

  return (
    <Dialog open={dialogOpen?dialogOpen.addAccount:subDialogOpen.addAccount} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <Box component="form" noValidate onSubmit={handleSubmit} >
        <DialogContent>
          <FormControl variant="standard" sx={{ width: "100%" }}>
            <InputLabel error={!formValid} name="currency_id" required id="demo-simple-select-standard-label">{t("account_currency")}</InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              name="currency_id"
              value={currency}
              onChange={handleChange}
              label={t("account_currency")}
              error={!formValid}
            >
              {
                currencyArr.map(c => (
                  <MenuItem key={c.code} value={c.id}>{c.code} {c.name}</MenuItem>
                ))
              }
            </Select>
          </FormControl>
          <TextField
            required
            disabled={currency===''}
            margin="dense"
            id="account_name"
            name="account_name"
            label={currency===2 ? "Account Name" : t("bank_account_name")}
            type="text"
            fullWidth
            variant="standard"
            error={!formValid}
          />
          <TextField
            required
            disabled={currency===''}
            margin="dense"
            id="account"
            name="account"
            label={currency===2 ? "Account Number" : t("bank_account")}
            type="text"
            fullWidth
            variant="standard"
            error={!formValid}
          />
          <TextField
            required
            disabled={currency===''}
            margin="dense"
            id={currency===2 ? "swift_code" : "bank_code"}
            name={currency===2 ? "swift_code" : "bank_code"}
            label={currency===2 ? "Swift Code" : t("bank_code")}
            type="text"
            fullWidth
            variant="standard"
            error={!formValid}
          />
          <TextField
            required
            disabled={currency===''}
            margin="dense"
            id="bank_name"
            name="bank_name"
            label={currency===2 ? "Bank Name" : t("bank_name")}
            type="text"
            fullWidth
            variant="standard"
            error={!formValid}
          />
          {
            currency===3 &&
            <TextField
              required
              disabled={currency===''}
              margin="dense"
              id="bank_location"
              name="bank_location"
              label={t("bank_location")}
              type="text"
              fullWidth
              variant="standard"
              error={!formValid}
            />
          }
          {
            currency===3 &&
            <TextField
              required
              disabled={currency===''}
              margin="dense"
              id="bank_branch"
              name="bank_branch"
              label={t("bank_branch")}
              type="text"
              fullWidth
              variant="standard"
              error={!formValid}
            />
          }
          <TextField
            disabled={currency===''}
            margin="dense"
            id="note"
            name="note"
            label={currency===2 ? "Note" : t('note')}
            type="text"
            fullWidth
            variant="standard"
            error={!formValid}
            helperText={!formValid ? errorMessage : ""}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('cancel')}</Button>
          {loading
            ?
            <LoadingButton loading variant="contained">
              {t('submit')}
            </LoadingButton>
            :
            <Button variant="contained" type="submit">{t('submit')}</Button>
          }
        </DialogActions>
      </Box>
    </Dialog>
  )
}
