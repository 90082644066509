import React, { useState, useContext, useEffect } from 'react'
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TextField from '@mui/material/TextField';
import TableRow from '@mui/material/TableRow';
import Slide from '@mui/material/Slide';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import CircularProgress from '@mui/material/CircularProgress';
import { OpenContext } from '../context/OpenContext'
import { UserContext } from '../context/UserContext'
import { AlertContext } from '../context/AlertContext'
import useDidMountEffect from "../hooks/useDidMountEffect";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PlatformTypeDialog({title, id}) {
  const { userInfo } = useContext(UserContext);
  const { dialogOpen, setDialogOpen } = useContext(OpenContext)
  const { setAlertOpen } = useContext(AlertContext)
  const [ typeArr, setTypeArr] = useState([]);
  const [ loadingD, setLoadingD] = useState(false);
  const [ loading, setLoading] = useState(false);
  const [ buttonLoading, setButtonLoading] = useState(false);
  const [ saveLoading, setSaveLoading] = useState(false);
  const [ open, setOpen] = useState(false);
  const [ editType, setEditType] = useState('');
  const [ value, setValue] = useState('');
  const [ newValue, setNewValue] = useState('');
  const [ deleteType, setDeleteType] = useState('');
  const [ formValid, setFormValid] = useState(true);
  const [ textValid, setTextValid] = useState(true);
  const [ formData, setFormData ] = useState({
    name: '', 
    status:true
  })

  const handleConfirmClose = () => {
    setOpen(false)
  }

  const handleClose = () => { 
    setDialogOpen({
      add: false,
      type: false,
      edit: false,
    })
    setButtonLoading(false)
    setFormValid(true)
  }

  const handleDelete = () => {
    setLoadingD(true)
    var requestOptions = {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userInfo.token},
    };

    fetch('https://api.futurekey.org/api/platforms/'+ id + '/types/' + deleteType, requestOptions)
    .then(response => response.json())
    .then(data => {
      if(data.message==='OK'){
        setAlertOpen({
          open: true,
          message: '類型已刪除'
        })
        setLoadingD(false)
        handleConfirmClose()
        setDeleteType('')
      }else{
        setLoadingD(false)
        console.log(data.message)
      }
    })
  }

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleAddChange = (event) => {
    setNewValue(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setButtonLoading(true)
    setFormValid(true)

    if( newValue===''){
      setFormValid(false)
      setButtonLoading(false)
    }else{
      setFormData({
        name: newValue, 
        status:true
      })  
    }
  }

  useEffect (() => {
    if(formData.name!=='' && editType===''){
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userInfo.token},
        body: JSON.stringify(formData)
      };

      fetch('https://api.futurekey.org/api/platforms/'+ id +'/types', requestOptions)
      .then(response => response.json())
      .then(data => {
        if(data.message==='OK'){
          setButtonLoading(false)
          setAlertOpen({
            open: true,
            message: '新增成功'
          })
          setFormData({
            name: '', 
            status:true
          })
          setNewValue('')
        }else{
          setButtonLoading(false)
          console.log(data.message)
        }
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[formData])

  const handleSave = () => {
    setSaveLoading(true)
    setTextValid(true)

    if( value===''){
      setTextValid(false)
      setSaveLoading(false)
    }else{
      setFormData({
        name: value, 
        status:true
      })  
    }
  }

  useEffect (() => {
    if(formData.name!=='' && editType!==''){
      const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userInfo.token},
        body: JSON.stringify(formData)
      };

      fetch('https://api.futurekey.org/api/platforms/'+ id +'/types/' + editType, requestOptions)
      .then(response => response.json())
      .then(data => {
        if(data.message==='OK'){
          setSaveLoading(false)
          setAlertOpen({
            open: true,
            message: '變更成功'
          })
          setFormData({
            name: '', 
            status:true
          }) 
          setEditType('')
          setValue('')
        }else{
          setSaveLoading(false)
          console.log(data.message)
        }
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[formData])

  useDidMountEffect(() => {
    if(!buttonLoading && !saveLoading && !loadingD){
      setLoading(true)
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userInfo.token},
      };

      fetch('https://api.futurekey.org/api/platforms/'+ id +'/types', requestOptions)
      .then(response => response.json())
      .then(data => {
        if(data.message==='OK'){
          setLoading(false)
          setTypeArr(data.data)
        }else{
          setLoading(false)
          console.log(data.message)
        }
      })
      setTimeout(() => {
        setAlertOpen({
          open: false,
          message: ''
        })
      }, 3000);
    }
  },[id, buttonLoading, saveLoading, loadingD])

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClick={handleConfirmClose}
        aria-describedby="alert-dialog-slide-description"
        sx={{zIndex: "1301"}}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            確定要刪除類型嗎？
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{justifyContent: "space-between"}}>
          <Button onClick={handleConfirmClose}>取消</Button>
          {
            loadingD &&
            <LoadingButton
              loading
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="outlined"
            >
              處理中
            </LoadingButton>
          }
          {
            !loadingD &&
            <Button onClick={handleDelete}>確定</Button>
          }
        </DialogActions>
      </Dialog>
      <Dialog open={dialogOpen.type} onClose={handleClose} fullWidth={true} TransitionComponent={Transition} maxWidth="xs">
        <DialogTitle>
          {title}
          <IconButton aria-label="close" onClick={handleClose} sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500], }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ py: 0, pb: 3 }}>
          <Box component="form" noValidate onSubmit={handleSubmit}>
            <Grid container sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
              <Grid item xs={8}>
                <TextField
                  margin="dense"
                  value={newValue}
                  onChange={handleAddChange}
                  id="name"
                  name="name"
                  label="新增類型名稱"
                  type="text"
                  fullWidth
                  size="small"
                  error={!formValid}
                  helperText={!formValid ? "請填寫類型名稱" : ''}
                />
              </Grid>
              <Grid item xs={4} sx={{ textAlign: "right" }}>
                {
                  buttonLoading &&
                  <LoadingButton
                    loading
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="outlined"
                  >
                    處理中
                  </LoadingButton>
                }
                {
                  !buttonLoading &&
                  <Button variant="contained" type="submit">儲存</Button>
                }
              </Grid>
            </Grid>
          </Box>
          <TableContainer>
            <Table size="small" aria-label="table">
              <TableHead>
                <TableRow>
                  <TableCell align="center" style={{ minWidth: 100}}>名稱</TableCell>
                  <TableCell align="center" style={{ minWidth: 220 }}>操作</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  loading 
                  ? 
                  <TableRow>
                    <TableCell align="center" colSpan={8}>
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                  :
                  (
                    typeArr.length === 0 ?
                    <TableRow>
                      <TableCell align="center" colSpan={8}>
                        無相關資料
                      </TableCell>
                    </TableRow>
                  :
                  typeArr.length > 0 &&
                  typeArr.map((row, index) => (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      <TableCell align="center">
                      { 
                        editType!==row.id
                        ?
                          row.name
                        :
                          <TextField
                            value={value}
                            onChange={handleChange}
                            margin="dense"
                            id="editname"
                            name="editname"
                            label="編輯類型名稱"
                            type="text"
                            fullWidth
                            size="small"
                            error={!textValid}
                            helperText={!textValid ? "請填寫類型名稱" : ''}
                          />
                      }
                      </TableCell>
                      <TableCell align="center"> 
                        {
                          editType!==row.id
                          ? <Button onClick={()=>{
                                setEditType(row.id)
                                setValue(row.name)
                              }}variant="outlined" sx={{ mr: 2 }} startIcon={<EditIcon />}>
                              編輯
                            </Button>
                          :
                            (saveLoading ?
                              <LoadingButton
                                loading
                                loadingPosition="start"
                                startIcon={<SaveIcon />}
                                variant="outlined"
                              >
                                處理中
                              </LoadingButton>
                            :
                              <Button onClick={handleSave} variant="outlined" color='success' sx={{ mr: 2 }} startIcon={<SaveIcon />}>儲存</Button>
                            )
                        }   
                        <Button onClick={() => {
                            setDeleteType(row.id)
                            setOpen(true)
                          }} variant="outlined" color='error' startIcon={<DeleteOutlineIcon />}>刪除</Button>
                      </TableCell>
                    </TableRow>
                  ))
                  )
                }
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
    </>
  )
}