import React from 'react'
import {useNavigate} from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from './theme'
import { useState, useContext } from 'react'
import { UserContext } from "./context/UserContext"
import { OpenContext } from "./context/OpenContext"
import useDidMountEffect from "./hooks/useDidMountEffect";
import ForgetPwdDialog from './components/ForgetPwdDialog';

export default function Login() {
  const { t, i18n } = useTranslation();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  const [ open, setOpen ] = React.useState(false);
  const { setUserInfo } = useContext(UserContext);
  const [ dialogOpen, setDialogOpen ] = useState(false)

  const navigate = useNavigate();
  let [loginInfo, setLoginInfo] = useState({
    username: '',
    password: '',
  })
  let [formValid, setFormValid] = useState(true)

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    if(data.get('username')===''){
      setFormValid(false)
    }else{
      setFormValid(true)
    }
    if(data.get('password')===''){
      setFormValid(false)
    }else{
      setFormValid(true)
    }

    setLoginInfo({
      username: data.get('username'),
      password: data.get('password'),
    });

  };

  useDidMountEffect(() => {
    if(formValid){
      setOpen(true);
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(loginInfo)
      };
      
      fetch('https://api.futurekey.org/api/auth/login', requestOptions)
        .then(response => response.json())
        .then(data => {
          if(data.message==='OK')  {
            let userData = data.data
            sessionStorage.setItem('token', userData.token)
            sessionStorage.setItem('email', userData.email)
            sessionStorage.setItem('name', userData.name)
            sessionStorage.setItem('username', userData.username)
            sessionStorage.setItem('role', userData.role)
            sessionStorage.setItem('last_ip', userData.last_ip)
            sessionStorage.setItem('last_date', userData.last_date)
            sessionStorage.setItem('id', userData.id)
            setUserInfo({
              token: sessionStorage.getItem('token'),
              username: sessionStorage.getItem('username'),
              name: sessionStorage.getItem('name'),
              role: sessionStorage.getItem('role'),
              last_ip: sessionStorage.getItem('last_ip'),
              last_date: sessionStorage.getItem('last_date'),
              email: sessionStorage.getItem('email'),
              id: sessionStorage.getItem('id'),
              is_login: true
            })
            setTimeout(() => {
              navigate('/')
            }, 1500);
          }else{
            setOpen(false);
            console.log(data)
            setFormValid(false)
          }
        })
    }
   }, [loginInfo])

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <CssBaseline />
        <Grid item xs={false} sm={12} md={6}>
          <Box 
            sx={{
              py:10,
              px:10,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%'
            }}
          >
            <img style={{width: '100%'}} src={process.env.PUBLIC_URL+'/images/Logox2.png'} alt='Logox2.png'/>
          </Box>
          
        </Grid>
        <Grid item xs={12} sm={12} md={6} component={Paper} elevation={6} square>
          <Box
            sx={{
              py:10,
              px:10,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%'
            }}
          >
            <Typography component="h1" variant="h5" fontWeight="700">
            {t('welcome_message')}
            </Typography>
            {i18n.language === 'zh' || i18n.language === 'zh-TW'
              ? <Button className="switchBtn" variant="outlined" onClick={() => changeLanguage('en')}>Switch to English</Button>
              : <Button className="switchBtn" variant="outlined" onClick={() => changeLanguage('zh')}>切换到中文</Button>
            }
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 4 }}>
              <TextField
                margin="normal"
                fullWidth
                id="username"
                label="Username"
                name="username"
                autoComplete="username"
                autoFocus
                helperText={ !formValid ? t('username_error') : "" }
                error={!formValid}
              />
              <TextField
                margin="normal"
                fullWidth
                label="Password"
                name="password"
                type="password"
                id="password"
                autoComplete="current-password"
                helperText={!formValid ? t('password_error') : ""}
                error={!formValid}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                className="login_btn"
              >
                {t('login_btn')}
              </Button>
              <Grid container>
                <Grid item xs>
                  <Button variant='text' onClick={()=>setDialogOpen(true)}>{t('forget_btn')}</Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <OpenContext.Provider value={{dialogOpen, setDialogOpen}}>
        <ForgetPwdDialog />
      </OpenContext.Provider>
    </ThemeProvider>
  )
}
