import React, { useState, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { OpenContext } from '../context/OpenContext'
import { UserContext } from '../context/UserContext'
import { AlertContext } from '../context/AlertContext'
import useDidMountEffect from "../hooks/useDidMountEffect";

export default function ChangePwdDialog({title}) {
  const { t } = useTranslation();

  const { dialogOpen, setDialogOpen } = useContext(OpenContext)
  const { userInfo } = useContext(UserContext)
  const { setAlertOpen } = useContext(AlertContext)
  const [errorMessage, setErrorMessage] = useState('')
  const [userData, setUserData] = useState({})
  const [formData, setFormData] = useState({
    name: userInfo.name,
    email: userInfo.email,
    password: '',
    currency_id: userInfo.currency_id,
    status: userInfo.status
  })
  const [formValid, setFormValid] = useState(true)

  const handleClose = () => {
    setDialogOpen({
      changePwd: false,
      editInfo: false,
      editAccount: false,
      addAccount: false
    })
    setFormValid(true)
    setFormData({
      name: '',
      email: '',
      password: '',
      currency_id: '',
      status: ''
    })
    setErrorMessage('')
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    setFormValid(true)

    var regNumber = /\d+/;
    var regString = /[a-zA-Z]+/; 

    if(data.get('new_password')==='' || data.get('new_password_check')===''){
      setFormValid(false)
      setErrorMessage(t('password_rule3'))
    }

    if(data.get('new_password') !== data.get('new_password_check')){
      setFormValid(false)
      setErrorMessage(t('password_rule2'))
    }

    if(!regNumber.test(data.get('new_password')) || !regString.test(data.get('new_password')) ){
      setFormValid(false)
      setErrorMessage(t('password_rule3'))
      return
    }

    setFormData({
      name: userInfo.name,
      email: userInfo.email,
      password: data.get('new_password'),
      currency_id: userData.currency.id,
      status: userInfo.status
    });


  }


  useEffect(() => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userInfo.token },
    };
    
    fetch('https://api.futurekey.org/api/users/'+userInfo.id, requestOptions)
      .then(response => response.json())
      .then(data => {
        if(data.message==='OK')  {
          setUserData(data.data)
        }else{
          console.log(data)
          setFormValid(false)
        }
      })
  },[userInfo])

  useDidMountEffect(() => {
    if(formValid && formData.password !== ''){
      const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userInfo.token },
        body: JSON.stringify(formData)
      };
      
      fetch('https://api.futurekey.org/api/users/'+userInfo.id, requestOptions)
        .then(response => response.json())
        .then(data => {
          console.log(data)
          if(data.message==='OK')  {
            setAlertOpen(true)
            handleClose()
          }else{
            console.log(data)
            setFormValid(false)
          }
        })
    }
   }, [formData])

  return (
    <Dialog open={dialogOpen.changePwd} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <Box component="form" noValidate onSubmit={handleSubmit} >
        <DialogContent sx={{ py: 0 }}>
          <TextField
            margin="dense"
            id="new_password"
            name="new_password"
            label={t('password')}
            type="password"
            fullWidth
            variant="standard"
            error={!formValid}
          />
          <TextField
            margin="dense"
            id="new_password_check"
            name="new_password_check"
            label={t('confirm_password')}
            type="password"
            fullWidth
            variant="standard"
            helperText={ !formValid ? errorMessage : t('password_rule3') }
            error={!formValid}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('cancel')}</Button>
          <Button variant="contained" type="submit">{t('submit')}</Button>
        </DialogActions>
      </Box>
    </Dialog>
  )
}
