import React, { useState, useContext } from 'react'
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import { OpenContext } from '../context/OpenContext'
import { UserContext } from '../context/UserContext'
import { AlertContext } from '../context/AlertContext'
import useDidMountEffect from '../hooks/useDidMountEffect'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const formRow = [
  { id: "name", title : "廣告平台名稱", grid: 6, type: "text" , width: "95%", require: true},
  { id: "full_name", title : "公司抬頭", grid: 6, type: "text" , width: "95%", require: false},
  { id: "url", title : "平台登入網址", grid: 12, type: "url" , width: "100%", require: false},
  { id: "username", title : "平台登入帳號", grid: 6, type: "text" , width: "95%", require: false},
  { id: "password", title : "平台登入密碼", grid: 6, type: "text" , width: "95%", require: false},
  { id: "contact_name", title : "會計聯絡人", grid: 6, type: "text" , width: "95%", require: false},
  { id: "company_num", title : "公司統編", grid: 6, type: "text" , width: "95%", require: false},
  { id: "address", title : "公司地址", grid: 12, type: "text" , width: "100%", require: false},
]
const accountRow = [
  {id: "account_name", title : "銀行戶名", grid: 6, type: "text" , width: "95%", require: false},
  {id: "account_num", title : "銀行帳號", grid: 6, type: "text" , width: "95%", require: false},
  {id: "bank_name", title : "銀行名稱", grid: 6, type: "text" , width: "95%", require: false},
  {id: "bank_num", title : "銀行代碼", grid: 6, type: "text" , width: "95%", require: false},
]

export default function AddPlatformDialog({title, editData}) {
  const { dialogOpen, setDialogOpen } = useContext(OpenContext)
  const { setAlertOpen } = useContext(AlertContext)
  const { userInfo } = useContext(UserContext)
  const [ errorMessage, setErrorMessage ] = useState('')
  const [ formData, setFormData ] = useState({
    name: "",
    full_name: "",
    address: "",
    company_num: "",
    contact_name: "",
    url: "",
    username: "",
    password: "",
    bank_name: "",
    bank_num: "",
    account_name: "",
    account_num: ""
  })
  const [ formValid, setFormValid ] = useState(true)
  const [ loading, setLoading ] = useState(false)
  const [ open, setOpen ] = useState(false)
  const [ loadingD, setLoadingD ] = useState(false)

  const handleClose = () => { 
    setDialogOpen({
      type: false,
      add: false,
      edit: false
    })
    setFormData({
      name: "",
      full_name: "",
      address: "",
      company_num: "",
      contact_name: "",
      url: "",
      username: "",
      password: "",
      bank_name: "",
      bank_num: "",
      account_name: "",
      account_num: ""
    })
  }

  const handleDelete = () => {
    if(open){
      setLoadingD(true)
      var requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userInfo.token},
      };

      fetch('https://api.futurekey.org/api/platforms/'+ editData.id, requestOptions)
      .then(response => response.json())
      .then(data => {
        if(data.message==='OK'){
          setAlertOpen({
            open: true,
            message: '平台已刪除'
          })
          setLoadingD(false)
          handleClose()
          handleConfirmClose()
        }else{
          setLoadingD(false)
          console.log(data.message)
        }
      })

    }else{
      setOpen(true)
    }
  }
  
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    setLoading(true)
    setFormValid(true)

    if(data.get('name')===''){
      setFormValid(false)
      setErrorMessage('請確認所有必填欄位已填寫正確')
      setLoading(false)
      return
    }

    setFormData({
      name: data.get('name'),
      full_name: data.get('full_name'),
      address: data.get('address'),
      company_num: data.get('company_num'),
      contact_name: data.get('contact_name'),
      url: data.get('url'),
      username: data.get('username'),
      password: data.get('password'),
      bank_name: data.get('bank_name'),
      bank_num: data.get('bank_num'),
      account_name: data.get('account_name'),
      account_num: data.get('account_num'),
      status: true
    })

  }

  const handleConfirmClose = () => {
    setOpen(false)
  }

  useDidMountEffect(() => {
    if(formValid && formData.name !== ''){
      const requestOption = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userInfo.token },
        body: JSON.stringify(formData)
      };
      
      fetch('https://api.futurekey.org/api/platforms/' + editData.id, requestOption)
        .then(response => response.json())
        .then(data => {
          if(data.message==='OK')  {
            handleClose()
            setLoading(false)
            setAlertOpen({
              open: true,
              message: '變更成功'
            })
          }else{
            console.log(data)
            setLoading(false)
            setFormValid(false)
          }
        })
    }
   }, [formData])

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClick={handleClose}
        aria-describedby="alert-dialog-slide-description"
        sx={{zIndex: "1301"}}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            確定要刪除此廣告平台嗎？
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{justifyContent: "space-between"}}>
          <Button onClick={handleConfirmClose}>取消</Button>
          {
            loadingD &&
            <LoadingButton
              loading
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="outlined"
            >
              處理中
            </LoadingButton>
          }
          {
            !loadingD &&
            <Button onClick={handleDelete}>確定</Button>
          }
        </DialogActions>
      </Dialog>
      <Dialog open={dialogOpen.edit} onClose={handleClose} TransitionComponent={Transition}>
        <DialogTitle>
          {title}
          <IconButton aria-label="close" onClick={handleClose} sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500], }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Box component="form" noValidate onSubmit={handleSubmit} >
          <DialogContent sx={{ py: 0 }}>
            <Typography component="h6" variant="subtitle2" gutterBottom fontWeight={500}>
              基本資訊
            </Typography>
            <Grid container sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
              {
                formRow.map((row) => (
                  <Grid key={row.id} item xs={row.grid}>
                    <TextField
                      defaultValue={editData[row.id]}
                      required={row.require}
                      margin="dense"
                      id={row.id}
                      name={row.id}
                      label={row.title}
                      sx={{ width: row.width }}
                      type={row.type}
                      variant="standard"
                    />
                  </Grid>
                ))
              }
            </Grid>
            <Typography component="h6" variant="subtitle2" gutterBottom fontWeight={500}>
              匯款資訊
            </Typography>
            <Grid container sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
              {
                accountRow.map((row) => (
                  <Grid key={row.id} item xs={row.grid}>
                    <TextField
                      defaultValue={editData[row.id]}
                      required={row.require}
                      margin="dense"
                      id={row.id}
                      name={row.id}
                      label={row.title}
                      sx={{ width: row.width }}
                      type={row.type}
                      variant="standard"
                    />
                  </Grid>
                ))
              }
            </Grid>
            {
              !formValid &&
              <Typography component="p" variant="body2" gutterBottom fontWeight={400} color="error">
                {errorMessage}
              </Typography>
            }
          </DialogContent>
          <DialogActions  sx={{justifyContent: 'space-between'}}>
            <Grid item>
              <Button color="error" variant='outlined' onClick={handleDelete}>刪除</Button>
            </Grid>
            <Grid item>
              <Button onClick={handleClose}>取消</Button>
              {
                loading &&
                <LoadingButton
                  loading
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  variant="outlined"
                >
                  處理中
                </LoadingButton>
              }
              {
                !loading &&
                <Button variant="contained" type="submit">儲存</Button>
              }
            </Grid>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  )
}
