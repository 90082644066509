import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import BarChartIcon from '@mui/icons-material/BarChart';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import AddchartIcon from '@mui/icons-material/Addchart';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import ReceiptIcon from '@mui/icons-material/Receipt';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import WebAssetIcon from '@mui/icons-material/WebAsset';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DomainIcon from '@mui/icons-material/Domain';
import CampaignIcon from '@mui/icons-material/Campaign';
import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

const path = window.location.pathname

export default function AdminListItems({open}) {
  const [listOpen, setListOpen] = React.useState(true);
  const [list2Open, setList2Open] = React.useState(true);

  const handleClick = () => {
    setListOpen(!listOpen);
  };
  const handleClick2 = () => {
    setList2Open(!list2Open);
  };

  return (
    <React.Fragment>
      {/* <ListSubheader component="div" disableSticky={true}>
        {
          open.menuOpen &&
          <Divider textAlign="left">財務管理</Divider>
        }
        {
          !open.menuOpen && 
          <Divider />
        }
      </ListSubheader> */}
      {
        open.menuOpen &&
        <ListItemButton className='menu_title' onClick={handleClick}>
          <ListItemText primary="財務管理" />
          {listOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      }
      {
        !open.menuOpen && 
        <Divider />
      }
      <Collapse in={listOpen} timeout="auto" unmountOnExit>
        <Link href="/report" underline="none" color="inherit">
          <ListItemButton selected={path === '/report'}>
            <ListItemIcon>
              <BarChartIcon />
            </ListItemIcon>
            <ListItemText primary="會計報表" />
          </ListItemButton>
        </Link>
        <Link href="/add_report" underline="none" color="inherit">
          <ListItemButton selected={path === '/add_report'}>
            <ListItemIcon>
              <AddchartIcon />
            </ListItemIcon>
            <ListItemText primary="新增報表" />
          </ListItemButton>
        </Link>
        <Link href="/exchange_rate" underline="none" color="inherit">
          <ListItemButton selected={path === '/exchange_rate'}>
            <ListItemIcon>
              <CurrencyExchangeIcon />
            </ListItemIcon>
            <ListItemText primary="匯率設置" />
          </ListItemButton>
        </Link>
        <Link href="/accounting" underline="none" color="inherit">
          <ListItemButton selected={path === '/accounting'}>
            <ListItemIcon>
              <ReceiptIcon />
            </ListItemIcon>
            <ListItemText primary="帳款記錄" />
          </ListItemButton>
        </Link>
        <Link href="/bill_notice" underline="none" color="inherit">
          <ListItemButton>
            <ListItemIcon>
              <PriceCheckIcon />
            </ListItemIcon>
            <ListItemText primary="帳款通知" />
          </ListItemButton>
        </Link>
      </Collapse>

      {/* <ListSubheader component="div" disableSticky={true}>
        <Divider textAlign="left">廣告管理</Divider>
      </ListSubheader> */}
      {
        open.menuOpen &&
        <ListItemButton className='menu_title' onClick={handleClick2}>
          <ListItemText primary="廣告管理" />
          {list2Open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      }
      {
        !open.menuOpen && 
        <Divider />
      }
      <Collapse in={list2Open} timeout="auto" unmountOnExit>
        <Link  href="/user_list" underline="none" color="inherit">
          <ListItemButton selected={path === '/user_list'}>
            <ListItemIcon>
              <SupervisorAccountIcon />
            </ListItemIcon>
            <ListItemText primary="帳號管理" />
          </ListItemButton>
        </Link>
        <Link  href="/website_list" underline="none" color="inherit">
          <ListItemButton selected={path === '/website_list'}>
            <ListItemIcon>
              <WebAssetIcon />
            </ListItemIcon>
            <ListItemText primary="網站管理" />
          </ListItemButton>
        </Link>
        <Link  href="/adunit_list" underline="none" color="inherit">
          <ListItemButton selected={path === '/adunit_list'}>
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="廣告版位管理" />
          </ListItemButton>
        </Link>
        <Link  href="/platform_list" underline="none" color="inherit">
          <ListItemButton selected={path === '/platform_list'}>
            <ListItemIcon>
              <DomainIcon />
            </ListItemIcon>
            <ListItemText primary="廣告平台管理" />
          </ListItemButton>
        </Link>
        <Link  href="/post_list" underline="none" color="inherit">
          <ListItemButton selected={path === '/post_list'}>
            <ListItemIcon>
              <CampaignIcon />
            </ListItemIcon>
            <ListItemText primary="公告管理" />
          </ListItemButton>
        </Link>
      </Collapse>
    </React.Fragment>
  )
}
