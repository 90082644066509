import React, { useEffect, useState, useContext } from 'react'
import { useTranslation } from 'react-i18next';

import { ThemeProvider } from '@mui/material/styles';
import { theme } from './theme'
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import LockResetIcon from '@mui/icons-material/LockReset';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import { UserContext } from "./context/UserContext"
import { AlertContext } from "./context/AlertContext"
import { OpenContext } from "./context/OpenContext"
import { MenuContext } from "./context/MenuContext"
import HeaderAndMenu from './components/HeaderAndMenu'
import ChangePwdDialog from './components/ChangePwdDialog'
import EditInfoDialog from './components/EditInfoDialog'
import EditAccountDialog from './components/EditAccountDialog'
import AddAccountDialog from './components/AddAccountDialog'

function TransitionDown(props) {
  return <Slide {...props} direction="down" />;
}

export default function Profile() {
  const { t } = useTranslation();

  const { userInfo, setUserInfo } = useContext(UserContext);
  const [ menuOpen, setMenuOpen ] = useState(true);
  const [alertOpen, setAlertOpen] = useState(false);
  const [value, setValue] = useState("0");
  const [dialogOpen, setDialogOpen] = useState({
    changePwd: false,
    editInfo: false,
    editAccount: false,
    addAccount: false
  }) 
  const [dialogTitle, setDialogTitle] = useState('') 
  const [userData, setUserData] = useState({
    bank_accounts: {
      currency:{id: ""},
      account_name: "",
      account: "",
      bank_name: "",
      bank_code: "",
      bank_location: "",
      swift_code: "",
      bank_branch: "",
    }
  })

  useEffect (() => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userInfo.token},
    };

    fetch('https://api.futurekey.org/api/users/'+userInfo.id, requestOptions)
    .then(response => response.json())
    .then(data => {
      if(data.message==='OK'){
        setUserData(data.data)
        var newUserInfo = userInfo
        newUserInfo.status = data.data.status
        newUserInfo.currency_id = data.data.currency_id
        newUserInfo.name = data.data.name
        newUserInfo.email = data.data.email
        setUserInfo(newUserInfo)
        sessionStorage.setItem('name', data.data.name)
        sessionStorage.setItem('email', data.data.email)
      }else{
        console.log(data.message)
      }
    })
    setTimeout(() => {
      setAlertOpen(false)
    }, 3000);
  },[dialogOpen,userInfo,setUserInfo])

  const handleChangePwd = () =>{
    setDialogOpen({
      changePwd: true,
      editInfo: false,
      editAccount: false,
      addAccount: false
    })
    setDialogTitle(t('change_password'))
  }
  const handleEditInfo = () =>{
    setDialogOpen({
      changePwd: false,
      editInfo: true,
      editAccount: false,
      addAccount: false
    })
    setDialogTitle(t('edit_information'))
  }
  const handleEditAccount = () =>{
    setDialogOpen({
      changePwd: false,
      editInfo: false,
      editAccount: true,
      addAccount: false
    })
    setDialogTitle(t('edit_payment'))
  }
  const handleAddAccount = () =>{
    setDialogOpen({
      changePwd: false,
      editInfo: false,
      editAccount: false,
      addAccount: true
    })
    setDialogTitle(t('add_payment'))
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <ThemeProvider theme={theme}>
      <MenuContext.Provider value={{menuOpen, setMenuOpen}}>
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />
          <HeaderAndMenu mainTitle={t("account_setting")}/>
          <Box component="main" sx={{ flexGrow: 1, height: '100vh', overflow: 'auto' }}>
            <Toolbar className='fixToolbar' />
            <Snackbar open={alertOpen} TransitionComponent={TransitionDown} anchorOrigin={{vertical: 'top', horizontal:'right' }}>
              <Alert severity="success" open={false}>{t('change_success')}</Alert>
            </Snackbar>
            <Container className='mainContainer' maxWidth="lg" sx={{ mt: 4, mb: 4 }} >
              <Card sx={{ minWidth: 275 }}>
                <CardContent>
                  <Grid container>
                    <Grid item md={4}>
                      <Typography variant='subtitle1' component="h4">{t('username')} : {userInfo.username}</Typography>
                    </Grid>
                    <Grid item md={8}>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item md={4}>
                      <Typography variant='subtitle1' component="h4">
                        {userInfo.role === 'User' ? t('contact_person') : t('name')} : {userInfo.name==='-'?'':userInfo.name}
                      </Typography>
                    </Grid>
                    <Grid item md={8}>
                    </Grid>
                  </Grid>
                  <Typography variant='subtitle1' component="h4" py={1}>Email : {userData.email}</Typography>
                  <Typography variant='subtitle1' component="h4" py={1}>{t('accountant_email_label')} : {userData.mails}</Typography>
                  {
                    userInfo.role === 'User' &&
                    <Typography variant='subtitle1' component="h4" py={1}>{t('settlement_currency')} : {userData.currency ? userData.currency.name : ''}</Typography>
                  }
                  <CardActions>
                    <Button variant="contained" onClick={handleChangePwd} startIcon={<LockResetIcon />}>
                      {t('change_password')}
                    </Button>
                    <Button variant="contained" onClick={handleEditInfo} startIcon={<EditIcon />}>
                      {t('edit_information')}
                    </Button>
                  </CardActions>
                </CardContent>
              </Card>
              {
                userInfo.role === 'User' &&
                <Card sx={{ minWidth: 275, mt: "16px" }}>
                  <CardContent>
                    <Typography variant='h6' component="h4" mb={2}>{t('payment_information')}</Typography>
                    {
                      userData.bank_accounts && userData.bank_accounts.length > 0 &&
                        <TabContext value={""+value+""}>
                          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="accounts">
                            {
                              userData.bank_accounts.map((bank_account, index)=>(
                                <Tab key={bank_account.currency.code} label={bank_account.currency.name} value={""+index+""} />
                              ))
                            }
                            </TabList>
                          </Box>
                        {
                          userData.bank_accounts.map((bank_account, index)=>(
                            <TabPanel key={bank_account.currency.code} sx={{p: "0px",pb :"16px"}} value={""+index+""}>
                              {
                                (bank_account.currency.id===1) &&
                                  <TableContainer >
                                    <Table aria-label="table">
                                      <TableBody>
                                        <TableRow>
                                          <TableCell style={{ width: '30%' }} component="td">
                                            {t('bank_account_name')}
                                          </TableCell>
                                          <TableCell style={{ width: '70%' }} component="td">
                                            {bank_account.account_name}
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell style={{ width: '30%' }} component="td">
                                            {t('bank_account')}
                                          </TableCell>
                                          <TableCell style={{ width: '70%' }} component="td">
                                            {bank_account.account}
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell style={{ width: '30%' }} component="td">
                                            {t('bank_code')}
                                          </TableCell>
                                          <TableCell style={{ width: '70%' }} component="td">
                                            {bank_account.bank_code}
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell style={{ width: '30%' }} component="td">
                                            {t('bank_name')}
                                          </TableCell>
                                          <TableCell style={{ width: '70%' }} component="td">
                                            {bank_account.bank_name}
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell style={{ width: '30%' }} component="td">
                                            {t('note')}
                                          </TableCell>
                                          <TableCell style={{ width: '70%' }} component="td">
                                            {bank_account.note}
                                          </TableCell>
                                        </TableRow>
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                              }
                              {
                                (bank_account.currency.id===2) &&
                                  <TableContainer xs={{p:"0px"}}>
                                    <Table aria-label="table">
                                      <TableBody>
                                        <TableRow>
                                          <TableCell style={{ width: '30%' }} component="td">
                                            Account Name
                                          </TableCell>
                                          <TableCell style={{ width: '70%' }} component="td">
                                            {bank_account.account_name}
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell style={{ width: '30%' }} component="td">
                                            Account Number
                                          </TableCell>
                                          <TableCell style={{ width: '70%' }} component="td">
                                            {bank_account.account}
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell style={{ width: '30%' }} component="td">
                                            Swift Code
                                          </TableCell>
                                          <TableCell style={{ width: '70%' }} component="td">
                                            {bank_account.swift_code}
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell style={{ width: '30%' }} component="td">
                                            Bank Name
                                          </TableCell>
                                          <TableCell style={{ width: '70%' }} component="td">
                                            {bank_account.bank_name}
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell style={{ width: '30%' }} component="td">
                                            Note
                                          </TableCell>
                                          <TableCell style={{ width: '70%' }} component="td">
                                            {bank_account.note}
                                          </TableCell>
                                        </TableRow>
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                              }
                              {
                                (bank_account.currency.id===3) &&
                                  <TableContainer xs={{p:"0px"}}>
                                    <Table aria-label="table">
                                      <TableBody>
                                        <TableRow>
                                          <TableCell style={{ width: '30%' }} component="td">
                                            {t('bank_account_name')}
                                          </TableCell>
                                          <TableCell style={{ width: '70%' }} component="td">
                                            {bank_account.account_name}
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell style={{ width: '30%' }} component="td">
                                            {t('bank_account')}
                                          </TableCell>
                                          <TableCell style={{ width: '70%' }} component="td">
                                            {bank_account.account}
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell style={{ width: '30%' }} component="td">
                                            {t('bank_code')}
                                          </TableCell>
                                          <TableCell style={{ width: '70%' }} component="td">
                                            {bank_account.bank_code}
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell style={{ width: '30%' }} component="td">
                                            {t('bank_name')}
                                          </TableCell>
                                          <TableCell style={{ width: '70%' }} component="td">
                                            {bank_account.bank_name}
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell style={{ width: '30%' }} component="td">
                                            {t('bank_location')}
                                          </TableCell>
                                          <TableCell style={{ width: '70%' }} component="td">
                                            {bank_account.bank_location}
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell style={{ width: '30%' }} component="td">
                                            {t('bank_branch')}
                                          </TableCell>
                                          <TableCell style={{ width: '70%' }} component="td">
                                            {bank_account.bank_branch}
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell style={{ width: '30%' }} component="td">
                                            {t('note')}
                                          </TableCell>
                                          <TableCell style={{ width: '70%' }} component="td">
                                            {bank_account.note}
                                          </TableCell>
                                        </TableRow>
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                              }
                            </TabPanel>
                          ))
                        }
                        </TabContext>
                    }
                    <CardActions>
                      <Button variant="contained" startIcon={<AddIcon />} onClick={handleAddAccount}>
                        {t('add_payment')}
                      </Button>
                      {
                        userData.bank_accounts && userData.bank_accounts.length > 0 &&
                        <Button variant="contained" startIcon={<EditIcon />} onClick={handleEditAccount}>
                          {t('edit_payment')}
                        </Button>
                      }
                    </CardActions>
                  </CardContent>
                </Card>
              }
            </Container>
            <OpenContext.Provider value={{dialogOpen, setDialogOpen}}>
              <AlertContext.Provider value={{alertOpen, setAlertOpen}}>
                <ChangePwdDialog title={dialogTitle}/>
                <EditInfoDialog title={dialogTitle}/>
                {
                  userData.bank_accounts && userData.bank_accounts.length>0 &&
                  <EditAccountDialog title={dialogTitle} existAccount={userData.bank_accounts ?  userData.bank_accounts[value] : {}} userId={userInfo.id}/>
                }
                <AddAccountDialog title={dialogTitle} userId={userInfo.id}/>
              </AlertContext.Provider>
            </OpenContext.Provider>
          </Box>
        </Box>
      </MenuContext.Provider>
    </ThemeProvider>
  )
}
