import React, { useState, useContext, useEffect } from 'react'
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import { OpenContext } from '../context/OpenContext'
import { UserContext } from '../context/UserContext'
import { AlertContext } from '../context/AlertContext'
import useDidMountEffect from '../hooks/useDidMountEffect'
import Autocomplete from '@mui/material/Autocomplete';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function EditSiteDialog({title, data}) {
  //console.log(data)
  const { dialogOpen, setDialogOpen } = useContext(OpenContext)
  const { setAlertOpen } = useContext(AlertContext)
  const { userInfo } = useContext(UserContext)
  const [ errorMessage, setErrorMessage ] = useState('')
  const [ userArr, setUserArr ] = useState([])
  const [ user, setUser ] = useState();
  const [ userValue, setUserValue ] = useState('')
  const [ inputUser, setInputUser ] = useState('')
  const [ formData, setFormData ] = useState({
    user_id: '',
    name: '',
    domain: '',
    dable_code: '',
    pmc_name: '',
    taboola_name:'',
    auto: true
  })
  const [ formValid, setFormValid ] = useState(true)
  const [ loading, setLoading ] = useState(false)
  const [ open, setOpen ] = useState(false)
  const [ loadingD, setLoadingD ] = useState(false)

  const handleClose = () => { 
    setDialogOpen({
      add: false,
      edit: false,
    })
    setFormData({
      user_id: '',
      name: '',
      domain: '',
      dable_code: '',
      pmc_name: '',
      taboola_name:'',
      auto: true
    })
    setUser('')
    setUserValue('')
    setInputUser('')
    setLoading(false)
    setFormValid(true)
  }

  const handleConfirmClose = () => {
    setOpen(false)
  }

  const handleDelete = () => {
    if(open){
      setLoadingD(true)
      var requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userInfo.token},
      };

      fetch('https://api.futurekey.org/api/websites/'+ data.id, requestOptions)
      .then(response => response.json())
      .then(data => {
        if(data.message==='OK'){
          setAlertOpen({
            open: true,
            message: '網站已刪除'
          })
          setLoadingD(false)
          handleClose()
          handleConfirmClose()
        }else{
          setLoadingD(false)
          console.log(data.message)
        }
      })

    }else{
      setOpen(true)
    }
  }

  const handleUserChange = (event) => {
    setUser(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    setLoading(true)
    setFormValid(true)

    if(data.get('name')==='' || user==='' ){
      setFormValid(false)
      setErrorMessage('請確認所有必填欄位已填寫正確')
      return
    }

    setFormData({
      user_id: user,
      name: data.get('name'),
      domain: data.get('domain'),
      dable_code: data.get('dable_code'),
      pmc_name: data.get('pmc_name'),
      taboola_name: data.get('taboola_name'),
      status: true,
      auto: true
    })

  }

  useDidMountEffect(() => {
    if(formValid && formData.name !== ''){
      const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userInfo.token },
        body: JSON.stringify(formData)
      };
      
      fetch('https://api.futurekey.org/api/websites/' + data.id, requestOptions)
        .then(response => response.json())
        .then(data => {
          if(data.message==='OK')  {
            handleClose()
            setLoading(false)
            setAlertOpen({
              open: true,
              message: '變更成功'
            })
          }else{
            console.log(data)
            setLoading(false)
            setFormValid(false)
          }
        })
    }
   }, [formData])

   useDidMountEffect(() => {
    setUser(data.user.id)
    setUserValue(data.user.username)
    setInputUser(data.user.username)
  },[data])

  useEffect (() => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userInfo.token},
    };

    fetch('https://api.futurekey.org/api/users?role=User&per_page=500&page=1', requestOptions)
    .then(response => response.json())
    .then(data => {
      if(data.message==='OK'){
        let newUserArr = data.data.users.map((u) => {
          return {
            label: u.username,
            value: u.id,
          }
        })
        setUserArr(newUserArr)
      }else{
        console.log(data.message)
      }
    })
  },[userInfo, data])

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClick={handleClose}
        aria-describedby="alert-dialog-slide-description"
        sx={{zIndex: "1301"}}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            確定要刪除此網站嗎？
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{justifyContent: "space-between"}}>
          <Button onClick={handleConfirmClose}>取消</Button>
          {
            loadingD &&
            <LoadingButton
              loading
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="outlined"
            >
              處理中
            </LoadingButton>
          }
          {
            !loadingD &&
            <Button onClick={handleDelete}>確定</Button>
          }
        </DialogActions>
      </Dialog>
      <Dialog open={dialogOpen.edit} onClose={handleClose} TransitionComponent={Transition}>
        <DialogTitle>
          {title}
          <IconButton aria-label="close" onClick={handleClose} sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500], }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Box component="form" noValidate onSubmit={handleSubmit} >
          <DialogContent sx={{ py: 0 }}>
            <Autocomplete
              disableClearable
              id="user"
              value={userValue}
              onChange={(event, newValue) => {
                setUserValue(newValue);
                handleUserChange(event);
              }}
              inputValue={inputUser}
              onInputChange={(event, newInputValue) => {
                setInputUser(newInputValue);
              }}
              options={userArr}
              sx={{ width: '100%' }}
              noOptionsText="無相關網站主"
              size="small"
              disabled
              renderOption={(props, option) => {
                return (
                  <li {...props} value={option.value}>
                    {option.label}
                  </li>
                );
              }}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              renderInput={(params) => <TextField size="small" variant='standard' name="user" {...params} label="網站主" />}
            />
            {/* <FormControl error={!formValid} required variant='standard' margin="dense" fullWidth>
              <InputLabel id="user-select-helper-label">網站主</InputLabel>
              <Select
                size="small"
                labelId="user-select-label"
                id="user_select"
                name="user_select"
                label="網站主"
                value={user}
                onChange={handleUserChange}
              >
                {
                  userArr && 
                  userArr.map((u, index) => (
                    <MenuItem key={u.id} value={u.id}>{u.username}{u.code}</MenuItem>
                  ))
                }
              </Select>
            </FormControl> */}
            <TextField
              defaultValue={data.name}
              required
              margin="dense"
              id="name"
              name="name"
              label="網站名稱"
              type="text"
              fullWidth
              variant="standard"
              error={!formValid}
              helperText={!formValid ? errorMessage : ''}
            />
            <TextField
              defaultValue={data.domain}
              margin="dense"
              id="domain"
              name="domain"
              label="域名"
              type="text"
              fullWidth
              variant="standard"
            />
            <Grid container sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
              <Grid item xs={6}>
                <TextField
                  defaultValue={data.pmc_name}
                  margin="dense"
                  id="pmc_name"
                  name="pmc_name"
                  label="第一平台網站名稱"
                  sx={{ width: '95%' }}
                  type="text"
                  fullWidth
                  variant="standard"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  defaultValue={data.dable_code}
                  margin="dense"
                  id="dable_code"
                  name="dable_code"
                  label="Dable平台網站ID"
                  sx={{ width: '95%' }}
                  type="text"
                  fullWidth
                  variant="standard"
                />
              </Grid>
            </Grid>
            <Grid container sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
              <Grid item xs={6}>
                <TextField
                  defaultValue={data.taboola_name}
                  margin="dense"
                  id="taboola_name"
                  name="taboola_name"
                  label="taboola平台網站名稱"
                  sx={{ width: '95%' }}
                  type="text"
                  fullWidth
                  variant="standard"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{justifyContent: 'space-between'}}>
            <Grid item>
              <Button color="error" variant='outlined' onClick={handleDelete}>刪除</Button>
            </Grid>
            <Grid item>
              <Button sx={{mr: 2}} onClick={handleClose}>取消</Button>
              {
                loading &&
                <LoadingButton
                  loading
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  variant="outlined"
                >
                  處理中
                </LoadingButton>
              }
              {
                !loading &&
                <Button variant="contained" type="submit">儲存</Button>
              }
            </Grid>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  )
}
